import React, {useEffect, useState} from 'react'
import {SubTitle} from '../../components/Typography'
import Button from '../../components/Button'
import {useHistory} from 'react-router-dom';
import {Heading, Paragraph} from '../../components/Typography2';
import {reSendEmail} from '../../services/api'
import IconBtn from "../../components/IconBtn";
import logger from '../../utils/logger';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const Verify = (props) => {
    const history = useHistory();
    const email = props.location.state;
    const [showMessage, setShowMessage] = useState(false);
    const { setContentType } = useTrackingCode();

    useEffect(() => {
        setContentType('verify-email-page');
        window.scrollTo(0, 0);
    }, []);

    const handleClose = () => {
        history.push('/');
    };

    const handleResendEmail = async () => {
        logger.debug('Resend email Clicked!')
        if (email) {

            let resp = await reSendEmail(email)
            logger.debug("resp", resp);

            if (resp && resp.success) {
                setShowMessage(resp.success)
            }
        }
    }

    logger.debug("history", props.location.state);
    return (
        <>
            <section className='form-page verify-page form'>
                <div className='form__container'>
                    <Heading value='Verify your account' className='dark'/>
                    <Paragraph value='Go to your email and follow the instructions to activate your account. If you haven’t received the email, come back and hit “Resend Email”.'
                        className='dark'/>
                    <div className='form__grid'>
                        <Button
                            value='Resend email'
                            size='large'
                            disable={showMessage}
                            className='button__dark'
                            type='primary'
                            onClick={() => handleResendEmail()}
                        />
                    </div>
                    <br/>
                    {showMessage &&
                    <div className="verify-email">
                      <Heading value={`An email has been sent to ${email}`}/>
                    </div>
                    }
                    <div>
                        <br/>
                        <SubTitle link='Log in' to='/login'/>
                    </div>
                </div>
                <IconBtn className='cross' onClick={handleClose} type="cross" secondary/>
            </section>
        </>
    )
}

export default Verify;
