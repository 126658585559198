import ArticleDetails from './ArticleDetails'
import { connect } from 'react-redux'
import './style.scss';
import {setArticlesDetailsBack} from "../Exhibitions/redux/actions";

const mapDispatchToProps = dispatch => ({
    setArticlesDetailsBack: (val) => dispatch(setArticlesDetailsBack(val)),
})

const mapStatesToProps = () => {
    return;
}

export default connect(mapStatesToProps, mapDispatchToProps)(ArticleDetails);
