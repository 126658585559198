import logger from '../utils/logger';
var rudder;

const isProd = process.env.NODE_ENV === "production"

try {
    if (!!window?.localStorage) {
        logger.debug('process.env = ', process.env.NODE_ENV)
        const RSK = "2DXKgZC6c2duApCdZ1zkaMwDiMG"
        const DATA_PLANE_URL = "https://vorticigahd.dataplane.rudderstack.com"
        const rd = require('rudder-sdk-js')
        rd.load(RSK, DATA_PLANE_URL);
        rudder = rd;
    }
  }
catch(err) {
    logger.error(err);
}

export default rudder;
