import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom'
import {fetchArticleDetails} from '../../services/api';
import {formatDate} from '../../utils';
import CollateralLayouts from "../../components/CollateralLayouts";
import {Title, Label, Timeline} from '../../components/Typography2';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const ArticleDetail = (props) => {
    const [details, setDetails] = useState(null);
    const route = useRouteMatch();
    const { setContentType } = useTrackingCode();

    useEffect(() => {
        setContentType('article-details-page');
        getArticles();
        props.setArticlesDetailsBack(true);
    }, [])

    const getArticles = async () => {
        // @ts-ignore
        const exhibitionId = route && route.params && route.params.id;
        const result = await fetchArticleDetails(exhibitionId);
        if (result && result.data) {
            setDetails(result.data);
        }
    }

    return (
        <>
            <div className="details container">
                {
                    details &&
                    <>
                      <div className="article-detail_header">
                        <Title className="details__heading" value={details.title}/>
                        <div className="details__flex">
                          <Label value={details.author}/>
                          <Timeline value={formatDate(details.writtenDate, true, true)}/>
                        </div>
                      </div>
                      <div className="details__container">
                        <div className="collateral-wrapper" style={{marginTop: 0}}>
                            {
                                details && details.collateralElement && details.collateralElement.length > 0 &&
                                <CollateralLayouts layout={details.collateralElement}/>
                            }
                        </div>
                      </div>
                    </>
                }

            </div>
        </>
    )
};

export default ArticleDetail;
