import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import Card from "../../components/Card";
import Spinner from '../../components/Spinner';
import Dropdown from "../../components/Dropdown";
import useInfiniteScroll from 'react-infinite-scroll-hook';
import SvgHeader from "../../components/SvgHeader";
import SubHeader from "../../components/SubHeader";
import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation';
import { constructImageUrl } from '../../utils';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const Museum = (props) => {
    const history = useHistory()
    const {
        updateSortBy,
        resetGalleries,
        setGalleriesLoader,
        fetchCollectiveMetaInit,
        collectiveMeta,
        gallery: {galleries, sortBy, currentPage, canLoadMore},
    } = props;
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const { setContentType } = useTrackingCode();

    useEffect(() => {
        setContentType('museums-and-public-galleries-listing-page');
        loadData();
        loadCollectives()
        return () => {
            resetGalleries();
        }
    }, []);

    const loadCollectives = () => {
        if (collectiveMeta.length === 0) {
            fetchCollectiveMetaInit();
        }
    }

    useEffect(() => {
        setIsLoadingMore(false)
    }, [galleries && galleries.length]);

    const loadData = async () => {
        if (canLoadMore && !isLoadingMore) {
            setIsLoadingMore(true);
            const nextPage = currentPage + 1;
            const isGroup = !['alphabetical',].includes(sortBy);
            await props.fetchGalleriesInit(nextPage, sortBy, isGroup, "museum");
        }
    };

    const handleGalleryClick = (galleryId) => {
        history.push(`/galleries/${galleryId}`)
    };

    const filterOptions = () => {
        const options = [
            {
                value: 'alphabetical',
                label: 'Alphabetical'
            }
        ]
        return options.concat(collectiveMeta.map(c => {
            return {
                value: c.id,
                label: c.name
            }
        }))
    }

    const handleValueChange = async (value) => {
        setGalleriesLoader(true)
        updateSortBy(value);
        const isGroup = !['alphabetical',].includes(value);
        await props.fetchGalleriesInit(1, value, isGroup, "museum");
    }

    const infiniteRef = useInfiniteScroll({
        loading: isLoadingMore,
        hasNextPage: canLoadMore,
        onLoadMore: loadData,
    });

    return (
        <div>
            <SubHeader title="Institutions" bottomGutter>
                <Dropdown options={filterOptions()} selected={sortBy} onChange={(val) => handleValueChange(val)}/>
            </SubHeader>
            <ScrollAnimation className="bg-white">
                <div className="container pt-0">
                    {/*// @ts-ignore*/}
                    <div className="cards-container" ref={infiniteRef}>
                        {
                            galleries && galleries.map((gallery: any, index: number) => {
                                const {id, slug, name, image, locations, overview} = gallery.data;
                                const imageUrl = image && image.data&&constructImageUrl(image,"720")
                                return (
                                    <div data-aos="fade-up" data-aos-duration="500" key={index}>
                                        <Card imgSrc={image && image.data && [imageUrl, imageUrl]} title={name}
                                            description={overview}
                                            location={locations}
                                            horizontal
                                            onClick={() => handleGalleryClick(slug || id)}
                                            isFirstCard={index === 0}
                                        />

                                    </div>
                                )
                            })
                        }
                        {
                            isLoadingMore &&
                            <Spinner/>
                        }
                    </div>
                </div>
            </ScrollAnimation>
        </div>
    )
};

export default Museum;
