import React, { useEffect, useState } from 'react';
import {useRouteMatch} from "react-router-dom";
import { GetExhibitions } from '../Discover';
import {getDiscoverExhibitions} from "../../../services/api";


const DiscoverDetails = () => {
    const [details,setDetails] = useState(null);
    const route = useRouteMatch();
    //@ts-ignore
    const itemId = route && route.params && route.params.id;

    const getArray = async (id) => {
        const resp = await getDiscoverExhibitions(id);
        if (resp) {
            setDetails(resp.data);
            return;
        }
    };
    useEffect(() => {
        getArray(itemId);
    }, [])

    return (
        <div className="discover">
            {
                details && (
                    // @ts-ignore
                    <GetExhibitions 
                        type={details.type} 
                        id={details.id}
                        y={details.y}
                        isHorizontal={details.isHorizontal}
                        title={details.title || ''}
                        image={details?.image}
                        link = {details?.link || ''}
                        slug = {details?.slug || ''}
                        description={details.description || ''}
                        isAutoScrollEnabled={details.isAutoScrollEnabled}
                        organisations={details.organisations}
                        isImageOnLeft={details.isImageOnLeft}
                        // isLoggedIn={isLoggedIn}
                        // updateFavourite={updateFavourite}
                        // toggleFavouriteExhibition={toggleFavouriteExhibition}
                    />
                )
            }
        </div>
    )
}

export default DiscoverDetails;