const styles = (theme) => ({
  chatBox: {
    width: 416,
    borderRadius: '10px 10px 0 0',
    overflow: 'hidden',
    border: '1px solid #E6E6E6',
    marginLeft: '30px',
    bottom: 0,
    right: 0,
    transition: 'bottom 0.23s ease-in-out',
    fontFamily: 'Maax',
    backgroundColor: '#fff',
  },
  closed: {
    height: '54px',
    overflow: 'hidden',
  },
  title: {
    color: '#fff !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
  },
  open: {
    background: 'black',
    padding: '10px',
  },
  chatHeader: {
    padding: '5px 23px 5px 22px',
    height: '54px',
    boxSizing: 'border-box',
    background: '#004F15',
    border: '1px solid #004F15',
    borderBottom: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&.green': {
      background: '#004F15',
      borderColor: '#004F15',
    },
  },
  unreadMessages: {
    background: '#000',
    border: '1px solid #000',
    '&.green': {
      background: '#000',
      borderColor: '#000',
    },
  },
  underlined: {
    textDecoration: 'underline',
  },
  chatHeaderIcons: {
    '&>:first-child': {
      marginRight: '15px',
    },
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
  },
  headerClose: {
    background: 'black',
  },
  headerOpen: {
    background: 'black',
    borderRadius: '5px 5px 0px 0px',
    color: '#fff',
  },
  header_bottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '35px',
    padding: '2px 15px',
    background: '#E6E6E6',
  },
  item_name: {
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '15px',
  },
  add_icon_img: {
    width: '20px',
    height: '20px',
    borderRadius: '50px',
    background: '#C4C4C4',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'top',
    marginRight: '12px',
    '& > svg': {
      flexShrink: 1,
      width: 15,
      height: 15,
      color: '#000',
    },
    '& + p': {
      fontSize: '14px',
      lineHeight: '15px',
    },
  },
  message_outer: {
    overflow: 'scroll',
    height: '360px',
    width: '100%',
    paddingRight: '40px',
    boxSizing: 'border-box',
    maxHeight: '495px',
    minHeight: '495px',
    padding: '53px 40px',
    margin: '0',
    // transform: 'rotate(180deg)',
    // direction: 'rtl',
    ' & li': {
      // transform: 'rotate(180deg)',
      // direction: 'ltr',
      marginBottom: 38,
    },
  },
  message_sent: {
    listStyle: 'none',
    position: 'relative',
  },
  message_text: {
    border: '1px solid #000',
    padding: '23px 25px',
    width: 'max-content',
    marginRight: '30px',
    borderRadius: '20px 20px 20px 0px',
    marginLeft: '50px !important',
    marginBottom: '0',
    wordBreak: 'break-word',
    fontSize: '12px',
    lineHeight: '16px',
    maxWidth: 'calc(100% - 102px)',
    color: 'rgba(0,0,0,0.7)',
    overflowWrap: 'break-word',
  },
  message_file_received: {
    border: '1px solid #000',
    padding: '13px 15px 10px 13px',
    marginRight: '30px',
    borderRadius: '20px 20px 20px 0px',
    marginLeft: '50px !important',
    marginBottom: '0',
    '& > img ': {
      width: '100%',
    },
  },
  message_file_sent: {
    border: '1px solid #000',
    padding: '13px 15px 10px 13px',
    marginRight: '50px',
    borderRadius: '20px 20px 0px 20px',
    marginBottom: '0',
    '& > img ': {
      width: '100%',
    },
  },
  sent_profile_icon: {
    width: '35px',
    height: '35px',
    background: '#000',
    position: 'absolute',
    left: '0',
    bottom: '15px',
    borderRadius: '50px',
    flexShrink: '0',
  },
  sender_name: {
    marginLeft: '50px !important',
    fontSize: '10px',
    marginTop: '0.35em',
    textAlign: 'left',
  },
  message_recvie: {
    listStyle: 'none',
    position: 'relative',
  },
  recive_profile_icon: {
    width: '35px',
    height: '35px',
    background: '#eee',
    position: 'absolute',
    right: '0',
    bottom: '-3px',
    flexShrink: '0',
    borderRadius: '50px',
  },
  recive_message_text: {
    width: 'max-content',
    border: '1px solid #D8D8D8',
    padding: '23px 25px',
    marginRight: '50px',
    marginLeft: 'auto',
    color: 'rgba(0,0,0,0.7)',
    borderRadius: '20px 20px 0px 20px',
    marginBottom: '0',
    fontSize: '12px',
    lineHeight: '16px',
    maxWidth: 'calc(100% - 102px)',
    overflowWrap: 'break-word',
  },
  reciver_name: {
    marginRight: '60px',
    textAlign: 'right',
    display: 'block',
  },
  field_sec: {
    display: 'flex',
    justifyContent: ' space',
    listStyle: 'none',
    alignItems: 'center',
    borderTop: '1px solid #e9e9e9',
    margin: '0',
    background: '#fff',
    padding: '26px 43px 26px 30px',
  },
  smiles: {
    // width: '15%',
    // marginTop: '-15px',
  },
  smile_icon: {
    width: '24px',
    height: '24px',
    display: 'block',
    marginLeft: 'auto',
    borderRadius: '50px',
    position: 'relative',
    cursor: 'pointer',
    '& > svg': {
      color: '#bfbfbf',
    },
    '& > input': {
      opacity: 0,
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
  },
  write_msg: {
    flex: 1,
  },
  type_field: {
    width: '80%',
    border: 'none',
    resize: 'none',
    height: 17,
    fontFamily: 'Arial',
    padding: '16px 0 14px 9px',
    '&::placeholder': {
      color: '#bfbfbf',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  attach_file: {
    // width: '15%',
    // marginTop: '-15px',
  },
  attach_icon: {
    width: '24px',
    height: '24px',
    borderRadius: '50px',
    display: 'block',
    marginRight: 'auto',
    '& > svg': {
      fill: '#c0c0c0',
    },
  },
  cardWrapper: {
    // transform: 'rotate(180deg)',
    // direction: 'ltr',
    padding: '20px 25px 25px 25px',
    border: '1px solid #b9b9b9',
    textAlign: 'center',
    marginBottom: '15px',
  },
  mediaMessage: {
    width: '100%',
  },
  cardinnerimage: {
    width: '100%',
    marginBottom: '25px',
    /* max-width: 400px; */
    maxHeight: '300px',
    objectFit: 'contain',
    // width: '170px',
    // // height: '170px',
    // marginBottom: '25px',
  },
  artisttitle: {
    textAlign: 'left',
    fontSize: '14px',
    lineHeight: '26px',
    color: '#000000',
  },
  year: {
    fontsize: '12px',
    lineHeight: '18px',
    color: '#4E4E4E',
    marginTop: '10px',
    textAlign: 'left',
  },
  artistcollection: {
    textAlign: 'left',
    marginTop: '15px',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#4E4E4E',
  },
  artworkDetails: {
    width: '100%',
    height: 460,
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gridColumnGap: '50px',
    justifyContent: 'center',
    alignContent: 'center',
  },
  imageWrapper: {
    width: '100%',
    maxHeight: 460,
    '& > img': {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
    '& > div': {
      width: 460,
    },
  },
  artwordDesciption: {
    marginTop: '30px',
  },
});
export default styles;
