import React from 'react';
import { Paragraph, Label } from '../Typography2';
import IconBtn from "../IconBtn";

const ChatListItem = ({ message, galleryTime, galleryName, title, onClick }) => {
    return (
        <div className='chat-list-item' onClick={onClick}>
            <div className='list-title'>
                <Paragraph value={title} />
                <IconBtn type="next-chat" onClick={onClick} />
            </div>
            <div className='gallery-details'>
                <Label className='gallery-name' value={galleryName} />
                <Label className='gallery-time' value={galleryTime} />
            </div>
            <Paragraph className='chat-message' value={message} />
        </div>
    )
};

export default ChatListItem;
