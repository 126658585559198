import React, {Fragment, useEffect, useRef, useState} from 'react'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {P1} from '../../../components/Typography'
import Card from '../../../components/Card'
import {
  favouriteExhibition,
  fetchExhibitionArtists,
  fetchExhibitionArtworks,
  fetchExhibitionArticles,
  fetchExhibitionsDetails,
  fetchEvents,
  RegisterEvent,
  fetchCurateDetails,
  fetchV2Scenes,
  UnRegisterEvent
} from '../../../services/api'
import Carousel from '../../../components/Carousel'
import axiosMain from "axios";
import {IExhibitionDetails} from './types'
import Spinner from '../../../components/Spinner'
import {formatDate, formatDateTime, getIdFromSlug, getShareURL, IsSafari, isMobileDevice, constructImageUrl} from '../../../utils'
import Button from '../../../components/Button'
import CollateralLayouts from '../../../components/CollateralLayouts'
import ArticleBox from '../../../components/Article'
import ImageLoader from "../../../components/ImageLoader";
import DetailsHeader from "../../../components/DetailsHeader";
import ScrollAnimation from '../../../components/ScrollAnimation/ScrollAnimation'
import ShareBtn from "../../../components/ShareBtn";
import ArrowDownDark from "../../../assets/icons/down-dark.svg";
import {Title, Label, Heading, Paragraph} from "../../../components/Typography2";
import logger from '../../../utils/logger';
import { NURBS_API_URL } from '../../../components/Constants';
import HeadTags from "../../../components/Helmet/HeadTags";
const APP_SCHEME = 'vortic-collect';

const ExhibitionDetails = (props) => {
  const route = useRouteMatch()
  const history = useHistory()
  const [details, setDetails] = useState<IExhibitionDetails>({data: {}});
  const [artworks, setArtworks] = useState([]);
  const [curateState, setCurateState] = useState({
    models:null,
    dimensions:null,
  });
  const [curateDataLoadingState, setCurateDataLoadingState] = useState({
    total:0,
    completed:0,
    percentage:0,
  });
  const [articles, setArticles] = useState([]);
  const [showAllDetails, toggleAllDetails] = useState(false);
  const [artists, setArtists] = useState([]);
  const [events, setEvents] = useState([]);
  const [exhibtionLoaderImage, setExhibtionLoaderImage] = useState(null);
  const [isLoadingDetails, setLoadingDetails] = useState(true);
  const [isLoadingArtworks, setLoadingArtworks] = useState(true);
  const [isLoadingArtists, setLoadingArtists] = useState(true);
  const headerImage = useRef(null);
  const carouselRef = useRef(null);
  const articlesRef = useRef(null);
  const [carouselState, setCarouselState] = useState(0);
  const [isHover, setIsHover] = useState(true);
  const [sceneName, setSceneName] = useState('');

  const isPreviewOnly = history.location.pathname.includes('exhibitions/preview/')

  const isDetailsPreviewOnly = history.location.pathname.includes('details') || history.location.pathname.includes('preview')
  // @ts-ignore
  const exhibitionId = route && route.params && route.params.id;

  const handleMobileExhibitionClick = () => {
    if(isMobileDevice()) {
      window.location.href = `${APP_SCHEME}://exhibition/${getIdFromSlug(exhibitionId)}`
    }
  }

  useEffect(() => {
    if (!!props.artworkId) {
      var arr = [];
      artworks.map(item => arr.push(item.data.id));
      props.setArtworkIds([...arr]);
    }
  }, [props.artworkId])

  useEffect(() => {
    if (isLoadingDetails) {
      setTimeout(() => {
        document.getElementById("root").style.height = "auto";
        document.getElementById("root").classList.add('h-auto');
      }, 0)
    }
  }, [isLoadingDetails])

  const loadExhibitionDetails = async () => {
    return new Promise(async (resolve, reject) => {
      const exhibitionDetails = await fetchExhibitionsDetails(exhibitionId, isPreviewOnly);
      if (exhibitionDetails.data.id !== null) {
        const exhibitionImage = exhibitionDetails.data.image && exhibitionDetails.data.image.data && exhibitionDetails.data.image.data.CFURL && (exhibitionDetails.data.image.data.CFURL + '720_' + exhibitionDetails.data.image.data.name + exhibitionDetails.data.image.data.signature);
        if(exhibitionImage){
          const assetBlob = await getWithProgress(exhibitionImage);
          setExhibtionLoaderImage(assetBlob);
        }
        const {startedAt, endedAt} = exhibitionDetails.data;
        exhibitionDetails.data.startedAt = formatDate(startedAt);
        exhibitionDetails.data.endedAt = formatDate(endedAt, true);
        setSceneName(exhibitionDetails.data.name)
        setDetails(exhibitionDetails);
        setLoadingDetails(false);
        props.setExhibitionLoading(true)
        resolve(true)
      } else {
        resolve(true)
      }
    })
  };

  const loadExhibitionArtworks = async () => {
    return new Promise(async (resolve, reject) => {
      const artworksResp = await fetchExhibitionArtworks(getIdFromSlug(exhibitionId));
      if (artworksResp.data) {
        setArtworks(artworksResp.data);
        var arr = [];
        [...artworksResp.data].map(item => arr.push(item.data.id));
        setLoadingArtworks(false)
        resolve(true)
      } else {
        resolve(true)
      }
    })
  };

  const loadExhibitionArtists = async () => {
    const artistsResp = await fetchExhibitionArtists(getIdFromSlug(exhibitionId))
    if (artistsResp.data) {
      setArtists(artistsResp.data);
      setLoadingArtists(false)
    }
  };

  const loadExhibitionArticles = async () => {
    return new Promise(async (resolve, reject) => {
      const articleResp = await fetchExhibitionArticles(getIdFromSlug(exhibitionId));
      if (articleResp.data) {
        const articlesResponse = articleResp.data;
        articlesResponse.sort(function (a, b) {
          return a.data.y - b.data.y
        });
        setArticles(articlesResponse);
        if (props.isArticleDetailsBack) {
          articlesRef.current.scrollIntoView({behavior: 'smooth'});
          props.setArticlesDetailsBack(false);
        }
        resolve(true)
      } else {
        resolve(true)
      }
    })
  };

  const capitalizeFirstLetter = (string) => {
    if (string.indexOf(' ') === -1) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    }
    return string;
  };

  useEffect(() => {
    logger.debug("Hello I have exhibition details!")
    window.scrollTo(0, 0);
    loadDetails();
    return () => {
      logger.debug('UN MOUNTING Exhibitions Details');
      props.setArticlesDetailsBack(false);
    }
  }, []);


  const getWithProgress = async (url) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await axiosMain.get(url, {
          responseType: 'blob',
          onDownloadProgress: (event) => {
            const percentage = (event.loaded * 100) / event.total;
            // logger.debug('onDownloadProgress = ', percentage)
          }
        })
        resolve( URL.createObjectURL(resp.data));

      } catch (error) {
        logger.debug("ErrorProgres------------------", error)
        reject("requestFail")
      }
    });
  }
  const addCurateDataLoadingPercentage = () => {
    setCurateDataLoadingState(prevState => ({
      ...prevState,
      percentage: Math.ceil(((prevState.completed + 1) / prevState.total) * 100),
      completed: prevState.completed + 1,
    }));
  }
  const getTotalCurateRecords = (data) => {
    let total = 0;
    data.forEach((dimension) => {
      const artwork = dimension.data && dimension.data.artwork && dimension.data.artwork.data;
      if(artwork){
        total++;
      }
      if(
          artwork && artwork.type ==="2d" ||
          artwork && artwork.type ==="video"
      ){
        total++;
      }
      if(artwork && artwork.thumbnail){
        total++;
      }
      if(dimension.data.HDRArtworkMap){
        total++;
      }
      if( dimension.data.HDRAssetMap){
        total++;
      }
      if(artwork && artwork.type ==="3d"){
        total = total + 2;
      }
    });
    const assetBlobMap = {};
    data.forEach(async dimension => {
      if(dimension.data && dimension.data.asset && !assetBlobMap[dimension.data.asset.data.id]) {
        assetBlobMap[dimension.data.asset.data.id] = {blob:dimension.data.asset.data.file.data.signedUrl,isBlob:false};
      }
    });

    return total + Object.keys(assetBlobMap).length;
  }

  const loadCurateData = async () => {
    if(details.data && details.data.version !== 0) {
      let scenes = await fetchV2Scenes(getIdFromSlug(exhibitionId));
      if(scenes && scenes.data && scenes.data[0]) {
        setSceneName(scenes.data[0].data.name)
      }
    }
    setCurateDataLoadingState({
      total:0,
      completed:0,
      percentage:0,
    });
    let curateDetails = await fetchCurateDetails(getIdFromSlug(exhibitionId), null);
    let data = curateDetails && curateDetails.models && curateDetails.models[0];
    let dimensions = curateDetails && curateDetails.dimensions;
    let dimensions2dCount = (dimensions && dimensions.length > 0) ? getTotalCurateRecords(dimensions) : 0;
    setCurateDataLoadingState(prevState => ({
      ...prevState,
      total: dimensions2dCount,
    }));
    if (data.renderVersion) {
      const modelSignedUrl =  await getWithProgress(data.model.signedUrl);
      addCurateDataLoadingPercentage();
      const HDRProbeSignedUrl =  await getWithProgress(data.HDRProbe.signedUrl);
      addCurateDataLoadingPercentage();
      const HDRLightMapSignedUrl =  await getWithProgress(data.HDRLightMap.signedUrl);
      addCurateDataLoadingPercentage();
      data = {
        data: {
          ...data,
          model: {
            data: {
              ...data.model,
              signedUrl:modelSignedUrl
            }
          },
          HDRProbe: {
            data: {
              ...data.HDRProbe,
              signedUrl: HDRProbeSignedUrl
            }
          },
          HDRLightMap: {
            data: {
              ...data.HDRLightMap,
              signedUrl: HDRLightMapSignedUrl
            }
          },
          room: {
            data: {
              renderVersion: data.renderVersion
            }
          }
        }
      }
    }
    const assetBlobMap = {};
    for (let dimension of dimensions) {
      const artwork = dimension.data && dimension.data.artwork && dimension.data.artwork.data;
      const artworkType = dimension.data.artwork.data.type;
      const resource = artworkType === "2d" ? dimension.data.artwork.data.image : artworkType === "video" ? dimension.data.artwork.data.videoThumbnail : dimension.data.artwork.data.thumbnail;

      var size = "1024sq_";

      if (parseInt(dimension.data.artwork.data.width) + parseInt(dimension.data.artwork.data.height) > 100) {
        size = "2048sq_";
      }
      dimension.data.artwork.data.basisURL =  await getWithProgress(resource.data.CFURL + size + resource.data.name + ".basis" + resource.data.signature);
      addCurateDataLoadingPercentage();

      if(dimension.data && dimension.data.asset) {
        if(!assetBlobMap[dimension.data.asset.data.id]){
          const assetBlob = await getWithProgress( dimension.data.asset.data.file.data.signedUrl);
          addCurateDataLoadingPercentage();
          dimension.data.asset.data.file.data.signedUrl = assetBlob;
          assetBlobMap[dimension.data.asset.data.id] = assetBlob;
        }else{
          dimension.data.asset.data.file.data.signedUrl = assetBlobMap[dimension.data.asset.data.id];
        }

      }

      if(dimension.data && dimension.data.HDRArtworkMap ){
        const artworkBlob = await getWithProgress( dimension.data.HDRArtworkMap.data.signedUrl);
        addCurateDataLoadingPercentage();
        dimension.data.HDRArtworkMap.data.signedUrl = artworkBlob;
      }
      if(dimension.data && dimension.data.HDRAssetMap ){
        const artworkBlob = await getWithProgress( dimension.data.HDRAssetMap.data.signedUrl);
        addCurateDataLoadingPercentage();
        dimension.data.HDRAssetMap.data.signedUrl = artworkBlob;
      }
      if(dimension.data.artwork.data && dimension.data.artwork.data.type === "2d"){
        const artworkImage = dimension.data.artwork.data.image && dimension.data.artwork.data.image.data;
        if(artworkImage){
          const artworkImageUrl = artworkImage.signedUrlLP_1024;
          const artworkBlob = await getWithProgress(artworkImageUrl);
          addCurateDataLoadingPercentage();
          artworkImage.signedUrlLP_1024 = artworkBlob;
        }

      }
      if(dimension.data.artwork.data.thumbnail){
        const artworkBlob = await getWithProgress(dimension.data.artwork.data.thumbnail.data.signedUrl);
        addCurateDataLoadingPercentage();
        dimension.data.artwork.data.thumbnail.data.signedUrl = artworkBlob;
      }
      if(dimension.data.artwork.data && dimension.data.artwork.data.type === "3d"){
        let resp = await fetchModel(dimension.data.artwork.data.bundleId);
        addCurateDataLoadingPercentage();
        const artworkBlob = await getWithProgress(resp.data.signedUrl);
        addCurateDataLoadingPercentage();
        dimension.data.artwork.data.modelUrl = artworkBlob;
      }
      if(dimension.data.artwork.data && dimension.data.artwork.data.type === "video" && dimension.data.artwork.data.videoThumbnail){
        const artworkBlob = await getWithProgress(dimension.data.artwork.data.videoThumbnail.data.signedUrl);
        addCurateDataLoadingPercentage();
        dimension.data.artwork.data.videoThumbnail.data.signedUrl = artworkBlob;
      }
    }
    // setCurateDataLoadingState(prevState => ({
    //     ...prevState,
    //     percentage:100
    // }));
    setCurateState({
      models:data,
      dimensions
    });
  }

  const fetchModel = async (modelId) => {
    const modelResp = await axiosMain({
      method: 'get',
      url: `${NURBS_API_URL}/assets/${modelId}`,
    });
    return modelResp.data;
  };

  useEffect(() => {
    logger.debug('coming events ===>>> ', events);
  }, [events]);

  const loadEvents = async () => {
    const eventsResp = await fetchEvents(getIdFromSlug(exhibitionId));
    if (eventsResp) {
      logger.debug('eventsResp : ', eventsResp);
      setEvents(eventsResp);
    }
  };

  const loadDetails = async () => {
    const exhibDetails = await loadExhibitionDetails();
    if (exhibDetails) {
      loadEvents();
      const exhibArtworks = await loadExhibitionArtworks();
      if (exhibArtworks) {
        const articles = await loadExhibitionArticles();
        if (articles) {
          loadExhibitionArtists();
        }
      }
    }
  };

  useEffect(() => {
    logger.debug('events comming are => ', events, events.length);
  }, [events]);

  const linkToCurate = () => {
    if (exhibitionId && props.isLoggedIn) {
      history.push({
        pathname: `/exhibitions/${getIdFromSlug(exhibitionId)}/curate`,
        state: {details: details, exhibtionLoaderImage},
      })
    } else if (!props.isLoggedIn) {
      history.push(`/login?/exhibitions/${getIdFromSlug(exhibitionId)}/curate`)
    }
  };

  const toggleFavourite = async () => {
    if (props.isLoggedIn) {
      const resp = await favouriteExhibition(details.data.id);
      if (resp.data) {
        const updatedDetails = {
          ...details,
          data: {
            ...details.data,
            isFavourite: resp.data.isFavourite
          }
        };
        props.updateFavourite();
        setDetails(updatedDetails);
      }
    } else {
      history.push(`/login?/exhibitions/${exhibitionId}`)
    }
  };

  const handleGalleryClick = (galleryId) => {
    history.push(`/galleries/${galleryId}`)
  };

  const handleArtistClick = (artistId) => {
    history.push(`/artists/${artistId}`)
  };

  const handleArtworkClick = (artworkId) => {
    props.setArtworkId(artworkId)
    props.setExhibitionId(exhibitionId)
  };

  const toggleRegister = (id, val) => {
    let newEvents = [...events];
    newEvents.find(event => event.data.id === id).data.isRegistered = val;
    setEvents(newEvents);
  };

  const handleRegister = async (eventId) => {
    if (!props.isLoggedIn) {
      history.push(`/login?/exhibitions/${exhibitionId}`)
    } else if (eventId) {
      const eventRegisterResp = await RegisterEvent(eventId);
      if (eventRegisterResp && eventRegisterResp.status === 200) {
        toggleRegister(eventId, true);
      }
    }
  };

  const handleUnRegister = async (eventId) => {
    if (!props.isLoggedIn) {
      history.push(`/login?/exhibitions/${exhibitionId}`)
    } else if (eventId) {
      const eventRegisterResp = await UnRegisterEvent(eventId);
      if (eventRegisterResp && eventRegisterResp.status === 200) {
        toggleRegister(eventId, false);
      }
    }
  };

  const resetAll = () => {
    setCarouselState(1);
    carouselRef.current.resetAll();
  }

  useEffect(() => {
    if (props.activeArtwork) {
      props.setArtworkId(props.activeArtwork);
    }
    props.setAciveArtWork()
    // loadCurateDetails();
  }, [])

  const loadCurateDetails = async () => {
    const details = await fetchCurateDetails(exhibitionId, null);
    logger.debug('details = ', details)
    const signedUrl = await fetchWithProgress(details.models[0].data.model.data.signedUrl);
    logger.debug('signedUrl = ', signedUrl)
  }

  const handleEventClick = (eventId, e) => {
    e.stopPropagation();
    if (exhibitionId && props.isLoggedIn) {
      history.push(`/exhibitions/${getIdFromSlug(exhibitionId)}/curate?eventId=${eventId}`, {
        details: details,
        exhibtionLoaderImage,
        curateState,
        eventId
      })

    } else if (!props.isLoggedIn) {
      history.push(`/login?/exhibitions/${getIdFromSlug(exhibitionId)}/curate`)
    }
  }

  const getLiveStatus = (event) => {
    // logger.debug('event = ', event)
    if (event.status == "live") {
      return true;
    } else if (event.status == "pre-live") {
      return event.isModerator;
    } else
      return false;
  }

  const fetchWithProgress = async (url) => {
    const resp = await axiosMain.get(url, {
      responseType: 'blob',
      onDownloadProgress: (event) => {
        const percentage = (event.loaded * 100) / event.total;
        logger.debug('onDownloadProgress = ', percentage)
      }
    })
    return URL.createObjectURL(resp.data);
  }

  const endedVideo=(event)=>{
    event.target.currentTime=0;
  }
  useEffect(()=>{
       setTimeout(() => {
        document.querySelector('#desc-para').innerHTML = details.data.description || details.data.descriptionHtml || "";
    }, 1000);
  },[details])
  return (
       <div className='exhibition-details-main'>
        {
          (isLoadingDetails === false) ? (
              <>
                {/* <CurateProgressLoader
                            progress={curateDataLoadingState.percentage}
                            isLoaded={curateDataLoadingState.percentage > 99}
                            // isLoaded={true}
                            sceneName={sceneName}
                            enterExhibition={() => history.push(`/exhibitions/${getIdFromSlug(exhibitionId)}/curate`, { curateState,exhibtionLoaderImage })}
                            image={details.data.image.data.signedUrl1920x1080.replace('/1920_', '/350_')}
                        /> */}
                {/* <HeadTags
                            title={details.data.name}
                            description={details.data.about.data.text}
                            image={details.data.image.data.signedUrl1920x1080.replace('/1920_', '/350_')}
                            type={'exhibition'}
                            id={details.data.id}
                        /> */}
                {/* <div className={'background-gray'}>
                        </div> */}
                <div className={(props.artworkId ? 'details stop-scroll' : 'details') + ` container loadingCurate`}>
                  <div className='details__container'>
                    {
                      details.data && details.data.id &&
                      <div>
                        <ScrollAnimation>
                          <Fragment>
                            <Title value={(details.data && details.data.name) || ''}
                                   className='details__heading'/>
                            <Paragraph id='desc-para'
                                   className='details__paragraph draft-text-v2'/>
                                  <div className='details__flex'>

                              <div>
                                <Label
                                    value={(details.data && details.data.organisation.data.name) || ''}/>
                                <p className='timeline details__timeline'>
                                  {((details.data && details.data.startedAt) || '')}
                                  {' →  '}
                                  {((details.data && details.data.endedAt + ' ') || '')}
                                  &nbsp;&nbsp;
                                  {'•'}
                                  &nbsp;&nbsp;&nbsp;
                                  {((details.data && details.data.organisation && details.data.organisation.data.locations && capitalizeFirstLetter(details.data.organisation.data.locations)) || '')}
                                </p>
                              </div>
                              {/* <div className='details__links details__flex'>
                                              <div className='action-icons'>
                                                <ShareBtn
                                                  shareUrl={getShareURL('exhibition', details.data.slug || details.data.id)}/>
                                                <IconBtn
                                                  type={(details.data && details.data.isFavourite) ? "favouriteOn2" : "favourite2"}
                                                  onClick={() => toggleFavourite()}/>
                                              </div>
                                              <Button
                                                value='Enter Exhibition'
                                                className='button__bright enter-exhibition-btn'
                                                iconType="next"
                                                onClick={() => history.push(`/exhibitions/${getIdFromSlug(exhibitionId)}/curate`, { curateState })}
                                              />
                                            </div> */}
                            </div>
                            <div className={`details__actions ${isDetailsPreviewOnly? 'wide-buttons': ''}`}>
                              <label className='share-action'>
                                <Button
                                    value='Share exhibition'
                                    className='btn__dark'
                                    iconType="share"
                                    type="secondary"
                                />
                                {isDetailsPreviewOnly? 
                                <ShareBtn up
                                    shareUrl={getShareURL('exhibition', details.data.slug || details.data.id)} entityUuid={details.data.uuid} />
                                    :
                                    <ShareBtn 
                                    shareUrl={getShareURL('exhibition', details.data.slug || details.data.id)} entityUuid={details.data.uuid} />
                                }
                              </label>
                              <Button
                                  value='Bookmark exhibition'
                                  className='btn__dark'
                                  iconType={(props.isLoggedIn && details.data && details.data.isFavourite) ? "favouriteOn" : "favourite"}
                                  type="secondary"
                                  onClick={() => toggleFavourite()}
                              />
                            </div>
                            <div className='details__preview' ref={headerImage} onClick={handleMobileExhibitionClick}>
                              {details.data.video && details.data.video.data && details.data.video.data.signedDesktopMedia ?
                                  <video  onEnded={endedVideo} controls poster={details.data.video.data.signedVideoThumbnail}
                                          src={IsSafari() || isMobileDevice() ? details.data.video.data.signedMobileMedia : details.data.video.data.signedDesktopMedia}>
                                  </video>
                                  :details.data?.image &&
                                  <ImageLoader
                                      imgUrls={[constructImageUrl(details.data?.image , "820")]}/>}
                            </div>
                          </Fragment>
                        </ScrollAnimation>

                        {
                          details.data.about &&
                          <div className='details__flex details__press details__media-wrapper fill-screen'>
                            {
                              details.data.about.data.text && (
                                  <ScrollAnimation>
                                    <div className='text-section'>
                                      <Paragraph value={details.data.about.data.text}/>
                                    </div>
                                  </ScrollAnimation>
                              )
                            }
                            {details.data.about.data.elements && <ScrollAnimation>
                              {
                                details.data.about.data.elements.slice(0, showAllDetails ? details.data.about.data.elements.length : 1)
                                    .map((element, index) => {
                                      if (element.data.elementType === 'normal' && element.data.text) {
                                        return <div className='text-section' key={index}>
                                          <Paragraph value={element.data.text}/>
                                        </div>
                                      } else if (element.data.elementType === 'bold') {
                                        let splitted = element && element.data && element.data.text && element.data.text.split("\n")
                                        return splitted && splitted.map((split, splitIndex) => {
                                          if (!!split) {
                                            return <div className='text-section' key={splitIndex}>
                                              <Heading value={split || ''}/>
                                            </div>
                                          }
                                        })
                                      } else if (element.data.elementType === 'image' && element.data.image && element.data.image.data.signedUrl) {
                                        return <div className='media-section' key={index}>
                                          <img
                                              className='details__press-preview'
                                              src={element.data.image && element.data.image.data.signedUrl}
                                              alt='Press'
                                          />
                                        </div>
                                      }
                                    })
                              }
                            </ScrollAnimation>}
                            {(details.data.about && details.data.about.data.elements.length > 1) && (
                                <ScrollAnimation>
                                  <div onClick={() => toggleAllDetails(!showAllDetails)}
                                       className='read-more'>
                                    <Label value={showAllDetails ? 'Read Less' : 'Read More'}/>
                                    <img src={ArrowDownDark}
                                         style={showAllDetails ? {transform: 'rotate(180deg)'} : {}}
                                         alt="arrow down"/>
                                  </div>
                                </ScrollAnimation>)}
                          </div>
                        }
                      </div>
                    }
                  </div>

                  {
                    events.length > 0 && (
                        <ScrollAnimation>
                          <div style={{paddingTop: '0'}}>
                            <DetailsHeader title="Sessions"/>
                            {
                              events.length > 0 && events.map((event, index) => {
                                const {
                                  name,
                                  isRegistered,
                                  shouldPlayback,
                                  status,
                                  startTime,
                                  id,
                                  description,
                                  image,
                                  speakers
                                } = event && event.data;
                                const isLive = getLiveStatus(event && event.data)
                                const webpImgUrl = image && constructImageUrl(image ,"720");
                                const imgUrl = image && constructImageUrl(image ,"720");
                                const moderator = speakers && speakers.length > 0 && speakers.filter(speaker => speaker.isModerator == "true");
                                return (
                                    <div key={index}>
                                      <Card imgSrc={image && image.data && [webpImgUrl, imgUrl]}
                                            title={name}
                                            description={description}
                                            horizontal
                                            event
                                            curateSidebar
                                            isLive={isLive}
                                            shouldPlayback={shouldPlayback}
                                            onClick={(e) => handleEventClick(id, e)}
                                            moderatedBy={moderator[0] && moderator[0].name}
                                            timeLine={formatDateTime(startTime)}
                                            handleRegister={() => handleRegister(id)}
                                            handleUnRegister={() => handleUnRegister(id)}
                                            registered={isRegistered}
                                            index={index}
                                            exhibitionEvents
                                      />
                                    </div>
                                )
                              })
                            }
                          </div>
                          <br/><br/>
                        </ScrollAnimation>
                    )
                  }

                  {
                    details.data && details.data.collateral && details.data.collateral.data && details.data.collateral.data.collateralElement && details.data.collateral.data.collateralElement.length > 0 &&
                    <div className="collateral-wrapper">
                      <CollateralLayouts layout={details.data.collateral.data.collateralElement}/>
                    </div>
                  }


                  {articles && articles.length > 0 && <div className="articles-wrapper" ref={articlesRef}>
                    <DetailsHeader title="Collateral Content"/>
                    <div className="articles">
                      {
                        articles.map((article, index) => {
                          const {title, author, writtenDate, id} = article.data;
                          return (
                              <React.Fragment key={index}>
                                <ArticleBox
                                    articleTitle={title}
                                    id={id}
                                    authorName={author} date={writtenDate}/>
                              </React.Fragment>
                          )
                        })
                      }
                    </div>
                  </div>}

                  {
                    !isLoadingArtworks && artworks.length > 0 &&
                    <div>
                      <ScrollAnimation>
                        <div className="artworks-wrapper">
                          <DetailsHeader title={artworks.length>1?"Artworks":"Artwork"}
                                         position={artworks && artworks.length > 1 && `${carouselState} / ${artworks.length}`}
                                         setIsHover={(val) => setIsHover(val)}
                                         isHover={isHover}
                                         resetAll={resetAll}
                                         handleLeftClick={() => {
                                           (carouselRef && carouselRef.current && carouselRef.current.moveCarousel("left"))
                                         }}
                                         handleRightClick={() => {
                                           (carouselRef && carouselRef.current && carouselRef.current.moveCarousel("right"))
                                         }}
                          />
                          {/*@ts-ignore*/}
                          <Carousel onClickItem={handleArtworkClick} type="secondary" data={artworks}
                                    setCarouselState={setCarouselState}
                                    onMouseLeave={() => setIsHover(false)}
                                    onMouseEnter={() => setIsHover(true)}
                                    ref={carouselRef}/>
                        </div>
                      </ScrollAnimation>
                    </div>
                  }

                  {
                    !isLoadingArtists &&
                    <div className='details__container'>
                      <ScrollAnimation>
                        <DetailsHeader title={artists.length>1?"Artists":"Artist"}/>
                      </ScrollAnimation>
                      <ScrollAnimation>
                        <div className='details__artists'>
                          {
                            artists.length > 0 ? artists.map((artist, index) => {
                              const WebpImg = artist.data && artist.data && artist.data.image && constructImageUrl(artist.data.image ,"720");
                              const Img = artist.data && artist.data.image  && constructImageUrl(artist.data.image ,"720");
                              return (
                                  <React.Fragment key={index}>
                                    <Card
                                        imgSrc={artist.data.image && [WebpImg, Img]}
                                        title={artist.data.name}
                                        description={artist.data.description}
                                        horizontal
                                        onClick={() => handleArtistClick(artist.data.slug || artist.data.id)}
                                    />
                                  </React.Fragment>
                              )
                            }) : (
                                <>
                                  <P1 value="No Artists Found."/>
                                  <br/><br/><br/>
                                </>
                            )
                          }
                        </div>
                      </ScrollAnimation>
                      <ScrollAnimation>
                        <DetailsHeader
                            title={((details.data.organisation && details.data.organisation.data && details.data.organisation.data.type) === 'museum' ? 'Institution' : 'Galleries') || ''}/>
                      </ScrollAnimation>
                      <ScrollAnimation>
                        <div className="exhibitions-galleries-wrapper">
                          {
                            details.data.organisation ?
                                <Card
                                    imgSrc={details.data.organisation?.data?.image && [constructImageUrl(details.data.organisation.data.image,"720")]}
                                    title={details.data.organisation.data.name}
                                    description={details.data.organisation.data.overview}
                                    location={details.data.organisation.data.locations}
                                    horizontal
                                    onClick={() => handleGalleryClick(details.data.organisation.data.slug || details.data.organisation.data.id)}
                                /> : (
                                    <>
                                      <br/>
                                      <P1 value="Galleries not Found."/>
                                    </>
                                )
                          }
                        </div>
                      </ScrollAnimation>
                    </div>
                  }
                  {/*</div>*/}
                </div>
              </>
          ) : <Spinner className={'exhibition-spinner'}/>
        }
      </div>
  )
};

export default ExhibitionDetails
