import {
    FETCH_EXHIBITIONS_SUCCESS,
    GET_EXHIBITION_DETAILS,
    GET_EXHIBITION_DETAILS_SUCCESS,
    RESET_EXHIBITIONS, SET_EXHIBITIONS_LOADER, TOGGLE_FAVOURITE,
    UPDATE_SORT_BY,
    SET_ACTIVE_ARTWORK,
    SET_ARTICLE_DETAILS_BACK
} from './actionTypes'

const initialState = {
    details: {},
    exhibitions: [],
    exhibitionsFirstLoad: true,
    exhibitionsLoading: true,
    detailsLoading: false,
    canLoadMore: true,
    currentPage: 0,
    sortBy: 'all',
    pages: {},
    activeArtWork: null,
    isArticleDetailsBack: false
}

function getDetails(state = initialState, action) {
    switch (action.type) {
        case GET_EXHIBITION_DETAILS:
            return {...state, details: action.payload}

        case UPDATE_SORT_BY:
            return {...state, sortBy: action.payload}

        case SET_EXHIBITIONS_LOADER:
            return {...state, exhibitionsLoading: action.payload}

        case RESET_EXHIBITIONS:
            if (action.payload) {
                return {
                    sortBy: 'all',
                    details: {},
                    exhibitions: [],
                    exhibitionsLoading: true,
                    exhibitionsFirstLoad: true,
                    detailsLoading: false,
                    canLoadMore: true,
                    currentPage: 0,
                    pages: {},
                }
            } else {
                return {
                    sortBy: 'all',
                    details: {},
                    exhibitions: [],
                    exhibitionsLoading: true,
                    detailsLoading: false,
                    exhibitionsFirstLoad: true,
                    canLoadMore: true,
                    currentPage: 0,
                    pages: {},
                }
            }

        case FETCH_EXHIBITIONS_SUCCESS:
            if (action.payload) {
                return {
                    ...state,
                    exhibitions: action.payload.currentPage === 1 ? action.payload.data : [...state.exhibitions, ...action.payload.data],
                    exhibitionsLoading: false,
                    pages: action.payload.page,
                    currentPage: action.payload.currentPage,
                    exhibitionsFirstLoad: false,
                    canLoadMore: action.payload.page.lastPage && action.payload.currentPage < action.payload.page.lastPage.index,
                }
            }

        case GET_EXHIBITION_DETAILS_SUCCESS:
            return {...state, details: action.payload, detailsLoading: false}

        case TOGGLE_FAVOURITE:
            const newExhibitions = state.exhibitions.map(exhibition => {
                if (exhibition.data.id == action.payload.exhibitionId) {
                    exhibition = {
                        ...exhibition,
                        data: {
                            ...exhibition.data,
                            isFavourite: action.payload.isFavourite
                        }
                    }
                    return exhibition;
                }
                return exhibition;
            });
            return {
                ...state,
                exhibitions: newExhibitions,
            }
        case SET_ACTIVE_ARTWORK:
            return {
                ...state,
                activeArtWork: action.payload
            }
        case SET_ARTICLE_DETAILS_BACK:
            return {
                ...state,
                isArticleDetailsBack: action.payload
            }
        default:
            return state
    }
}

export default getDetails
