import React, { useState, useEffect } from 'react';
import {Title} from '../Typography2';
import {Link} from 'react-router-dom';
import { constructImageUrl } from '../../utils';

const TIME_DURATION = 7000;
const EXHIBITIONS_DELAY = 0;
const INSTITUTION_DELAY = 1200;
const GALLERIES_DELAY = 1800;
const COLLECTIVE_DELAY = 600;

const RenderItem = ({item, index}) => {
    const {title, data} = item;
    const [currentDataCount, setCurrentDataCount] = useState(0);
    const [isDelay, setIsDelay] = useState(true);

    const switchData = () => {
        if(currentDataCount < data.length - 1){
            setCurrentDataCount(currentDataCount => currentDataCount + 1)
        }
        else{
            setCurrentDataCount(0)
        }
    }

    const setTimeDelay = () => {
        if(!isDelay){
            return switchData();
        }
        else {
            setTimeout(() => {
                switchData();
                setIsDelay(false);
            }, getTimeDuration())
        }
    }

    const getTimeDuration = () => {
        switch(index){
            case 0:
                return EXHIBITIONS_DELAY;
            case 1:
                return INSTITUTION_DELAY;
            case 2:
                return GALLERIES_DELAY;
            case 3:
                return COLLECTIVE_DELAY;
        }
    }

    const getLink = (val) => {
        let link = "";
        if(val === "institutions"){
            link = "museums-&-public-galleries";
        } else if (val === "collaborations") {
            link = "collaborations/vortic-curated";
        } else {
            link = val;
        }
        return `/${link}`;
    }

    useEffect(() => {
        setTimeout(() => {
            setTimeDelay()
        }, TIME_DURATION)
    }, [currentDataCount]);

    return (
        <>
            {data && data.length > 0 &&
            <div className={'explore-card'}>
              <Link className='img-wrapper' to={getLink(title)}>
                <img
                  src={data && data[currentDataCount] && data[currentDataCount].data && data[currentDataCount].data.image && data[currentDataCount].data.image.data && constructImageUrl(data[currentDataCount].data.image,"1024")}
                  alt={''}/>
                  <div className='explore-card-content'>
                    <Title className="bright" value={title}/>
                    <div className='explore-card-arrow'>
                        <svg width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.5616 1.07192L18.3752 0.25836C18.7197 -0.0861199 19.2767 -0.0861199 19.6175 0.25836L26.7416 7.37884C27.0861 7.72332 27.0861 8.28035 26.7416 8.62116L19.6212 15.7416C19.2767 16.0861 18.7197 16.0861 18.3788 15.7416L17.5653 14.9281C17.2171 14.5799 17.2245 14.0119 17.5799 13.6711L21.9922 9.4677L1.00229 9.46771C0.514887 9.46771 0.122765 9.07558 0.122765 8.58818L0.122765 7.41548C0.122765 6.92808 0.514887 6.53596 1.00229 6.53596L21.9922 6.53596L17.5763 2.32891C17.2171 1.98809 17.2098 1.42006 17.5616 1.07192Z" fill="white"/>
                        </svg>
                    </div>
                  </div>
              </Link>
            </div>
            }
        </>
    )
}

const ImgItem = ({dataArray}) => {
    return (
        <>
            {
                dataArray && dataArray.map((item, index) => <RenderItem item={item} key={index} index={index} />)
            }
        </>
    )
}

export default ImgItem;