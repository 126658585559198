import Conversation from './Conversation';
import {connect} from "react-redux";
import { setCurrentConversation } from './redux/actions';
import {setConversation} from "../Global/redux/actions";

export default Conversation;
const mapStatesToProps = ({ conversation, profile }) => {
  return {
      conversationList: conversation.conversationList,
      currentConversation: conversation.currentConversation,
      profile: profile.profileData,
      enquireMessage: conversation.enquireMessage
  }
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentConversation: (conversation) => {dispatch(setCurrentConversation(conversation))},
        setChat: (val) => {dispatch(setConversation(val))}
    }
};

// export default connect(mapStatesToProps, mapDispatchToProps)(Conversation)
