import React, { useState } from 'react'
import {IDropdownProps} from './types';
import {Paragraph} from '../Typography2';
import IconBtn from '../IconBtn';

const Dropdown: React.SFC<IDropdownProps> = ({ options, selected, onChange }) => {
    const [ dropdown, setDropdown ] = useState(false);

    const getSelectedLabel = () => {
        if(selected) {
            let filter = options.filter(({value}) => value === selected);
            if(filter[0] && filter[0].label) {
                return filter[0].label;
            }
            else {
                return selected;
            }
        }
    }

    return (
        <div className='dropdown-clickable-div' onClick={() => setDropdown(!dropdown)}>
            <div className={`dropdown-wrapper ${dropdown ? 'active' : ''}`}>
                <div className="dropdown-box">
                    <Paragraph value={getSelectedLabel()} />
                    <IconBtn type="downCaretLight" />
                </div>
                <div className="dropdown">
                    {
                        options.map(({value, label}) => {
                            return <Paragraph key={value} onClick={() => onChange(value)} value={label} className={ selected === value ? 'hidden' : '' } />
                        })
                    }
                </div>
            </div>
        </div>
    )
};

export default Dropdown
