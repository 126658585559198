import React, { useEffect, useState } from "react";
import IconBtn from "../IconBtn";
import "./style.scss";
import { Paragraph } from "../Typography2";
import { secondsToHms } from "../../utils/index"

export default function AudioPlayer({
  isPlaying,
  progress = 0,
  totalDuration,
  currentTime,
  // setCurrentTime,
  setIsPlaying,
  audioUrl,
  isMinimizedVariant = false
}) {
  const time = `${secondsToHms(currentTime)} / ${secondsToHms(totalDuration)}`;
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if(currentTime === totalDuration && totalDuration > 0) {
      setTimeout(() => {
        setIsPlaying(false);
        // setCurrentTime(0);
        setReset(true);
        setTimeout(() => {
          setReset(false);
        })
      }, 1000)
    }
  }, [currentTime, totalDuration])

  return (
    <button className={`mobile-audio-player ${isMinimizedVariant ? 'mobile-audio-player--mini' : ''} ${reset ? 'reset' : ''} ${isPlaying ? "playing" : ""}`} onClick={() => setIsPlaying(!isPlaying)}>
      {!!audioUrl && <div className="icon-wrapper">
        {totalDuration > 0 && currentTime > 0 && <svg
          height="32"
          width="32"
          style={{ strokeDasharray: 100, strokeDashoffset: 100, animationDuration: `${totalDuration}s` }}
        >
          <circle
            cx="16"
            cy="16"
            r="15"
            stroke="#78D4A2"
            strokeWidth="1"
            fill="transparent"
          ></circle>
        </svg>}
        {
          // <IconBtn type={isPlaying ? "pausedOutlined" : "playOutlined"} />
          <IconBtn type={isPlaying ? "pausedOutlined" : "headPhone"} />
        }
      </div>}
      {!isMinimizedVariant && <Paragraph className="bright">
        {isPlaying ? time : "Play Audio"}
      </Paragraph>}
    </button>
  );
}
