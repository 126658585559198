import {useRef, useEffect, useState} from "react";
import CollaborationsCurate from '../CollaborationsCurate';
import {TUseCurateProps} from '../types';
import {
  getFilteredLinkedArtworks,
  getFormattedListOfArtworks
} from "../helpers/formatLinkedArtworks";

export const CURATE_ELEMENT_ID = 'curate-container';

// return type of the useCurate hook
export type useCurateReturn = {
  loadingProgress: number;
  loadingMessage: string;
  offset: number;
  scene: any;
  handleUpdateLobbyGalleries: (galleries: []) => void;
  handleFocusOnLobbyGallery: (gallery: any) => void,
  resizeLoop: () => void;
};

const useCurate = ({galleryFiles, linkedArtworks, collaborationsData}: TUseCurateProps): useCurateReturn => {
  const [scene, setScene] = useState<any | null>(null);
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const sceneRef = useRef<any | null>(null);

  const [offset, setOffset] = useState<number>(0);
  const loadingProgressHandler = (loadingProgress: number, loadingMessage: string) => {
    setLoadingProgress(loadingProgress);
    setLoadingMessage(loadingMessage);
  };

  const handleClickArtwork = () => {
  };
  const onVideoStateChange = () => {
  };
  const closeDetails = () => {
  };
  const onGuidedModeArtworkChange = () => {
  };

  const handleAnimationChange = (offset: number) => {
    setOffset(offset);
  };

  const sceneResizeListener = () => {
    if (scene) {
      try {
        scene.resize();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const resizeLoop = () => {
    const interval = setInterval(() => {
      scene.resize();
    }, 10);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      clearTimeout(timeout);
    }, 1000);
  };

  useEffect(() => {
    if (loadingProgress === 100) {
      setTimeout(() => {
        sceneResizeListener()
      }, 1000)
    }
  }, [loadingProgress])

  useEffect(() => {
    window.addEventListener("resize", sceneResizeListener);
    return () => {
      window.removeEventListener("resize", sceneResizeListener);
    }
  }, [scene])

  const loadArtworksInRoom = async () => {
    const filteredArtworks = getFilteredLinkedArtworks(linkedArtworks, galleryFiles.data.id);
    const formattedLinkedArtworks = await getFormattedListOfArtworks(filteredArtworks)
    if(sceneRef.current?.loadPlacementConfig){
      sceneRef.current?.loadPlacementConfig(formattedLinkedArtworks)
    }
  }

  const onDoneCurateLoading = async () => {
    await loadArtworksInRoom()
  }

  const navigateToExhibitionPage = (slug) => {
    window.localStorage.setItem('isOpenedFromCollaborations', 'true');
    window.open(`/exhibitions/${slug}`, '_blank');
  }

  const getExhibitionSlugFromGalleryId = (galleryId) => {
    return collaborationsData.filter(gallery => gallery.id === galleryId)[0]?.exhibitions[0]?.slug;
  }


  const handleGallerySelectFromCurate = (galleryId) => {
    try {
      navigateToExhibitionPage(getExhibitionSlugFromGalleryId(galleryId))
    } catch (e) {
      console.log('err = ', e)
    }
  }

  const loadRoom = () => {
    const instance = new CollaborationsCurate(
      CURATE_ELEMENT_ID,
      {
        ...galleryFiles,
        data: {
          ...galleryFiles.data,
          room: {
            data: {
              renderVersion: 2
            }
          }
        }
      },
      loadingProgressHandler,
      handleClickArtwork,
      null,
      onVideoStateChange,
      closeDetails,
      onGuidedModeArtworkChange,
      handleAnimationChange,
      () => {
      },
      handleGallerySelectFromCurate,
      onLobbyGallerySelected,
      onDoneCurateLoading
    );
    instance.resize();
    sceneRef.current = instance; // Update the ref
    setScene(instance);
  }

  const onLobbyGallerySelected = (galleryId) => {
    document.dispatchEvent(new CustomEvent("listing_on_gallery_selected", {
      detail: { galleryId }
    }));
  }

  useEffect(() => {
    if (galleryFiles) {
      loadRoom();
    }
  }, [galleryFiles])

  const handleUpdateLobbyGalleries = (galleries: []) => {
    scene?.updateLobbyGalleries(galleries)
  }

  const handleFocusOnLobbyGallery = (gallery) => {
    scene?.focusonLobbyGallery(gallery)
  }

  return {
    loadingProgress,
    loadingMessage,
    offset,
    handleUpdateLobbyGalleries,
    handleFocusOnLobbyGallery,
    scene,
    resizeLoop
  }
}

export type useCurateTypes = ReturnType<typeof useCurate>;

export default useCurate;
