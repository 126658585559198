import React, {Fragment, useEffect } from 'react'
import Card from '../../components/Card'
import {useHistory} from "react-router";
import Spinner from "../../components/Spinner";
import Exhibition from "../../components/Exhibition";
import {favouriteExhibition} from "../../services/api";
import {Paragraph} from '../../components/Typography2';
import { constructImageUrl } from '../../utils';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const Favourites = (props) => {
    const history = useHistory();
    const { setContentType } = useTrackingCode();
    const {
        favourite: {favourites, favouritesLoading, isFavouritesUpdated},
        fetchFavourites,
        toggleFavouriteExhibition
    } = props;

    const isFavouritesEmpty = () => {
        return favourites.organisations.length === 0 && favourites.artists.length === 0 && favourites.exhibitions.length === 0 && favourites.artworks.length === 0;
    };

    useEffect(() => {
        setContentType('favourites-listing-page');
        if (isFavouritesEmpty() || isFavouritesUpdated) {
            loadData();
        }
    }, []);

    const loadData = async () => {
        await fetchFavourites();
    };

    const toggleFavourite = async (id) => {
        const resp = await favouriteExhibition(id);
        if (resp.data) {
            toggleFavouriteExhibition(id, resp.data.isFavourite);
            props.updateDataInFavourite('exhibitions', id, resp.data.isFavourite);
        }
    };

    const handleGalleryClick = (galleryId) => {
        history.push(`/galleries/${galleryId}`)
    };

    const handleArtistClick = (artistId) => {
        history.push(`/artists/${artistId}`)
    };

    const handleArtworkClick = (artworkId) => {
    };

    const handleExhibitionClick = (exhibitionId) => {
        history.push(`/exhibitions/${exhibitionId}`);
    };

    return (
        <>
            <br/><br/>
            <div className='container exhibitions-wrapper galleries-wrapper favourites-wrapper'>
                {
                    favouritesLoading ?
                        <Spinner/>
                        :
                        isFavouritesEmpty() ?
                            <Paragraph className="address gray" value={'No favourites.'}/>
                            :
                            <Fragment>
                                {
                                    favourites.artists.map(({data}) => {
                                        return <Card
                                            imgSrc={data.image && [constructImageUrl(data.image, "600")]}
                                            title={data.name}
                                            description={data.description}
                                            horizontal
                                            onClick={() => handleArtistClick(data.id)}
                                        />
                                    })
                                }

                                <div className="cards-container">
                                    <div>
                                    {
                                        favourites.artworks.map(({data}) => {
                                        return <Exhibition
                                        id={data.id}
                                        exhibitionImg={data.image ? [constructImageUrl(data.image,"1080")] : [constructImageUrl(data.thumbnail,"1080")]}
                                        title={data.title}
                                        onClick={handleExhibitionClick}
                                        subTitle={data.organisation && data.organisation.data.overview}
                                        galleryName={data.organisation && data.organisation.data.name}
                                        locations={data.organisation && data.organisation.data.locations? data.organisation.data.locations : ''}
                                        startedAt={data.createdAt}
                                        endedAt={data.updatedAt}
                                        toggleFavourite={(id) => toggleFavourite(id)}
                                        isFavourite={data.isFavourite}
                                        artistName={data.artist.data.name}
                                        className="no-link"
                                        uuid={data.uuid}
                                        slug={data.slug}
                                        isDateVisible={data.isDateVisible}
                                        />
                                    })
                                }
                                        {
                                            favourites.exhibitions.map(({data}) => {
                                                return <Exhibition
                                                    id={data.id}
                                                    exhibitionImg={data.image && [constructImageUrl(data.image,"1080")]}
                                                    title={data.name}
                                                    onClick={handleExhibitionClick}
                                                    subTitle={data.organisation && data.organisation.data.overview}
                                                    galleryName={data.organisation && data.organisation.data.name}
                                                    locations={data.organisation && data.organisation.data.locations}
                                                    startedAt={data.startedAt}
                                                    endedAt={data.endedAt}
                                                    toggleFavourite={(id) => toggleFavourite(id)}
                                                    isFavourite={data.isFavourite}
                                                    uuid={data.uuid}
                                                    slug={data.slug}
                                                    isDateVisible={data.isDateVisible}
                                                />
                                            })
                                        }
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                                {
                                    favourites.organisations.map(({data}) => {
                                        return <Card
                                            imgSrc={data.image && [constructImageUrl(data.image,"1080")]}
                                            title={data.name}
                                            description={data.overview}
                                            location={data.locations}
                                            horizontal
                                            onClick={() => handleGalleryClick(data.id)}
                                        />
                                    })
                                }
                                <br/>
                            </Fragment>
                }
            </div>
        </>
    )
}

export default Favourites
