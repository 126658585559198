import { getVariableFromUrl, isEmpty, redirectToIndex } from './utils';
import { SendBirdAction } from './SendBirdAction';
import { SendBirdConnection } from './SendBirdConnection';
import logger from '../../utils/logger';
import { ChatLeftMenu } from './ChatLeftMenu';
// import { Chat } from './Chat';
import { UPDATE_INTERVAL_TIME } from './const';

import SendBirdSyncManager from 'sendbird-syncmanager';
// import { Toast } from './components/Toast';
import { Chat } from './Chat';

const sb = new SendBirdAction();

let chat = null;
let chatLeft = null;

const createConnectionHandler = () => {
  const manager = SendBirdSyncManager.getInstance();
  const connectionManager = new SendBirdConnection();
  connectionManager.onReconnectStarted = () => {
    // Toast.start(document.body, 'Connection is lost. Trying to reconnect...');
    connectionManager.channel = chat.channel;
  };
  connectionManager.onReconnectSucceeded = () => {
    // chatLeft.updateUserInfo(SendBirdAction.getInstance().getCurrentUser());
    // Toast.remove();
    manager.resumeSync();
  };
  connectionManager.onReconnectFailed = () => {
    connectionManager.reconnect();
  };
};

const updateGroupChannelTime = () => {
  // setInterval(() => {
  // LeftListItem.updateLastMessageTime();
  // }, UPDATE_INTERVAL_TIME);
};

// document.addEventListener('DOMContentLoaded', () => {
// });

export const initSync = async (userid, nickname, profile_url = '', type) => {
  // const { userid, nickname } = getVariableFromUrl();
  if (isEmpty(userid) || isEmpty(nickname)) {
    redirectToIndex('UserID and Nickname must be required.');
  }

  SendBirdSyncManager.sendBird = sb.sb;
  const options = new SendBirdSyncManager.Options();
  options.messageCollectionCapacity = 2000;
  options.messageResendPolicy = 'automatic';
  options.automaticMessageResendRetryCount = 5;
  options.maxFailedMessageCountPerChannel = 50;
  options.failedMessageRetentionDays = 7;
  // SendBirdSyncManager.getInstance().clearCache();

  SendBirdSyncManager.setup(userid, options, () => {
    // chatLeft = new ChatLeftMenu();
    // setTimeout(() => {
    //   console.log('chatLeft.channels = ', chatLeft.channels);
    // });
    // chat = new Chat();
    // updateGroupChannelTime();
    // chatLeft.loadGroupChannelList(true);

    if(type && type ==="vip") {
      type = 'collector'
    }

    logger.debug('profile_url = ', profile_url, userid, type);
    sb.connect(userid.toString(), nickname, profile_url)
      .then((user) => {
        logger.debug('user = ', user);
        if (!user.metaData.type) {
          user.createMetaData({ type: type }, (_user, _err) => {
            logger.debug('_user = ', _user, _err);
            createConnectionHandler();
          });
        } else {
          user.updateMetaData({ type: type }, (_user, _err) => {
            logger.debug('_user = ', _user, _err);
            createConnectionHandler();
          });
          // createConnectionHandler();
        }
        // sb.updateMetaData({data: type}, (_, __) => {
        //   createConnectionHandler();
        // })
        // chatLeft.updateUserInfo(user);
      })
      .catch((e) => {
        logger.error('e = ', e)
        // Toast.start(document.body, 'Connection is not established.');
      });
  });
};
