import React, { useEffect, useRef, useState } from "react";
import AdvanceCarousel from "../../components/AdvanceCarousel";
import BannerItem from "../../components/BannerItem";
import DiscoverCard from "../../components/DiscoverCard";
import Card from "../../components/Card";
import smoothscroll from "smoothscroll-polyfill";
import DiscoverHeader from "../../components/DiscoverHeader";
import Exhibition from "../../components/Exhibition";
import ExploreVortic from "../../components/ExploreVortic";
import { IDiscoverLayouts } from "../DiscoverLayout/types";
import {
  favouriteExhibition,
  getDiscoverDetails,
  getDiscoverExhibitions,
  getDiscoverPreviewDetails,
  RegisterEvent,
  UnRegisterEvent,
} from "../../services/api";
import ScrollAnimation from "../../components/ScrollAnimation/ScrollAnimation";
import { useHistory } from "react-router";
import { formatDate, constructImageUrl } from "../../utils";
import Flickity from "react-flickity-component";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const Discover: React.FC<IDiscoverLayouts> = React.forwardRef(
  ({
    getDetails,
    setArtworkId,
    details,
    preview,
    isLoggedIn,
    updateFavourite,
    toggleFavouriteExhibition,
    isConversation
  }, ref) => {
    const [layout, setLayouts] = useState([]);
    const [animated, setAnimation] = useState(true);
    const [bannerData, setBannerData] = useState([]);
    const [scrollY, setWindowScrollY] = useState(0);
    const [state, setState] = useState({
      featuredExhibition: null,
      exploreVortic: null,
      collectives: null,
    });
    const { setContentType } = useTrackingCode();

    // useEffect(() => {
    //   console.log('isConversation => ', isConversation);
    // }, [isConversation])

    const handleScroll = () => {
      setWindowScrollY(window.scrollY * 2);
    }

    useEffect(() => {
      setContentType('landing-page');
      fetchDetails();
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      }
    }, []);

    useEffect(() => {
      const currentlayout = [];
      let bannerTempData = [];
      details.length > 0 &&
        details.forEach((item) => {
          if (item.data.type === "exhibitions_banner" || item.data.type === "exhibition_banner") {
            bannerTempData.push(item);
          }
          currentlayout.push({
            ...item.data,
            w: item.data.columnType,
            order: item.data.type === "explore_vortic",
            h: 4,
          });
        });
      setBannerData(bannerTempData);
      setLayouts([
        ...currentlayout.filter((cl) => cl.type !== "explore_vortic"),
        ...currentlayout.filter((cl) => cl.type === "explore_vortic"),
      ]);
    }, [details]);
    const fetchDetails = async () => {
      if (preview) {
        const resp = await getDiscoverPreviewDetails();
        if (resp) {
          getDetails(resp);
        }
      } else {
        const resp = await getDiscoverDetails();
        if (resp) {
          getDetails(resp);
        }
      }
      window.scrollTo(0, 0);
    };

    return (
      <div className="discover">
        {/* Banner Carousel */}
        {bannerData?.length > 0 && bannerData.map((banner, index) => {
          return (
            <div style={{ gridRowStart: ((banner.data.y + 1)), gridRowEnd: "auto" }} key={index}>
              <DiscoverBanner
                id={banner.data.id}
                isAutoScrollEnabled={banner.data.isAutoScrollEnabled}
                isConversation={isConversation}
              />
            </div>
          )
        })}

        {layout.length > 0 &&
          layout.map((item, index) => {
            if (["exhibitions_banner"].includes(item.type) || ["exhibition_banner"].includes(item.type)) return;

            return (
              <React.Fragment key={index}>
              {/* @ts-ignore */}
              <GetExhibitions
                // totalLayouts={layout.length}
                type={item.type}
                id={item.id}
                slug={item.slug}
                y={item.y}
                isHorizontal={item.isHorizontal}
                title={item.title || ""}
                image={item?.image}
                link={item?.link || ""}
                description={item.description || ""}
                galleryName={item.galleryName || ""}
                isAutoScrollEnabled={item.isAutoScrollEnabled}
                isLoggedIn={isLoggedIn}
                updateFavourite={updateFavourite}
                toggleFavouriteExhibition={toggleFavouriteExhibition}
                isConversation={isConversation}
                isImageOnLeft={item?.isImageOnLeft}
              />
              </React.Fragment>
            );
          })}
      </div>
    );
  }
);
export default Discover;

const getArray = async (id) => {
  const resp = await getDiscoverExhibitions(id);
  if (resp) {
    return resp;
  }
};

export const GetExhibitions = ({
  id,
  isHorizontal,
  title,
  description,
  galleryName,
  type,
  image,
  link,
  isImageOnLeft,
  slug,
  // totalLayouts,
  // totalItems,
  // setTotalItems,
  // setLoader,
  isLoggedIn,
  // isLast,
  isAutoScrollEnabled,
  toggleFavouriteExhibition,
  updateFavourite,
  y,
  organisations,
  isConversation
}) => {
  const [e, setE] = useState([]);
  const [eLength, setELength] = useState(0);
  const [totalEvents, setEvents] = useState(null);
  const FeaturedExhibition = useRef(null);
  const [currentItem, setCurrentItem] = useState(1);
  const [textDetails, setTextDetails] = useState(null);
  const [isHover, setIsHover] = useState(!isAutoScrollEnabled);
  const [isLeftDisabled, setLeftDisabled] = useState(false);
  const [isRightDisabled, setRightDisabled] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const [totalOrganizations, setOrganizations] = useState(null);
  const sliderRef = useRef(null);
  const [positionX, setPositionX] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [maxSlide, setMaxSlide] = useState(1);
  const [count, setCount] = useState(0);
  const history = useHistory();

  const exhibitionCarouselLeftClick = () => {
    const x = 1 + (positionX !== 0 ? (-1 * positionX) / 100 : 0);
    if (positionX !== 0) {
      setCurrentSlide(x - 1);
      setPositionX(positionX + 100);
    } else if (positionX === 0) {
      setCurrentSlide(maxSlide);
      setPositionX(-(maxSlide - 1) * 100);
    }
    setCount(0);
  };
  const exhibitionCarouselRightClick = () => {
    const x = 1 + (positionX !== 0 ? (-1 * positionX) / 100 : 0);
    if (x < maxSlide) {
      setCurrentSlide(x + 1);
      setPositionX(positionX - 100);
    } else if (x === maxSlide) {
      setPositionX(0);
      setCurrentSlide(1);
    }
    setCount(0);
  };
  useEffect(() => {
    if (isAutoScrollEnabled) {
      const timer = setTimeout(() => setCount(count + 1), 1e3);
      return () => clearTimeout(timer);
    }
  });
  useEffect(() => {
    if (isAutoScrollEnabled && count === 3) {
      exhibitionCarouselRightClick();
    }
  }, [count]);

  const handleResize = () => {};

  useEffect(() => {
    if (window.innerWidth >= 1200 && totalEvents > 0) {
      setCurrentItem(2);
    } else if (window.innerWidth >= 1200 && totalEvents === 0) {
      setCurrentItem(1);
    }
  }, [totalEvents]);

  useEffect(() => {
    smoothscroll.polyfill();
    handleResize();
    window.addEventListener("resize", () => handleResize());
    return () => window.removeEventListener("resize", () => handleResize());
  }, []);

  useEffect(() => {
    if (e.length > 0) {
      setELength(e.length);
    }
  }, [e]);
  useEffect(() => {
    if (e.length > 4) {
      let number = e.length;
      number = number / 4;
      if (Number.isInteger(number)) {
        setMaxSlide(number);
      } else {
        setMaxSlide(Math.floor(number) + 1);
      }
    }
  }, [e]);

  useEffect(() => {
    getArray(id).then((_e) => {
        setFetchedData(_e.data);
        const exhibitions = _e.data.exhibitions;
        const events = _e.data.events;
        const organisations = _e.data.organisations;
        setEvents(events.length);
        const concated = exhibitions.concat(events).concat(organisations);
        setE(concated);
        setOrganizations(_e.data.organisations);
        setTextDetails(_e.data.textComponent);
    });
  }, [id]);

  // useEffect(() => {
  //     const data = [...totalItems];
  //     data.push(e);
  //     setTotalItems(data);
  // }, [e])

  // useEffect(() => {
  //     if (totalItems.length > totalLayouts) {
  //         setLoader(false)
  //     } else {
  //         setLoader(true)
  //     }
  // }, [totalItems, totalLayouts])

  const scrollFeaturedLeft = () => {
    if (FeaturedExhibition && FeaturedExhibition.current) {
      setLeftDisabled(true);
      FeaturedExhibition.current.scroll({
        left:
          FeaturedExhibition.current.scrollLeft -
          (FeaturedExhibition.current.clientWidth + 50),
        behavior: "smooth",
      });
      setTimeout(() => {
        setLeftDisabled(false);
      }, 500);
    }
  };

  const scrollFeaturedRight = () => {
    if (FeaturedExhibition && FeaturedExhibition.current) {
      setRightDisabled(true);
      FeaturedExhibition.current.scroll({
        left:
          FeaturedExhibition.current.scrollLeft +
          (FeaturedExhibition.current.clientWidth + 50),
        behavior: "smooth",
      });
      setTimeout(() => {
        setRightDisabled(false);
      }, 500);
    }
  };

  const handleLeftClick = (title) => {
    scrollFeaturedLeft();
    if (currentItem > 1) {
      setTimeout(() => {
        setCurrentItem(currentItem - 1);
      }, 400);
    }
  };

  const handleRightClick = (title) => {
    scrollFeaturedRight();
    if (currentItem < eLength) {
      setTimeout(() => {
        setCurrentItem(currentItem + 1);
      }, 400);
    }
  };

  const getUnstyled = (inlineStyles) => {
    let classes = "";
    inlineStyles.length > 0 &&
      inlineStyles.map((item) => {
        const className = item.style && item.style.toLowerCase();
        classes = classes + " " + className;
      });
    return classes;
  };


  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    }
  }, []);

  const getClassName = (className) => {
    switch (className) {
      case "header-onex2":
        return "h1x2";
      case "header-one":
        return "h1";
      case "header-two":
        return "h2";
      case "header-three":
        return "h3";
      case "unstyled":
        return "p1";
      default:
        return className;
    }
  };

  const toggleRegister = (id, val) => {
    let newEvents = [...e];
    newEvents.find((event) => event.data.id === id).data.isRegistered = val;
    setEvents(newEvents);
  };

  const handleRegister = async (eventId, isLoggedIn, exhibitionId) => {
    if (!isLoggedIn) {
      history.push(`/login?/exhibitions/${exhibitionId}`);
    } else if (eventId) {
      const eventRegisterResp = await RegisterEvent(eventId);
      if (eventRegisterResp && eventRegisterResp.status === 200) {
        toggleRegister(eventId, true);
      }
    }
  };

  const handleUnRegister = async (eventId, isLoggedIn, exhibitionId) => {
    if (!isLoggedIn) {
      history.push(`/login?/exhibitions/${exhibitionId}`);
    } else if (eventId) {
      const eventRegisterResp = await UnRegisterEvent(eventId);
      if (eventRegisterResp && eventRegisterResp.status === 200) {
        toggleRegister(eventId, false);
      }
    }
  };

  const handleExhibitionClick = async (val) => {
    history.push(`/exhibitions/${val}`);
  };

  const resetAll = () => {
    setCurrentItem(1);
    FeaturedExhibition.current &&
      FeaturedExhibition.current.scroll({ left: 0, behavior: "smooth" });
  };

  const handleManualScroll = (e) => {
    const scrolled = e.target.scrollLeft;
    const itemWidth = e.target.clientWidth + 50;
    const currentState = scrolled / itemWidth;
    setCurrentItem(Math.round(currentState) + 1);
  };

  const toggleFavourite = async (id) => {
    const tempE = [...e];
    const exhib = tempE.filter((e) => e.data.id == id)[0];
    const index = tempE.indexOf(exhib);
    exhib.data.isFavourite = !exhib.data.isFavourite;
    tempE[index] = exhib;
    if (isLoggedIn) {
      const resp = await favouriteExhibition(id);
      if (resp.data) {
        updateFavourite();
        toggleFavouriteExhibition(id, resp.data.isFavourite);
        setE(tempE);
      }
    } else {
      history.push(`/login?/discover`);
    }
  };
  const handleGalleryClick = (galleryId) => {
    history.push(`/galleries/${galleryId}`);
  };

  const handleCardClick = (slug, type, uuid = null) => {
    if(type !== "featured_exhibitions"){
      handleGalleryClick(slug);
    } else {
      handleExhibitionClick(slug || uuid);
    }
  }

  return (
    <ScrollAnimation
      className={(y + 1) + "-classes"}
      style={{ gridRowStart: y + 1, gridRowEnd: "auto", maxWidth: 'inherit' }}
    >
      <div>
        {type !== "explore_vortic" ? (
          (type === "announcement") ? (
            <DiscoverCard
              title={title}
              description={description}
              galleryName={galleryName}
              imgUrl={constructImageUrl(image, "1024")}
              handleBtnClick={() => {
                window.open(
                  link.includes("https://") ? link : `https://${link}`,
                  "_blank"
                );
              }}
              btnLabel={"Visit"}
              variant={!isImageOnLeft ? "primary" : null}
            />
          ) : 
          (type === "exhibitions_banner" || type === "exhibition_banner") ? (
            <div style={{ gridRowStart: y + 1, gridRowEnd: "auto" }}>
              <DiscoverBanner
                id={id}
                isAutoScrollEnabled={isAutoScrollEnabled}
                // isConversation={isConversation}
              />
            </div>
          ) :
          (type === "text_component") ? (
            textDetails?.data?.json && <div className="draft-text-v2 discovers-draft-text-v2" dangerouslySetInnerHTML={{__html: textDetails?.data?.json}}></div>
          ) : 
          (type === "featured_partner" || type === "featured_partners" || type === "featured_exhibitions" || type === "featured_sessions") ? (
            e?.length > 0 && (
              !isHorizontal ?
              (
                <div className="horizontal-carousel discover-collectives">
                  <DiscoverHeader title={title} description={description} />
                  <div className="discover-collectives__content">
                    {e?.map((organisation, index) => {
                      return (
                        <Card
                          key={index}
                          imgSrc={`${constructImageUrl(organisation.data.image,"720")}`}
                          title={organisation?.data?.name}
                          description={organisation?.data?.overview}
                          location={
                            organisation?.data?.locations &&
                            organisation?.data?.locations
                          }
                          onClick={() => 
                            handleCardClick(
                              organisation?.data?.slug || organisation?.data?.uuid || organisation?.data?.id,
                              type,
                              organisation?.data?.slug
                            )
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              )
              : 
              (
                <>
                  {
                    e?.length > 0 && (
                      <div className="horizontal-carousel">
                      <DiscoverHeader
                        title={title}
                        description={description}
                        arrowsCount={`${currentSlide} \xa0\xa0\xa0\xa0\xa0\ / \xa0\xa0\xa0\xa0\xa0\ ${maxSlide}`}
                        handleLeftArrowClick={() => exhibitionCarouselLeftClick()}
                        handleRightArrowClick={() => exhibitionCarouselRightClick()}
                      />
                      <AdvanceCarousel
                        groupCells={4}
                        responsiveToVertical={true}
                        exhibitionPositionX={positionX}
                        exhibitionSliderRef={sliderRef}
                      >
                        <>
                          {e?.map((_e, index) => {
                            return (
                              <div key={index}>
                                <Exhibition
                                  id={id}
                                  uuid={_e?.data?.uuid}
                                  slug={_e?.data?.slug}
                                  onClick={() => {
                                    handleExhibitionClick(_e?.data?.slug || _e?.data?.uuid);
                                  }}
                                  exhibitionImg={
                                    _e?.data &&
                                    _e?.data?.image &&
                                    _e?.data?.image?.data &&
                                  constructImageUrl( _e.data.image ,"820")
                                  }
                                  title={_e?.data?.name}
                                  subTitle={_e?.data?.description || ""}
                                  galleryName={
                                    ( _e?.data?.organisation?.data?.name) ||
                                    ""
                                  }
                                  startedAt={_e?.data?.startedAt}
                                  endedAt={_e?.data?.endedAt}
                                  isDateVisible={_e?.data?.isDateVisible}
                                  discover
                                />
                              </div>
                            );
                          })}
                        </>
                      </AdvanceCarousel>
                </div>
                    )
                  }
                </>
              )
            )
          ) : (
            <>
              {
                e?.length > 0 && (
                  <div className="horizontal-carousel">
                  <DiscoverHeader
                    title={title}
                    description={description}
                    arrowsCount={`${currentSlide} \xa0\xa0\xa0\xa0\xa0\ / \xa0\xa0\xa0\xa0\xa0\ ${maxSlide}`}
                    handleLeftArrowClick={() => exhibitionCarouselLeftClick()}
                    handleRightArrowClick={() => exhibitionCarouselRightClick()}
                  />
                  <AdvanceCarousel
                    groupCells={4}
                    responsiveToVertical={true}
                    exhibitionPositionX={positionX}
                    exhibitionSliderRef={sliderRef}
                  >
                    <>
                      {e?.map((_e, index) => {
                        return (
                          <div key={index}>
                            <Exhibition
                              id={id}
                              uuid={_e?.data?.uuid}
                              slug={_e?.data?.slug}
                              onClick={() => {
                                handleExhibitionClick(_e?.data?.slug || _e?.data?.uuid);
                              }}
                              exhibitionImg={
                                _e?.data &&
                                _e?.data?.image &&
                                _e?.data?.image?.data &&
                              constructImageUrl( _e.data.image ,"820")
                              }
                              title={_e?.data?.name}
                              subTitle={_e?.data?.description || ""}
                              galleryName={
                                ( _e?.data?.organisation?.data?.name) ||
                                ""
                              }
                              startedAt={_e?.data?.startedAt}
                              endedAt={_e?.data?.endedAt}
                              isDateVisible={_e?.data?.isDateVisible}
                              discover
                            />
                          </div>
                        );
                      })}
                    </>
                  </AdvanceCarousel>
            </div>
                )
              }
            </>
          )
        ) : (
          <div className="horizontal-carousel">
            <DiscoverHeader title={title} />
            <ExploreVortic exploreData={fetchedData} />
          </div>
        )}
      </div>
    </ScrollAnimation>
  );
};
const DiscoverBanner = ({ id, isAutoScrollEnabled, isConversation = false }) => {
  const [exhibition, setExhibition] = useState([]);
  const [isHover, setIsHover] = useState(!isAutoScrollEnabled);
  const bannerSliderRef = useRef(null);
  const history = useHistory();
  useEffect(() => {
    getArray(id).then((_e) => {
      const exhibitions = _e.data.exhibitions;
      const events = _e.data.events;
      const concated = exhibitions.concat(events);
      setExhibition(concated);
    });
  }, [id]);
  useEffect(() => {
    let timer1;
    let timer2;
    let timer3;
    if (bannerSliderRef.current) {
      timer1 = setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 250);
      timer2 = setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 250);
      timer3 = setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 500);
    }
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, [bannerSliderRef.current, isConversation]);

  const handleBannerLeftArrowClick = () => {
    // @ts-ignore
    document.querySelector(".previous").click();
  };

  const handleBannerRightArrowClick = () => {
    // @ts-ignore
    document.querySelector(".next").click();
  };

  return (
    <>
      <div className="discover-banner-wrapper">
        {exhibition?.length > 0 && <Flickity
          className={"carousel"}
          elementType={"section"}
          flickityRef={(c) => (bannerSliderRef.current = c)}
          options={{
            pauseAutoPlayOnHover: false,
            wrapAround: true,
            adaptiveHeight: true,
            prevNextButtons: true,
            draggable: false,
            pageDots: false,
            contain: true,
            autoPlay: 4000,
          }}
        >
          {
            exhibition.map((item, index) => {
              return (
                <BannerItem
                  key={index}
                  exhibitionId={item?.data?.id}
                  exhibitionSlug={item?.data?.slug}
                  uuid={item?.data?.uuid}
                  title={item.data.name}
                  description={item.data?.description}
                  isDateVisible={item.data?.isDateVisible}
                  // descriptionHtml={item.data?.descriptionHtml}
                  galleryName={item?.data?.organisation?.data?.name}
                  timeline={`${formatDate(
                    item?.data?.startedAt
                  )} - ${formatDate(item?.data?.endedAt, true)}`}
                  image={`${constructImageUrl(item?.data?.image,"1920")}`}
                />
              );
            })}
        </Flickity>}
        <>
          <div
            className="arrow arrow-left"
            onClick={() => {
              handleBannerLeftArrowClick();
            }}
          >
            <svg
              width="21"
              height="38"
              viewBox="0 0 21 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.4854 1.35522L1.80768 19.0329L19.4854 36.7106"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <div
            className="arrow arrow-right"
            onClick={() => {
              handleBannerRightArrowClick();
            }}
          >
            <svg
              width="21"
              height="38"
              viewBox="0 0 21 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.48535 36.3552L19.163 18.6776L1.48535 0.999885"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </>
      </div>
    </>
  );
};
