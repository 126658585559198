import React, {useEffect, useRef, useState} from 'react';
import InboxArtworkCard from "../InboxArtworkCard";
import Message from "../ReceivedMessage";
import Emoji from '../../assets/icons/emoji.svg';
import Attach from '../../assets/icons/attach.svg';
import Send from '../../assets/icons/send.svg';
import {IInboxProps} from './types';
import Spinner from '../../components/Spinner';
import ListHeader from "../ListHeader";
import EmojiPicker from "../EmojiPicker";
import logger from "../../utils/logger";
import { formatAMPM } from "../../utils";

const Inbox: React.FC<IInboxProps> = ({isLoading, toggleScroll, shouldScroll, isCurateChat, isSendDisabled, title, handleEnterPress, handleOnClickArtworkCard, onChangeAttachment, onScrollToTop, handleInputClick, smsTextValue, onChangeInputValue, messages, galleryName, onBackClick, onClose, eventsChat, sendMsg}) => {

    const [loading, setLoading] = useState(true);
    const [isEmojisBox, setIsEmojisBox] = useState(false);
    const [inputDisabled, setInputDisabled] = useState(false);
    const handleMessageScroll = (e) => {
        if(e.target.scrollTop === 0) {
            onScrollToTop()
        }
    };

    useEffect(() => {
        if(isLoading === false){
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        }
    }, [isLoading]);

    useEffect(() => {
        return () => {
            resetBodyScroll();
        }
    }, [])

    const removeBodyScroll = () => {
        document.querySelector('body') && document.querySelector('body').classList.add('overflow-hidden');
    };

    const resetBodyScroll = () => {
        document.querySelector('body') && document.querySelector('body').classList.remove('overflow-hidden');
    };

    return (
        <aside className='inbox-wrapper' onMouseEnter={removeBodyScroll} onMouseUp={removeBodyScroll} onMouseLeave={resetBodyScroll}>
            {
                !eventsChat &&
                <ListHeader title={title} onBackClick={!eventsChat && onBackClick} subTitle={galleryName} onCrossClick={(!eventsChat || isCurateChat) && onClose} />
            }
            {
                <>
                    {loading && <Spinner />}
                    <div className='inbox__scrollbox' onScroll={handleMessageScroll} onClick={() => (setIsEmojisBox(false))}>
                        <MessagesList
                            messages={messages}
                            eventsChat={eventsChat}
                            toggleScroll={toggleScroll}
                            shouldScroll={shouldScroll}
                            handleOnClickArtworkCard={handleOnClickArtworkCard}
                        />
                    </div>

                    <div className='inbox__input-box' onClick={() => (inputDisabled === false && setIsEmojisBox(false))}>
                        <div className={"emoji-wrapper" + (isEmojisBox ? ' active' : '')}
                             onMouseEnter={() => setInputDisabled(true)}
                             onMouseLeave={() => setInputDisabled(false)}
                        >
                          <img alt="emoji" className='emoji' src={Emoji} onClick={() => {setIsEmojisBox(!isEmojisBox)}} />
                          <EmojiPicker setChosenEmoji={(data) => {
                              onChangeInputValue(smsTextValue.concat(data.emoji));
                              setIsEmojisBox(false)
                              document.getElementById('message-textarea').focus();
                          }}
                          />
                        </div>
                        <textarea
                            id={'message-textarea'}
                            placeholder='Write a message...'
                            value={smsTextValue}
                            onChange={(e) => onChangeInputValue(e.target.value)}
                            onClick={handleInputClick}
                            onKeyDown={handleEnterPress}
                        />
                        <img className='emoji send-btn' src={Send} onClick={() => !isSendDisabled && sendMsg('text')} />
                        {!eventsChat && <label className='attach-wrapper'>
                          <img className='attach' src={Attach}/>
                          <input
                            onChange={onChangeAttachment}
                            type={'file'}
                            accept="image/x-png,video/mp4,image/gif,image/jpeg"
                          />
                        </label>}
                    </div>
                </>
            }
        </aside>
    )
}

const MessagesList = ({messages, toggleScroll, shouldScroll, handleOnClickArtworkCard, eventsChat}) => {
    useEffect(() => {
        if(messages && messages.length > 0 && shouldScroll) {
            logger.debug('ToggleScroll::scrollToBottom = ')
            scrollToBottom();
            toggleScroll(false)
        }
    }, [messages && messages.length]);

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        setTimeout(() => {
            logger.debug('scrollToBottom = ', messagesEndRef.current.scrollTop, messagesEndRef.current.scrollHeight, messagesEndRef.current.scrollHeight - messagesEndRef.current.scrollTop)
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }, 100);
    };

    return (
        <>
        <ul ref={messagesEndRef}>
            {
                messages && messages.map((message, index) => {
                    if (message.isSystemMessage) {
                        return <RenderSystemMessage onClick={handleOnClickArtworkCard} message={message.message}/>
                    }
                    return (
                        <Message
                            key={index}
                            isFile={message.messageType === "file"}
                            type={message.type}
                            imgUrl={message.plainUrl}
                            isLoading={message.isLoading}
                            isFirst={true} // this one TODO: This one will take first message as first or not
                            isMyMsg = {message.isMyMessage}
                            hideStatus={eventsChat || false}
                            msg={message.message} senderName={message.senderName}
                            profileUrl={message.profileUrl}
                            time={formatAMPM(new Date(message.createdAt), false)}
                        />
                    )
                })
            }
        </ul>
        </>
    )
}

const RenderSystemMessage = ({message, onClick}) => {
    const splittedData = message.split('!,!');
    return <InboxArtworkCard
        onClick={() => onClick(splittedData[5])}
        imageUrl={splittedData[4]}
        title={`${splittedData[0] || ''}, ${splittedData[1] || ''}`}
        timeline={splittedData[2]}
        description={splittedData[3]}
    />
}

export default Inbox;
