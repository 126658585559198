import React from 'react';
import {Paragraph} from '../Typography2';
import {Link} from 'react-router-dom';
import './style.scss';
import {ISearchResult} from './types';
import ImageLoader from "../ImageLoader";

const SearchResult: React.FC<ISearchResult> = ({imgSrc, isDisabled, title, subtitle, description, id, type, uuid, slug}) => {
    const link = () => {
        const linkId = (type === "curators") ? uuid : (slug || uuid || id);
        if(!isDisabled) {
            return `${type}/${linkId}`;
        }
        else{
            return "";
        }
    }
    return (
            <div className="borderBottom ">
                <Link to={link()} className="search-result container">
                    <div className="img">
                        <ImageLoader imgUrls={imgSrc && Array.isArray(imgSrc) ? [...imgSrc] : [imgSrc]}/>
                    </div>
                    <div className="searchDetails">
                        <Paragraph className="searchTitle" value={title} />
                        <Paragraph className="gray searchSubtiltle" value={subtitle} />
                        <Paragraph className="searchDescription" value={description} />
                    </div>
                </Link>
            </div>
    )
};

export default SearchResult;
