import { getIdFromSlug } from './../utils/index';
import axios from "./axios-foursquare";
import axiosMain from "axios";
import {BACKEND_URL} from "../configs"
import logger from '../utils/logger';
import {isMobileDevice} from '../utils';
import {LOCAL_STORAGE_KEYS, RECORDS_LIMIT} from "../components/Constants"
import {initSync} from './sendbird/main'
// import * as config from "../config";

const getWebMobileParam = () => {
    let params = '';
    // Only pass params is BETA API is being used.
    // if(!BACKEND_URL.includes('api')) {
    //     return params;
    // }
    // if(isMobileDevice()){
    //     params = "&mobile=true"
    // }else{
    //     params = "&web=true"
    // }
    return params;
};

export const login = async (
    email: string,
    password: string
) => {
    const result = await axiosMain
        .post(`${BACKEND_URL}login`, {
            email, password, device: 'WEBAPP'
        })
        .catch((err: any) => {
            return Promise.reject(err.response);
        });
    if (result && result.data && result.data.token) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, JSON.stringify(result.data.token))
        localStorage.setItem(LOCAL_STORAGE_KEYS.AWS, JSON.stringify(result.data.aws))
        return result && result.data
    }
};

export const forgot = async (data) => {
    try {
        const result = await axios
            .post("/resetPassword", data)
            .catch((err: any) => {
                logger.error('/resetPassword => ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err)));
            });
        return result && result.data;
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const reset = async (data) => {
    try {
        const result = await axios
            .post("/validateResetCode", data)
            .catch((err: any) => {
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err?.response?.data)));
            });
        return result && result.data;
    } catch (e) {
        return Promise.reject(new Error(e.message));
    }
}

export const updateProfile = async (data) => {
    logger.debug('updateProfile data = ', data);
    try {
        const result = await axios
            .put(`/user`, data)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err?.response?.data)));
            });
        if (result) {
            delete result.data['image']
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};
export const updateNotification = async (data) => {
    logger.debug('updateNotification data = ', data);
    try {
        const result = await axios
            .put(`/user/notifications`, data)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err?.response?.data)));
            });
        if (result) {
            delete result.data['image']
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const updateProfileImage = async (data) => {
    logger.debug('updateProfileImage data = ', data);
    try {
        const result = await axios
            .post(`/user/media`, data)
            .catch((err: any) => {
                logger.error('err in updateProfileImage = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e.message);
        return Promise.reject(new Error(e.message));
    }
};

export const getUpdateProfile = async () => {
    try {
        const result = await axios
            .get(`/user`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        return Promise.reject(new Error(e.message));
    }
};
export const getProfileNotification = async () => {
    try {
        const result = await axios
            .get(`/user/notifications`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        return Promise.reject(new Error(e.message));
    }
};

export const fetchExhibitions = async (payload) => {
    const {page} = payload;
    logger.debug('fetchExhibitions api payload = ', payload)
    try {
        const result = await axios
            .get(`/user/discover/exhibitions?cardsView=true&page=${page}&limit=${RECORDS_LIMIT}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        logger.debug('fetchExhibitions = ', result)
        if (result) {
            return {...result.data, currentPage: page};
        }
    } catch (e) {
        logger.error('fetchExhibitions Error = ', e.message)
        return Promise.reject(new Error(e.message));
    }
};

export const fetchPrivateExhibitions = async (payload) => {
    const {page} = payload;
    logger.debug('API = ', payload)
    try {
        const result = await axios
            .get(`/user/discover/private-exhibitions?cardsView=true&page=${page}&limit=${RECORDS_LIMIT}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error('fetchExhibitions Error = ', e.message)
        return Promise.reject(new Error(e.message));
    }
};

export const fetchPrivateAndPublicExhibitions = async ({page, filterType = 'all'}) => {
    logger.debug('API = ', page, filterType)
    try {
        const result = await axios
            .get(`/user/web/discover/exhibitions?filter=${filterType}&cardsView=true&page=${page}&limit=${RECORDS_LIMIT}${getWebMobileParam()}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return {...result.data, currentPage: page};
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const fetchExhibitionsDetails = async (id, previewOnly=false) => {
    try {
        const result = await axios
            .get(`/user/exhibitions/${encodeURIComponent(id)}?eliminateSignedUrl=true${previewOnly ? '&previewOnly=true' : ''}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e)
        return Promise.reject(new Error(e.message));
    }
};

export const fetchExhibitionCurator = async (id, isPreview = false) => {
    try {
        const result = await axios
            .get(`/user/exhibitions/${id}/curator${isPreview ? '?previewOnly=true' : ''}`)
            .catch((err: any) => {
                return Promise.reject(null);
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        return Promise.resolve(null);
    }
};


export const fetchExhibitionArtworks = async (id) => {
    logger.debug('fetchExhibitionArtworks = ', id);
    try {
        const result = await axios
            .get(`/user/exhibitions/${id}/artworks?cardsView=true`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const fetchExhibitionArtists = async (id, isPreview = false) => {
    logger.debug('fetchExhibitionArtists = ', id);
    try {
        const result = await axios
            .get(`/user/exhibitions/${id}/artists?cardsView=true${isPreview ? '&previewOnly=true' : ''}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const fetchGalleries = async ({page, filter = "alphabetical", type}) => {
    logger.debug("type in = ", type);
    try {
        const result = await axios
            .get(`user/discover/galleries?featured=true&cardsView=true&filter=${filter}&limit=${RECORDS_LIMIT}&page=${page}${getWebMobileParam()}&type=${type}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return {...result.data, currentPage: page};
        }
    } catch (e) {
        return Promise.reject(new Error(e.message));
    }
};

export const fetchGalleryDetails = async (id, isPreview = false) => {
    try {
        const result = await axios
            .get(`user/galleries/${encodeURIComponent(id)}${isPreview ? '?previewOnly=true' : ''}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        return Promise.reject(new Error(e.message));
    }
};


export const fetchCuratorDetails = async (id, isPreview = false) => {
    try {
        const result = await axios
            .get(`user/curators/${id}${isPreview ? '?previewOnly=true' : ''}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        return Promise.reject(new Error(e.message));
    }
};


export const fetchOrganisationsCurators = async (organisationId) => {
    try {
        const result = await axios
            .get(`user/galleries/${getIdFromSlug(organisationId)}/curators`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        return Promise.reject(new Error(e.message));
    }
};

export const fetchGalleryArtists = async (id, isPreview = false) => {
    logger.debug('fetchGalleryArtists id = ', id);
    try {
        const result = await axios
            .get(`user/galleries/${id}/artists?cardsView=true${isPreview ? '&previewOnly=true' : ''}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e)
        return Promise.reject(new Error(e.message));
    }
};
export const fetchGalleryExhibitions = async (id, isPreview = false) => {
    logger.debug('fetchGalleryExhibitions id => ', id);
    try {
        const result = await axios
            .get(`user/galleries/${id}/exhibitions?cardsView=true${isPreview ? '&previewOnly=true' : ''}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const fetchCuratorExhibitions = async (id, isPreview = false) => {
    logger.debug('fetchCuratorExhibitions id => ', id);
    try {
        const result = await axios
            .get(`user/curators/${id}/exhibitions?cardsView=true${isPreview ? '&previewOnly=true' : ''}`)
            // .get(`user/galleries/${id}/exhibitions?cardsView=true${isPreview ? '&previewOnly=true' : ''}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const fetchArtistDetails = async (id, isPreview = false) => {
    logger.debug('fetchArtistDetails id => ', id);
    try {
        const result = await axios
            .get(`user/artists/${encodeURIComponent(id)}${isPreview ? '?previewOnly=true' : ''}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};
export const fetchArtistExhibitions = async (id, isPreview = false) => {
    logger.debug('fetchArtistExhibitions id => ', id);
    try {
        const result = await axios
            .get(`user/artists/${id}/exhibitions?cardsView=true${isPreview ? '&previewOnly=true' : ''}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};
export const fetchArtistArtworks = async (id, isPreview = false) => {
    logger.debug(id);
    try {
        const result = await axios
            .get(`user/artists/${id}/artworks${isPreview ? '?previewOnly=true' : ''}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const fetchCollectives = async (page) => {
    logger.debug("fetchCollectives page => ", page);
    try {
        const result = await axios
            .get(`user/discover/groups?page=${page}&limit=${RECORDS_LIMIT}${getWebMobileParam()}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return {...result.data, currentPage: page};
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const fetchCollectivesMeta = async () => {
    try {
        const result = await axios
            .get('/user/discover/groups/meta')
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e.message);
        return Promise.reject(new Error(e.message));
    }
};

export const fetchFavourites = async () => {
    try {
        const result = await axios
            .get(`user/favourites`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return {...result.data};
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const favouriteExhibition = async (exhibitionId) => {
    try {
        const result = await axios
            .put(`exhibitions/${exhibitionId}/favourite`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const favouriteGalleries = async (galleryId) => {
    try {
        const result = await axios
            .put(`galleries/${galleryId}/favourite`)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err?.response?.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};



export const favouriteCollectives = async (collectiveId) => {
    try {
        const result = await axios
            .put(`groups/${collectiveId}/favourite`)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err?.response?.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const favouriteArtists = async (artistID) => {
    try {
        const result = await axios
            .put(`/artists/${artistID}/favourite`)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const favouriteArtworks = async (artistID) => {
    logger.debug('favouriteArtworks artistID => ', artistID);
    try {
        const result = await axios
            .put(`/artworks/${artistID}/favourite`)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const fetchCollectiveDetails = async (id) => {
    try {
        const result = await axios
            .get(`user/groups/${encodeURIComponent(id)}?cardsView=true`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        return Promise.reject(new Error(e.message));
    }
};
export const fetchCollectiveExhibitions = async (id) => {
    try {
        const result = await axios
            .get(`user/groups/${id}/exhibitions?cardsView=true&ordered=true`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};
export const fetchCollectiveGalleries = async (id) => {
    try {
        const result = await axios
            .get(`user/groups/${id}/organisations?cardsView=true`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const fetchArtworkDetails = async (id) => {
    try {
        const result = await axios
            .get(`user/artworks/${encodeURIComponent(id)}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

let cancel;
var CancelToken = axiosMain.CancelToken;

export const fetchSearch = async (searchQuery, page, loadingMore) => {
    try {
        // console.log('cancel = ', cancel)
        // if (cancel != undefined) {
        //     cancel();
        //     console.log("cancelled");
        // }

        // &page=${page}&limit=${RECORDS_LIMIT}
        const result = await axios
            .get(`user/web/discover?search=${searchQuery}&page=${page}&limit=${5}`, {
                // cancelToken: new CancelToken(function executor(c) {
                //     cancel = c;
                // }),
            })
            .catch((err: any) => {
                logger.error('fetchSearch err = ', err, err.response);
                if (err && !!err.response) {
                    return Promise.reject(
                        new Error(err)
                    );
                }
                if (err.response) {
                    return Promise.reject(new Error(JSON.stringify(err.response.data)));
                }
                return Promise.reject(new Error(JSON.stringify(err)));
            });
        if (result) {
            return {...result.data, currentPage: page, loadingMore: loadingMore};
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};


export const fetchCurateDetails = async (exhibitionId, sceneId = undefined, allArtworks=false, isPreview = false, eliminateDimensions = true, eliminateSignedUrl = false) => {
    logger.debug('fetchCurateDetails = ', exhibitionId, sceneId)
    try {
        const result = await axios
            .get(`/user/exhibitions/${exhibitionId}/curate?eliminateDimensions=${eliminateDimensions ? 'true' : 'false'}${allArtworks ? '&allArtworks=true' : ''}${sceneId ? '&exhibitionSceneId=' + sceneId+'' : ''}${isPreview ? '&previewOnly=true' : ''}${eliminateSignedUrl ? '&eliminateSignedUrl=true' : ''}`)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}


export const fetchCurateLinkedArtworks = async (exhibitionId, sceneId = undefined, allArtworks=false, isPreview = false, eliminateSignedUrl=false) => {
    try {
        console.log('loading artworks')
        const result = await axios
            .get(`/user/exhibitions/${exhibitionId}/linkedArtworks?${allArtworks ? '&allArtworks=true' : ''}${sceneId ? '&exhibitionSceneId=' + sceneId+'' : ''}${isPreview ? '&previewOnly=true' : ''}${eliminateSignedUrl ? '&eliminateSignedUrl=true' : ''}`)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}

export const fetchV2Scenes = async (exhibitionId, isPreview = false) => {
    logger.debug('fetchV2Scenes exhibitionId => ', exhibitionId)
    try {
        const result = await axios
            .get(`user/exhibitions/${exhibitionId}/scenes?cardsView=true${isPreview ? '&previewOnly=true' : ''}`)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}
export const fetchArtworkMedia = async (exhibitionId, artworkId) => {
    try {
        const result = await axios
            .get(`user/${exhibitionId}/artworks/${artworkId}`)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}

export const submitNewsLetter = async (data) => {
    logger.debug("newsLetter data => ", data);
    try {
        const result = await axios
            .post(`/user/joinMailingList`, data)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}

export const getProfileImageUploadURL = async (data) => {
    try {
        const result = await axios
            .post(`/user/getProfileImageUploadURL`, data)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}

export const generateAvatar = async (userId, assetKey, gender) => {

    var urlencoded = new URLSearchParams();
    urlencoded.append("user_id", userId);
    urlencoded.append("assetKey", assetKey);
    urlencoded.append("gender", gender);

    try {
        const result = await axios
            .post(`/user/avatar/create`, urlencoded)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return true;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}

// export const retrieveUserAvatar = async () => {
//     try {
//         const result = await axios
//             .get(`/user/avatar/retrieve`)
//             .catch((err: any) => {
//                 logger.error(err);
//                 if (err && err.response && err.response.status === 400) {
//                     return Promise.reject(
//                         new Error("Request failed with status code 400")
//                     );
//                 }
//                 return Promise.reject(new Error(JSON.stringify(err.response.data)));
//             });
//         if (result) {
//             return result.data;
//         }
//     } catch (e) {
//         logger.error(e);
//         return Promise.reject(new Error(e.message));
//     }
// }


export const retrieveUserAvatar = async () => {
    try {
        const result = await axios
            .get(`/user/avatar/retrieve`)
            .catch((err: any) => {
                return null;
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error('fetchEvents Error = ', e)
        return Promise.reject(new Error(e.message));
    }
};


export const register = async (data) => {
    logger.debug("register data => ", data);
    try {
        const result = await axios
            .post(`/register`, data)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}
export const acceptInvite = async (code) => {
    logger.debug("acceptInvite code => ", code);
    try {
        const result = await axios
            .post(`/invites/accept`, {
                code
            })
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}

export const createPassword = async (body, authToken) => {
    logger.debug("createPassword => ", body, authToken);
    try {
        const result = await axios
            .post(`/createPassword`, body, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            })
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}

export const resetPassword = async (data) => {
    logger.debug("resetPassword data =>", data);
    try {
        const result = await axios
            .put("/updateProfile", data)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result && result.data) {
            return result.data;
        } else if (result) {
            return result;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const updateSuscriptionStatus = async (eventId, data) => {
    try {
        const result = await axios
            .put(`/user/events/${eventId}`, data)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result && result.data) {
            return result.data;
        } else if (result) {
            return result;
        }
    } catch (e) {
        logger.error(e);
        return  Promise.reject(new Error(e.message));
    }
};

export const changeEventStatus = async (id, data) => {
    try {
        const result = await axios
            .post("/events/" + id, data)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err)));
            });
        return result && result.data;
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};
export const enquireArtwork = async (data) => {
    try {
        const result = await axios
            .post("/user/contact", data)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err)));
            });
        return result && result.data;
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const fetchEvents = async (exhibitionId, isPreview = false) => {
    logger.debug('API events => ', exhibitionId);
    try {
        const result = await axios
            .get(`user/exhibitions/${exhibitionId}/events${isPreview ? '?previewOnly=true' : ''}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error('fetchEvents Error = ', e)
        return Promise.reject(new Error(e.message));
    }
};

export const fetchCurrentEvent = async (exhibitionId) => {
    logger.debug('API events => ', exhibitionId);
    try {
        const result = await axios
            .get(`user/exhibitions/${exhibitionId}/currentEvent`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err?.response?.data))); //TODO: have a look here please
            });
        logger.debug('fetchCurrentEvent = ', result)
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error('fetchEvents Error = ', e)
        return Promise.reject(new Error(e.message));
    }
};

export const fetchEventDetails = async (eventId) => {
    try {
        const result = await axios
            .get(`user/events/${eventId}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        logger.debug('fetchEventDetails = ', result)
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error('fetchEvents Error = ', e)
    }
};

export const fetchEventToken = async (eventId) => {
    try {
        const result = await axios
            .get(`events/${eventId}/token?deviceId=123`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        logger.debug('fetchEventToken = ', result)
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error('fetchEvents Error = ', e.message)
        return Promise.reject(new Error(e.message));
    }
};

export const RegisterEvent = async (eventId) => {
    logger.debug('API events => ', eventId);
    try {
        const result = await axios
            .post(`events/${eventId}/register`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        logger.debug('RegisterEvent = ', result)
        if (result) {
            return result;
        }
    } catch (e) {
        logger.error('fetchEvents Error = ', e.message)
        return Promise.reject(new Error(e.message));
    }
};

export const UnRegisterEvent = async (eventId) => {
    logger.debug('API events => ', eventId);
    try {
        const result = await axios
            .post(`events/${eventId}/unregister`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        logger.debug('UnRegisterEvent = ', result)
        if (result) {
            return result;
        }
    } catch (e) {
        logger.error('fetchEvents Error = ', e.message)
        return Promise.reject(new Error(e.message));
    }
};

export const fetchArtworkAgent = async (artworkId) => {
    try {
        const result = await axios
            .get(`/user/artworks/${artworkId}/agent`)
            .catch((err: any) => {
                // logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        // logger.debug('fetchArtworkAgent = ', result)
        if (result) {
            return result.data;
        }
    } catch (e) {
        // logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const initSendbird = async (data) => {
    logger.debug("initSendbird")
    const {id, name, profileUrl, role} = data;
    try {
        const resp = initSync(id, name, profileUrl, role);
        logger.debug('resp from sendbird ===>>> ', resp);
        return {};
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const fetchTotalUnread = async (payload) => {
    return undefined;
};

export const getDiscoverDetails = async () => {
    try {
        const result = await axios
            .get(`/user/discover-page`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err?.response?.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const getDiscoverPreviewDetails = async () => {
    try {
        const result = await axios
            .get(`/user/discover-page/preview`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const getDiscoverExhibitions = async (id) => {
    try {
        const result = await axios
            .get(`/user/discover-page/${id}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};
export const reSendEmail = async (email) => {
    try {
        const result = await axios
            .post(`resendEmail`, {
                email
            })
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const fetchArticleDetails = async (articleId) => {
    logger.debug('API fetchArticleDetails articleId => ', articleId);
    try {
        const result = await axios
            .get(`user/article/${articleId}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err?.response?.data)));
            });
        logger.debug('fetchArticleDetails = ', result)
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error('fetchArticleDetails Error = ', e.message)
        return Promise.reject(new Error(e.message));
    }
};

export const fetchCollectiveArticles = async (collectiveId) => {
    logger.debug('API fetchCollectiveArticles exhibitionId => ', collectiveId);
    try {
        const result = await axios
            .get(`/user/groups/${collectiveId}/articles`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        logger.debug('fetchExhibitionArticles = ', result)
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error('fetchExhibitionArticles Error = ', e.message)
        return Promise.reject(new Error(e.message));
    }
};
export const fetchExhibitionArticles = async (exhibitionId, previewOnly = false) => {
    logger.debug('API fetchExhibitionArticles exhibitionId => ', exhibitionId);
    try {
        const result = await axios
            .get(`/user/exhibitions/${exhibitionId}/articles${previewOnly ? '?previewOnly=true' : ''}`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        logger.debug('fetchExhibitionArticles = ', result)
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error('fetchExhibitionArticles Error = ', e.message)
        return Promise.reject(new Error(e.message));
    }
};


export const finishRecording = async (id) => {
    try {
        const result = await axios
            .post(`/events/${id}/finishRecording`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err)));
            });
        return result && result.data;
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};


export const InitiateRecording = async (id) => {
    try {
        const result = await axios
            .post(`/events/${id}/record`)
            .catch((err: any) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err)));
            });
        return result && result.data;
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};


export const EnterPin = async (body) => {
    logger.debug("createPassword => ", body);
    try {
        const result = await axios
            .post(`/enterPin`, body)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}

export const fetchExhibitionMessages = async (exhibitionId, isLoggedIn) => {
    try {
        const result = await axios
            .get(`/user/comments/${exhibitionId}/list`)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}

export const addEnquireMessage = async (artworkId, body, config = {}) => {
    try {
        const result = await axios
            .post(`/user/inquire/${artworkId}`, body, config)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}
export const createExhibitionMessage = async (exhibitionId, body, config = {}) => {
    logger.debug("createExhibitionMessage => ", exhibitionId, body);
    try {
        const result = await axios
            .post(`/comments/${exhibitionId}`, body, config)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}

export const updateExhibitionMessage = async (commentId, body, config = {}) => {
    logger.debug('updateProfile data = ', body);
    try {
        const result = await axios
            .put(`/comments/${commentId}`, body)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err?.response?.data)));
            });
        if (result) {
            delete result.data['image']
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};
export const likeExhibitionComments = async (commentId) => {
    logger.debug("likeExhibitionComments => ", commentId);
    try {
        const result = await axios
            .post(`/comments/${commentId}/like`)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}

export const unLikeExhibitionComments = async (commentId) => {
    logger.debug("likeExhibitionComments => ", commentId);
    try {
        const result = await axios
            .delete(`/comments/${commentId}/like`)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}

export const deleteComment = async (commentId) => {
    logger.debug("deleteComment => ", commentId);
    try {
        const result = await axios
            .delete(`/comments/${commentId}`)
            .catch((err: any) => {
                logger.error(err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        if (result) {
            return result;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
}

export const getEventRecordings = async (eventId) => {
    try {
        const result = await axios
        .get(`/events/${eventId}/allRecordings`)
        .catch((err: any) => {
            logger.error(err);
            if (err && err.response && err.response.status === 400) {
                return Promise.reject(
                    new Error("Request failed with status code 400")
                );
            }
            return Promise.reject(new Error(JSON.stringify(err.response.data)));
        });
        if (result) {
            return result;
        }
    } catch (err)  {
        console.error('err', err);
        return Promise.reject(new Error(err))
    }
}

export const fetchCollaborations = async ()=> {
  try {
    const result = await axios
      .get('user/untitled-art/collaborations')
      .catch((err: any) => {
        logger.error(err);
        if (err && err.response && err.response.status === 400) {
          return Promise.reject(
            new Error("Request failed with status code 400")
          );
        }
        return Promise.reject(new Error(JSON.stringify(err.response.data)));
      });
    if (result) {
      return result;
    }
  } catch (err)  {
    console.error('err', err);
    return Promise.reject(new Error(err))
  }
}


