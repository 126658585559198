import React, {useEffect, useState} from 'react'
import {Heading, Paragraph, Label} from '../../components/Typography2'
import Button from '../../components/Button'
import Input from '../../components/Input'
import {acceptInvite, register} from '../../services/api'
import {useHistory, Link} from 'react-router-dom'
import Spinner from '../../components/Spinner'
import IconBtn from "../../components/IconBtn";
import logger from "../../utils/logger";
import rudderStackEvents from "../../services/rudderstack-events";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const STEPS = {
    VALIDATING_CODE: 'validatingCode',
    CODE_VALIDATED: 'codeValidated',
    NO_CODE_PRESENT: 'noCodePresent',
    INVALID_CODE: 'invalidCode',
    PASSWORD_CREATED: 'passCreated',
};

const SignUp = ({isLoggedIn, isEnquireForm,isEventsSignUp, profileData, getUpdatedProfileDataInit, onSignupComplete, loginLinkClick, closeEventSignUp, handleLoginBtnEvent, handleBackBtnEvent, title, isCommentsForm, fromExhLoading, closeIconType, modal = false}) => {
    const history = useHistory()
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [registrationErr, setRegistrationErr] = useState(false)
    const [emailErr, setEmailErr] = useState(false)
    const [inviteCode, setInviteCode] = useState('')
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [nameErr, setNameErr] = useState(false);
    const [password, setPassword] = useState('')
    const [passErr, setPassErr] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('')
    const [interest, setInterest] = useState(false)
    const [termsAgreement, setTermsAgreement] = useState(false)
    const [success, setSuccess] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [progressStatus, setProgressStatus] = useState('');
    const { setContentType } = useTrackingCode();

    const getInviteCode = (search, email) => {
        logger.debug('getInviteCode = ', search)
        if (search && search.split) {
            let splitted = search.split('=');
            if (splitted[1]) {
                setInviteCode(splitted[1])
                validateVerificationCode(splitted[1], email)
            }
        }
    }

    const validateVerificationCode = async (code, email) => {
        setProgressStatus(STEPS.VALIDATING_CODE)
        const resp = await acceptInvite(code);
        if (resp && resp.success) {
            if (resp.email === email || (!isLoggedIn && resp.email)) {
                setEmail(resp.email)
                setLoading(false)
                setProgressStatus(STEPS.CODE_VALIDATED);
            } else {
                history.push('/404', email)
            }
        }
    }

    useEffect(() => {
        if (!isLoggedIn && history.location.search) {
            const path = history.location.search.split('?')
            if (path[1].includes('inviteCode')) {
                logger.debug('path[1] = ', path[1])
                getInviteCode(path[1], '');
            }
        } else if (isLoggedIn) {
            if (history.location.search) {
                getUpdatedProfileDataInit();
                const path = history.location.search.split('?')
                if (profileData.email && path[1].includes('inviteCode')) {
                    getInviteCode(path[1], profileData.email);
                }
            } else {
                history.push('/exhibitions')
            }
        }
    }, [getUpdatedProfileDataInit, profileData?.email])

    useEffect(() => {
        if (isLoggedIn && history.location.search) {
            setLoading(true)
        }
    })

    useEffect(() => {
        if (confirmPassword.length > 0 && isPasswordValid(confirmPassword) === false) {
            setPassErr(true)
        } else {
            setPassErr(false)
        }
    }, [confirmPassword]);

    useEffect(() => {
        if (password.length > 0 && isPasswordValid(password) === false) {
            setPassErr(true);
        } else {
            setPassErr(false);
        }
    }, [password]);

    useEffect(() => {
        if (password.length > 7 && isPasswordValid(password) && confirmPassword.length > 7 && isPasswordValid(confirmPassword)) {
            if (password !== confirmPassword) {
                setPasswordMatch(true);
            } else {
                setPasswordMatch(false);
            }
        } else {
            setPasswordMatch(false);
        }
    }, [password, confirmPassword]);

    const isEmailValid = (mail) => {
        if (mail) {
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(mail) === true
        }
        return true
    };

    const submit = async () => {
        if (validateSignUp()) {
            try {
                const resp = await register(
                    {
                        email,
                        name,
                        password,
                        userType: 'collector',
                        agreedToPolicy: interest,
                        inviteCode: inviteCode,
                        origin: isCommentsForm ? "webapp_comments" : ''
                    }
                )
                if(isCommentsForm){
                    onSignupComplete && onSignupComplete(resp.token);
                }
                if (resp.success) {
                    setDisabled(false)
                    rudderStackEvents.onAccountCreate();
                    if(!isCommentsForm){
                        history.push({pathname: '/verify', state: email})
                    }
                    setSuccess(true)
                } else {
                    setDisabled(false)
                    // setRegistrationErr(true)
                }
            } catch (e) {
                setDisabled(false)
                setRegistrationErr(true)
            }
        }
    }

    const handleSignUp = (e) => {
        if(!validateSignUp()) {return;}
        e.preventDefault()
        setDisabled(true)
        submit()
    }

    const validateSignUp = () => {
        return !!name && !!email && isEmailValid(email) && isPasswordValid(password) && password === confirmPassword && termsAgreement
    };

    const isPasswordValid = (pass) => {
        return pass.length > 7 && /^(?=.*\d)(?=.*[!@$*()]).{8,}$/i.test(pass);
    };

    useEffect(() => {
        const listener = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                return validateSignUp() && submit()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    })

    useEffect(() => {
        if (email.length > 0 && isEmailValid(email) === false) {
            setEmailErr(true);
        } else {
            setEmailErr(false);
        }
    }, [email])

    useEffect(() => {
        if (name.length !== 0 && name.length < 3) {
            setNameErr(true);
        } else {
            setNameErr(false);
        }
    }, [name]);

    const handlePasswordChange = (value) => {
        setPassword(value)
    }

    const handleConfirmPasswordChange = (value) => {
        setConfirmPassword(value)
    }

    const handleEmailChange = (value) => {
        setRegistrationErr(false)
        setEmail(value)
    }
    const handleClose = () => {
        history.goBack();
    };

    useEffect(() => {
        setContentType('signup-page');
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/* <section className={`sign-up form ${isEventsSignUp ? "enquire-signup-form" : ""}`}> */}
            <section className={`sign-up form`}>
                <div className='sign-up__container create-account'>
                    {
                        success
                            ? <Heading value='Check the confirmation email'/>
                            : loading ? <Spinner bright={true}/> : <>
                                {
                                    !isEnquireForm ?
                                    <>
                                        <Heading value={title ? title : "Create Account"} style={{fontFamily: "TT-Hoves"}} />
                                        {/* <Heading value={title ? title : "Create an account"} /> */}
                                    </>
                                    :
                                    <Heading value={"Create account"} />
                                }
                                {(isEnquireForm) && <Paragraph value={title} />}
                                <div className='guides-container'>
                                    {/* {((isEmailValid(email) && isPasswordValid(password) && password === confirmPassword) === false) && (nameErr === false && emailErr === false && passwordMatch === false) && */}
                                    {modal && <Paragraph
                                      className={`mb-2 instruction-paragraph`}
                                      value='Create a Vortic account to get the latest updates on new exhibitions and artworks. '
                                    />}
                                    <Paragraph
                                      className={`mb-2 hide-element ${passErr === false ? '' : 'invalid'}`}
                                      value='Your password must be at least 8 characters long and include at least one number and one of the following symbols: !@$*()'
                                    />
                                    <Paragraph
                                       className={`mb-2 hide-element ${emailErr ? 'invalid' : 'none'}`}
                                       value='Please provide a valid email address.'
                                   />
                                   <Paragraph
                                       className={`mb-2  hide-element ${passwordMatch === true ? 'invalid' : 'none'}`}
                                       value='Password is not matching.'
                                   />
                                   <Paragraph
                                       className={`mb-2  hide-element ${registrationErr ? 'invalid' : 'none'}`}
                                       value='This email address is already registered.'
                                   />
                                   <Paragraph
                                       className={`mb-2  hide-element ${nameErr ? 'invalid' : 'none'}`}
                                       value='Name should contain at least 3 letters.'
                                   />


                                {
                                    !isEnquireForm && isCommentsForm &&
                                    <>
                                        <Label
                                            value="Please fill out the details and create an account."
                                        />
                                        <br/>
                                        <Label
                                            value={<>Already have an account? <span className='underline pointer' onClick={handleLoginBtnEvent}>Log In</span></>}
                                        />
                                    </>
                                }
                                    {
                                        !isEnquireForm && !isCommentsForm &&
                                    <p className='paragraph'>Already have an account? {loginLinkClick ?
                                        <span className={'paragraph curate-link'}
                                              onClick={loginLinkClick}>Log in here</span> :
                                        <Link to='/login'>Log in here</Link>}</p>
                                    }
                                </div>
                                <form>
                                    <Input
                                        value={email}
                                        name="email"
                                        label='Email Address'
                                        type='email'
                                        placeholder=''
                                        className={`${modal ? 'input__bright' : 'input__dark'} ${registrationErr ? 'input__error' : ''}`}
                                        onChange={(e) => handleEmailChange(e.target.value)}
                                        disabled={disabled || progressStatus === STEPS.CODE_VALIDATED}
                                        errorMsg={emailErr ? 'Please provide a valid email address.' : ''}
                                        isModalInput={modal}
                                    />
                                    <Input
                                        value={name}
                                        label='Full Name'
                                        name="name"
                                        type='text'
                                        disabled={disabled}
                                        placeholder=''
                                        className={`${modal ? 'input__bright' : 'input__dark'} ${registrationErr ? 'input__error' : ''} `}
                                        onChange={(e) => setName(e.target.value)}
                                        errorMsg={nameErr ? 'Name should contain at least 3 letters.' : ''}
                                        isModalInput={modal}
                                    />
                                    <Input
                                        value={password}
                                        label='Password'
                                        name="new-password"
                                        autoComplete="new-password"
                                        type='password'
                                        placeholder=''
                                        className={`${(isEnquireForm || modal) ? 'input__bright' : 'input__dark'}  ${registrationErr ? 'input__error' : ''}`}
                                        onChange={(e) => handlePasswordChange(e.target.value)}
                                        errorMsg={password.length > 0 && ((isPasswordValid(password)) === false) && 'Your password must be at least 8 characters long and include at least one number and one of the following symbols: !@$*()'}
                                        disabled={disabled}
                                        isModalInput={modal}
                                    />
                                    <Input
                                        value={confirmPassword}
                                        name="new-password"
                                        autoComplete="new-password"
                                        label='Confirm Password'
                                        type='password'
                                        placeholder=''
                                        className={`${(isEnquireForm || modal) ? 'input__bright' : 'input__dark'}  ${registrationErr ? 'input__error' : ''}`}
                                        onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                                        disabled={disabled}
                                        errorMsg={confirmPassword.length > 0 && ((isPasswordValid(confirmPassword)) === false ? 'Your password must be at least 8 characters long and include at least one number and one of the following symbols: !@$*()' : (confirmPassword !== password && 'Password is not matching.'))}
                                        isModalInput={modal}
                                    />
                                    <label className='termsAgreement'>
                                        <input
                                            className={`${isCommentsForm? 'gray-bg':''}`}
                                            type='checkbox' checked={interest}
                                            onChange={(e) => setInterest(e.target.checked)}
                                        />
                                        <div>
                                            <p className='paragraph curate-checkbox'>
                                                <span className='gray'>Optional: </span> I am interested in receiving
                                                updates on the latest exhibitions, events, and services. You can opt out at
                                                any time. You will still receive service updates.
                                            </p>
                                        </div>
                                    </label>
                                    <label className='termsAgreement'>
                                        <input
                                            className={`${isCommentsForm? 'gray-bg':''}`}
                                            type='checkbox' checked={termsAgreement}
                                            onChange={(e) => setTermsAgreement(e.target.checked)}
                                        />
                                        <p className="paragraph curate-checkbox">
                                            <span className='gray'>Required: </span>
                                            I agree to the <a target="_blank" href="https://vorticxr.com/terms-and-conditions/">Terms and Conditions</a> and have read and understood the <a target="_blank" href="https://vorticxr.com/privacy-notice">Privacy Notice</a>.
                                        </p>
                                    </label>
                                </form>
                                <br/><br/>
                                    <div className="sign-up__footer">
                                        {validateSignUp() && !modal && (
                                            !isCommentsForm &&
                                            !isEnquireForm &&
                                            <Button
                                                value='Create Account'
                                                className='button__dark'
                                                type='primary'
                                                size='large'
                                                disable={disabled}
                                                onClick={(e) => handleSignUp(e)} //TODO: discussion required
                                            />
                                        )}
                                        {!isEnquireForm && isCommentsForm && (
                                                <Button
                                                    value='Create Account'
                                                    className={`${validateSignUp() ? '' : 'disabled-btn' } button__bright row-reverse`}
                                                    type='primary'
                                                    iconType="add"
                                                    size='large'
                                                    disable={!validateSignUp()}
                                                    onClick={(e) => handleSignUp(e)} //TODO: discussion required
                                                />
                                        )}
                                        {(isEnquireForm || modal) && <>
                                            <div className='forms-buttons-flex'>
                                                <Button
                                                    value='Create Account'
                                                    className={'button__bright' + (!validateSignUp() ? ' button__disabled' : '')}
                                                    type='secondary'
                                                    size='small'
                                                    onClick={(e) => handleSignUp(e)} //TODO: discussion required
                                                />
                                                <Button
                                                    value="Cancel"
                                                    className={`button__white`}
                                                    type="secondary"
                                                    size="small"
                                                    onClick={loginLinkClick}
                                                />
                                            </div>
                                        </>}
                                        {handleBackBtnEvent && !isEnquireForm && <button className="button__go-back" onClick={() => handleBackBtnEvent()}>Go back</button>}
                                    </div>
                            </>
                    }
                </div>
                {!modal && <IconBtn className='cross' onClick={closeEventSignUp ? closeEventSignUp : handleClose} type={closeIconType || "cross"}
                         secondary fromExhLoading={fromExhLoading}/>}
            </section>
        </>
    )
}

export default SignUp


