import React from 'react';
import { Heading, Paragraph } from '../Typography2';
import { IInboxArtworkCard } from './types'

const InboxArtworkCard: React.FC<IInboxArtworkCard> = ({ imageUrl, onClick, title, timeline, description }) => {
    return (
        <div onClick={onClick} className='inbox-artwork'>
            <div className='inbox-artwork__preview'>
                <img src={imageUrl} alt='preview' />
            </div>
            <div className='inbox-artwork__details'>
                <Heading value={title} />
                {timeline && timeline.trim() && <Paragraph className='timeline' value={timeline}/>}
                {description && description.trim() && <Paragraph className='description' value={description}/>}
            </div>
        </div>
    )
};

export default InboxArtworkCard;
