import React, {useState, Fragment, useEffect} from 'react';
import {SubTitle} from '../../components/Typography';
import {Heading, Paragraph} from '../../components/Typography2';
import Input from "../../components/Input";
import Button from "../../components/Button";
import {useHistory, useParams} from 'react-router-dom';
import {reset} from "../../services/api";
import axios from "axios";
import {BACKEND_URL} from "../../configs"
import logger from '../../utils/logger';
import IconBtn from "../../components/IconBtn";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";


const Reset: React.FC = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validity, setValidCode] = useState('validating');
    const [token, setToken] = useState('');
    const [isSuccess, setSuccess] = useState(undefined);
    const [passErr, setPassErr] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const { setContentType } = useTrackingCode();

    const history = useHistory()

    // @ts-ignore
    let {resetId} = useParams();

    useEffect(() => {
        setContentType('reset-password-page');
        window.scrollTo(0, 0);
        validateCode();
    }, [])

    useEffect(() => {
        if (confirmPassword.length > 0 && isPasswordValid(confirmPassword) === false) {
            setPassErr(true)
        } else {
            setPassErr(false)
        }
    }, [confirmPassword]);

    useEffect(() => {
        if (password.length > 0 && isPasswordValid(password) === false) {
            setPassErr(true);
        } else {
            setPassErr(false);
        }
    }, [password]);

    useEffect(() => {
        if (password.length > 7 && isPasswordValid(password) && confirmPassword.length > 7 && isPasswordValid(confirmPassword)) {
            if (password !== confirmPassword) {
                setPasswordMatch(true);
            } else {
                setPasswordMatch(false);
            }
        } else {
            setPasswordMatch(false);
        }
    }, [password, confirmPassword]);

    const validateCode = async () => {
        try {
            const result = await reset({
                "code": resetId
            })
            if (result) {
                setValidCode('valid')
                if (result.token) {
                    setToken(result.token.auth);
                }
            } else {
                setValidCode('invalid')
            }
        } catch (err) {
            setValidCode('invalid')
        }
    };

    const handleResetPassword = async () => {
        try {
            const resp = await axios.post(`${BACKEND_URL}createPassword`, {
                code: resetId,
                password
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            //@ts-ignore
            if (resp) {
                logger.debug('resp = ', resp);
                setSuccess(true)
            } else {
                setSuccess(false)
            }
        } catch (err) {
            setSuccess(false)
        }
    };

    const handleClose = () => {
        history.goBack();
    };

    const isPasswordValid = (pass) => {
        return pass.length > 7 && /^(?=.*\d)(?=.*[!@$*()]).{8,}$/i.test(pass);
    };

    const validateReset = () => {
        return isPasswordValid(password) && password === confirmPassword
    }

    return (
        <div style={{height: '100%'}}>
            <div className="form-page form">
                <div className="form__container">
                    {(isSuccess !== true) && <><SubTitle value="Not a problem"/><br/><br/></>}
                    <Heading value={isSuccess === true ? "Password Updated" : "Reset password"} className="dark"/>

                    {
                        isSuccess !== true &&
                        (validity !== "validating" &&
                            validity !== "invalid") &&
                        <div className='guides-container'>
                          <Paragraph
                            className={`mb-2 ${passErr === false ? '' : 'invalid'}`}
                            value='Your password must be at least 8 characters long and include at least one number and one of the following symbols: !@$*()'
                          />
                          <br/>
                          <Paragraph
                            className={`mb-2 ${passwordMatch === true ? 'invalid' : 'none'}`}
                            value='Password is not matching.'
                          />
                            {
                                passwordMatch && <br/>
                            }
                        </div>}


                    <Paragraph
                        className={`mb-2 ${isSuccess === true ? 'dark' : 'none'}`}
                        value='Your password updated successfully, you can login to your account using the button below.'
                    />


                    {
                        (validity === "validating" ||
                            validity === "invalid") &&
                        <br/>
                    }


                    {
                        validity === "validating" &&
                        <Paragraph value="Validating code..." className="dark"/>
                    }

                    {
                        validity === "invalid" &&
                        <Paragraph value="Invalid code!" className="invalid"/>
                    }

                    {
                        isSuccess === false &&
                        <Paragraph value="Failed to reset password" className="invalid"/>
                    }

                    {
                        validity === "valid" &&
                        <Fragment>
                            {
                                isSuccess !== true &&
                                <>
                                  <Input
                                    value={password}
                                    label='Password'
                                    type='password'
                                    placeholder=''
                                    name="new-password"
                                    className={`input__dark`}
                                    onChange={(e) => setPassword(e.target.value)}
                                  />
                                  <br/>
                                  <Input
                                    value={confirmPassword}
                                    label='Confirm Password'
                                    name="confirm-new-password"
                                    type='password'
                                    placeholder=''
                                    className={`input__dark`}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                  />
                                </>
                            }
                            {validateReset() && <>
                              <br/><br/>
                              <Button
                                value={isSuccess ? "Login" : 'Reset Password'}
                                size="large"
                                className='button__dark'
                                type="primary"
                                onClick={isSuccess ? () => history.push("/login") : handleResetPassword}
                              />
                            </>}
                        </Fragment>
                    }
                </div>
                <IconBtn className='cross' onClick={handleClose} type="cross" secondary/>
            </div>
        </div>
    )
};
export default Reset;
