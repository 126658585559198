import React from 'react'
import Cross from '../../assets/icons/cross-gray.svg';
import IModalProps from './types';
import IconBtn from "../IconBtn";

const Modal:React.FC<IModalProps> = ({className,closeBtn,children, onClose}) => {
    return (
        <div className={`modal ${className}`}>
            <div onClick={onClose} className="modal__overlay"> </div>
            <div className="modal__box">
                {closeBtn&&<IconBtn
                    className="cross"
                    type="cross"
                    onClick={onClose}
                />}
                <div>
                    {children}
                </div>
            </div>
        </div >
    )
};

export default Modal;
