import ExhibitionDetails from './ExhibitionDetails'
import { connect } from 'react-redux'
import './style.scss'
import {setRedirectPath, setArtworkId, setArtworkIds, setExhibitionId} from "../../Global/redux/actions";
import {updateFavourite} from "../../Favourites/redux/actions";
import {setAciveArtWork, setArticlesDetailsBack} from '../redux/actions'

const mapDispatchToProps = dispatch => ({
    setRedirectPath: (path) => dispatch(setRedirectPath(path)),
    updateFavourite: () => dispatch(updateFavourite()),
    setAciveArtWork:()=>dispatch(setAciveArtWork(null)),
    setArtworkId:(val)=>dispatch(setArtworkId(val)),
    setExhibitionId:(val)=>dispatch(setExhibitionId(val)),
    setArtworkIds:(val)=>dispatch(setArtworkIds(val)),
    setArticlesDetailsBack:(val)=>dispatch(setArticlesDetailsBack(val)),
});

const mapStatesToProps = ({global,exhibition}) => {
    return {
        isLoggedIn: global.isLoggedIn,
        artworkId: global.artworkId,
        activeArtwork:exhibition.activeArtWork,
        isArticleDetailsBack:exhibition.isArticleDetailsBack,
    };
};
export default connect(mapStatesToProps, mapDispatchToProps)(ExhibitionDetails)
