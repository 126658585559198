import React, { useState, Fragment, useEffect } from 'react';
import { Heading, Paragraph } from '../../components/Typography2';
import Input from "../../components/Input";
import Button from "../../components/Button";
import { forgot } from "../../services/api";
import { Link, useHistory } from 'react-router-dom';
import IconBtn from "../../components/IconBtn";
import logger from "../../utils/logger";
import { is } from 'immer/dist/internal';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
// @ts-ignore   
// import { IForgotPassword } from "./types";

const Forgot = ({ isLoggedIn, isForgotPassword, goToLogin, fromExhLoading, isCommentsForm, handleCloseBtnEvent, isEnquireForm, isForgotPage, closeIconType, modal }) => {
    const [email, setEmail] = useState('');
    const [isSuccess, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const history = useHistory();
    const { setContentType } = useTrackingCode();

    const handleReset = async () => {
        try {
            const result = await forgot({
                email
            });
            if (result) {
                setSuccess(true)
            } else {
                setError(true);
            }
        } catch (e) {
            setSuccess(false)
            setError(true);
        }
    };
    const checkIsCuratePage = () => {
        return history.location.pathname.split('/').pop().includes('exhibitions/');
    }

    const isEmailValid = (mail) => {
        return /^\S+@\S+\.\S+$/.test(mail) === true;
    };


    const validEmail = () => {
        return isEmailValid(email);
    };

    const handleClose = () => {
        if(isForgotPage) {
            history.push('/discover')
        } else {
            history.goBack();
        }
    };

    useEffect(() => {
      if(email) {
        setError(false);
      }
    }, [email])

    useEffect(() => {
        // console.log('validEmail(); => ', validEmail());
        setIsDisabled(!validEmail());
    }, [email])

    useEffect(() => {
        setContentType('forgot-password-page');
        setIsDisabled(!validEmail());
    }, [])
    

    const renderBanner = () => {
        return (
            <div className="form__header" style={{ alignSelf: 'end' }} >
                <Button
                    value='Close'
                    className={`button__dark ${isCommentsForm ? 'row-reverse' : ''}`}
                    iconType={isCommentsForm ? (closeIconType || "close") : null}
                    type="primary"
                    onClick={() => handleCloseBtnEvent()}
                />
            </div>
        )
    }
    const handleLogin = () =>{
            history.push("/login")
    }
    const handleCloseBtn = ()=> {
        if(window.history.length === 1){
            window.close();
        }else{
            if(isForgotPassword) {
                goToLogin()
            } else {
                handleClose()
            }
        }
    }

    useEffect(() => {
        if(modal && isSuccess) {
            goToLogin();
        }
    }, [isSuccess, modal])

    return (
        <div style={{ height: '100%', width: '100%', }}>
            <div className={`form-page verify-page form `}>

                {isCommentsForm && renderBanner()}
                <div className={`form__container ${modal ? 'form__container--modal' : ''}`} style={{ margin: isCommentsForm ? 'unset' : 'auto', }}>
                    {<Heading value={modal ? 'Forgotten your password?' : 'Forgot Password'} style={{ fontFamily: "TT-Hoves" }} />}
                    {
                        isSuccess ?
                            <Paragraph
                                value="We’ve sent you a link to username/email with instructions to reset your password." />
                            :
                            <Fragment>
                                {modal && <Paragraph
                                className='modal-instruction'
                                value="Submit your email address and we’ll send you a prompt to reset your password." />}
                                <Input
                                    value={email}
                                    label='Email'
                                    type='email'
                                    name="email"
                                    placeholder=''
                                    className={`${(modal || isEnquireForm) ? 'input__bright' : 'input__dark'} ${error ? 'input__error' : ''}`}
                                    onChange={(e) => setEmail(e.target.value)}
                                    isModalInput={modal}
                                />
                                {error &&
                                    <Paragraph className="invalid" value="Email is Invalid!" />}
                                {
                                    (validEmail() && !modal && !isEnquireForm) && (
                                        <>
                                            <br /><br />
                                            <Button
                                                value={modal ? 'Submit' : 'Send Email'}
                                                size={modal || isEnquireForm ? "small" : "large"}
                                                disable={isDisabled}
                                                className={` ${isDisabled ? 'button__disabled' : ''} ${modal || isEnquireForm ? 'button__bright' : 'button__dark'}`}
                                                type={(!modal && !isEnquireForm) ? "primary" : "secondary"}
                                                onClick={() => handleReset()}
                                            />
                                        </>
                                    )
                                }
                                {
                                    (modal || isEnquireForm) && 
                                    <div className='forms-buttons-flex'>
                                        <Button
                                                value={modal ? 'Submit' : 'Send Email'}
                                                size={modal || isEnquireForm ? "small" : "large"}
                                                disable={isDisabled}
                                                className={` ${isDisabled ? 'button__disabled' : ''} ${modal || isEnquireForm ? 'button__bright' : 'button__dark'}`}
                                                type={!modal && !isEnquireForm ? "primary" : "secondary"}
                                                onClick={() => handleReset()}
                                        />
                                        <Button
                                            value={(!modal && !isEnquireForm) ? 'Go Back' : 'Cancel'}
                                            className={`button__white`}
                                            type="secondary"
                                            size="small"
                                            onClick={goToLogin}
                                        />
                                    </div>
                                }
                            </Fragment>
                    }

                    {
                        // TODO: Need a focused review
                        isSuccess && isLoggedIn === false && (
                            <>
                                <br /><br />
                                <Button
                                    value='Go to Login'
                                    className='button__dark'
                                    onClick={goToLogin == undefined ? handleLogin : goToLogin}
                                />
                            </>
                        )
                    }
                </div>
                {
                    // isEnquireForm  ?
                    // <div className='forms-buttons-forget'>
                    //     <Button
                    //         value='Go Back'
                    //         className={`button__white ${isCommentsForm ? 'row-reverse' : ''}`}
                    //         type="secondary"
                    //         size="small"
                    //         onClick={() => {   
                    //             handleCloseBtnEvent();
                    //         }}
                    //     />
                    // </div>
                    // :
                    (!modal && !isEnquireForm && <IconBtn className='cross' onClick={handleCloseBtn} type={closeIconType || "cross"} secondary fromExhLoading={fromExhLoading} />)
                }
            </div>
        </div>
    )
};
export default Forgot;
