export const APP_ID = '3FE0A7A6-C1E7-4B89-A68A-28B3CAE748BF';
export const USER_ID = 'user_id';
export const DISPLAY_NONE = 'none';
export const DISPLAY_BLOCK = 'block';
export const DISPLAY_FLEX = 'flex';
export const ACTIVE_CLASSNAME = 'active';
export const KEY_ENTER = 13;
export const FILE_ID = 'attach_file_id';
export const UPDATE_INTERVAL_TIME = 5 * 1000;
export const COLOR_RED = '#DC5960';
export const MESSAGE_REQ_ID = 'reqId';
