import {
    GET_EXHIBITION_DETAILS_INIT,
    SET_EXHIBITIONS_LOADER,
    UPDATE_SORT_BY,
    TOGGLE_FAVOURITE,
    RESET_EXHIBITIONS,
    FETCH_PRIVATE_PUBLIC_EXHIBITIONS_INIT,
    GET_EXHIBITION_DETAILS_SUCCESS,
    FETCH_EXHIBITIONS_INIT,
    FETCH_EXHIBITIONS_SUCCESS,
    SET_ACTIVE_ARTWORK,
    SET_ARTICLE_DETAILS_BACK
} from './actionTypes'

export function fetchExhibitionsInit(page, filterType, isGroup, groupId) {
    return {
        type: FETCH_EXHIBITIONS_INIT,
        payload: {
            page, filterType, isGroup, groupId
        }
    }
}

export function fetchPrivateAndPublicExhibitionsInit(page, filterType, isGroup) {
    return {
        type: FETCH_PRIVATE_PUBLIC_EXHIBITIONS_INIT,
        payload: {page, filterType, isGroup}
    }
}

export function fetchExhibitionsSuccess(data) {
    return {
        type: FETCH_EXHIBITIONS_SUCCESS,
        payload: data
    }
}

export function toggleFavouriteExhibition(exhibitionId, isFavourite) {
    return {
        type: TOGGLE_FAVOURITE,
        payload: {
            exhibitionId, isFavourite
        }
    }
}

export function resetExhibitions(forceUpdate = false) {
    return {
        type: RESET_EXHIBITIONS,
        payload: forceUpdate
    }
}

export function setAciveArtWork(id = null) {
    return {
        type: SET_ACTIVE_ARTWORK,
        payload: id
    }
}

export function setExhibitionsLoader(data) {
    return {
        type: SET_EXHIBITIONS_LOADER,
        payload: data
    }
}

export function fetchExhibitionDetailsInit(id) {
    return {
        type: GET_EXHIBITION_DETAILS_INIT,
        payload: id
    }
}

export function updateSortBy(value) {
    return {
        type: UPDATE_SORT_BY,
        payload: value
    }
}

export function fetchExhibitionDetailsSuccess(data) {
    return {
        type: GET_EXHIBITION_DETAILS_SUCCESS,
        payload: data
    }
}

export function setArticlesDetailsBack(val) {
    return {
        type: SET_ARTICLE_DETAILS_BACK,
        payload: val
    }
}
