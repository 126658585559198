import React from 'react';
import { Label, Paragraph } from '../Typography2';
import IconBtn from "../IconBtn";
import { IListHeader } from './types';

const ListHeader:React.FC<IListHeader> = ({ title,noConversations, className = "", subTitle, onBackClick, onCrossClick, onInfoClick }) => {
    return (
        <div className={`list-header ${className}`}>
            {onBackClick && <IconBtn type="prevIcon" onClick={onBackClick}  />}
            <div className='list-header__title'>
                {!noConversations && <Paragraph value={title}/>}
                {subTitle && <Label value={subTitle} />}
            </div>
            {onInfoClick && <IconBtn onClick={onInfoClick} type="info"/>}
            {onCrossClick && <IconBtn onClick={onCrossClick} type="brightClose"/>}
        </div>
    )
};

export default ListHeader;
