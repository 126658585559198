import React, { useState, useEffect, useRef, useImperativeHandle, Fragment } from "react";
import classNames from "classnames";
import { Label, Paragraph } from "../Typography2";
import AudioIcon from "../../assets/icons/audio.svg";
import ExpandDetailsIcon from "../../assets/icons/expand-details.svg";
import ArrowLeft from "../../assets/icons/arrow-left-bold.svg";
import ArrowRight from "../../assets/icons/arrow-right-bold.svg";
import CloseIcon from "../../assets/icons/close-bold.svg";
import "./style.scss";
import { ICurateMobileArtwork } from "../CurateMobileArtwork/types";
import CurateMobileArtworkDetails from "../CurateMobileArtworkDetails";
import { constructImageUrl, isMobileDevice } from "../../utils";
import { fetchArtworkDetails, fetchArtworkMedia } from "../../services/api";
import { motion, useDragControls } from "framer-motion/dist/framer-motion";
import AudioPlayer from '../AudioPlayer';
import Audio from '../Audio';
import FadeDown from "../FadeDown";
import ArtworksAudioDetails from "../../components/ArtworksAudioDetails";
import IconBtn from '../IconBtn';

const CurateMobileArtwork: React.FC<ICurateMobileArtwork> = React.forwardRef(
  ({
    onClick,
    handleLeftClick,
    handleRightClick,
    onClose,
    artistName,
    artworkName,
    yearCreated,
    size,
    medium,
    reference,
    price,
    description,
    artworkId,
    // setArtworkPhotos,
    // artworkPhotos,
    setArtworkModal,
    // isPlaying,
    // audioUrl,
    // currentTime,
    // totalDuration,
    // isAnime,
    // setAudioUrl,
    // setCurrentTime,
    // setTotalDuration,
    // setIsAnime,
    // setPlaying,
    exhibitionId,
    dimensionsInIN,
    dimensionsInCM,
    handleEnquire,
    isLoggedIn,
    handleLogin,
    saveToCollection,
    isFavourite,
    excludedTax,
    priceUnavailable,
    currency,
    isNotMuseum,
    isForSale,
    setTooltipStyle,
    isActive,
    setIsActive,
    isLockedInDetailsMode,
    handleAllTooltips,
    setTooltipBottom,
    currentState,
    setCurrentState,
    isMobileArtwork,
    handleStartView,
    handleStopView,
    putArtworkInDetailsMode,
    closeArtworkDetailsInMobile,
    onSeenArtworkId,
    instructed,
    toggleModalAuth,
    selectedTooltip,
    resetTooltips,
    isMuseumType,
    activeMinimizedProp,
    handleActiveMinimizedProp,
    handleToggleArtworkDetailedView,
    audioDetailsPopup,
    isCurateAudio,
    sidePanel,
    listview,
    galleryName,
    exhibitiondId,
    artworkCatalogueNumber,
    resizeCurateCanvas,
    onCloseDesktopDetails,
    viewportWidth,
    handleLoginForDesktop,
    handleArtworkDescriptionForDesktop,
    getCurateHeight,
    isArt = true,
    isSingleArtworkRoom
}, ref) => {

  const MAIN_HEIGHT = window.innerHeight;
  const INITIAL_DRAG_CONSTRAINTS = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  };

  const artworkDetailsRef = useRef(null);
  const artworkDetailsCompRef = useRef(null);
  // Frammer states
  const constraintsRef = useRef(null);
  const dragControls = useDragControls();
  const [dragDirection, setDragDirection] = useState(null);
  const [key, setKey] = useState(0);
  const [activeted, setActivated] = useState(false);
  const [minimzeHeight, setMinimizeHeight] = useState(null);
  const [maximizeHeight, setMaximizeHeight] = useState(null);
  const [defaultHeight, setDefaulHeight] = useState(null);
  const [isDraggable, setIsDraggable] = useState(true);
  const [isFullViewMode, setFullViewMode] = useState(false);
  const [descriptionHeight, setDescriptionHeight] = useState(null);
  const [audioUrl, setAudioUrl] = useState('');
  const [isPlaying, setPlaying] = useState(false);
  const [isAnime, setIsAnime] = useState(false);
  const [currentTime, setCurrentTime] = useState('0');
  const [totalDuration, setTotalDuration] = useState('0');
  const [dragConstraints, setDragConstraints] = useState(INITIAL_DRAG_CONSTRAINTS);
  const [positionY, setPositionY] = useState(window.innerHeight);
  const [artworkPhotos, setArtworkPhotos] = useState([]);
  const [horizontalScroll, setHorizontalScroll] = useState(false);
  const [originalMaximumHeight, setOriginalMaximumHeight] = useState(0);
  const [isAboveViewPortTop, setIsAboveViewPortTop] = useState(false);
  const isMobileView = isMobileDevice() || window.innerWidth <= 990;
  const [desktopDetailedArrowsOffsetY, setDesktopDetailedArrowsOffsetY] = useState(0);
  const [isOverlay, setOverlay] = useState(false);
  const AudioRef = useRef(null);
  const HorizontalScrollTextRef = useRef(null);
  const HorizontalScrollTextWrapperRef = useRef(null);
  const desktopExpandedDetailsRef = useRef(null);

  const handleOverlayUp = (newY) => {
    // const mobileArtworkDetails = document.querySelector('.curate-mobile-artwork-details');

    if(newY <= window.innerWidth / 2) {
      setOverlay(true);
    } else {
      setOverlay(false);
    }
  }

  const handleOverlayDown = (newY) => {
    // const mobileArtworkDetails = document.querySelector('.curate-mobile-artwork-details');

    const frammerContainer = document.querySelector('.frammer-custom-container');
    const isTransformed = frammerContainer.classList.contains('transformed');
    if(isTransformed) {
      if(newY <= window.innerWidth / 2){
        setOverlay(true);
      } else {
        setOverlay(false);
      }
    }
  }

  const handleDragEnd = (event) => {
    if(dragDirection === "down") {
      const element = document.querySelector('.frammer-custom-item');
      const rect = element.getBoundingClientRect();
      const gapFromTop = rect.top - window.scrollY;
      if(!isAboveViewPortTop) {
        setPositionY(MAIN_HEIGHT - minimzeHeight);

        if(isOverlay) {
          setOverlay(false);
        }
      }
      handleOverlayDown(MAIN_HEIGHT - minimzeHeight);
      if(gapFromTop >= 16) {
        setIsAboveViewPortTop(false)
      }
      const bottom = document.querySelector('.curate').clientHeight - dragConstraints.bottom;
      setTooltipBottom(bottom);
    } else {
      setPositionY(MAIN_HEIGHT - maximizeHeight);
      handleOverlayUp(MAIN_HEIGHT - maximizeHeight);
      const element = document.querySelector('.frammer-custom-item');
      const rect = element.getBoundingClientRect();
      const gapFromTop = rect.top - window.scrollY;
      if(gapFromTop <= 10) {
        setIsAboveViewPortTop(true)
      }
      // const top = document.querySelector('.curate').clientHeight - dragConstraints.top;
      // setTooltipBottom(top + 2);
    }
    setKey(key+1)
  };

  useEffect(() => {
    if(isMobileView){
      if(positionY === 0) {
        setFullViewMode(true)
      } else {
        setFullViewMode(false)
      }
    }
  }, [positionY])

  const handleDrag = (event, info) => {
    if(selectedTooltip){
      resetTooltips();
    }
    if(!activeted) {
      setActivated(true)
    }
    if (info.offset.y > 0) {
      setDragDirection("down");
    } else if (info.offset.y < 0) {
      setDragDirection("up");
    }
    updatePillSticky();
  };

  const handleMouseDown = (e) => {
    dragControls.start(e, { snapToCursor: false })
    setActivated(true);
  }

  useEffect(() => {
    if(isMobileView && (minimzeHeight || originalMaximumHeight)) {
      setDragConstraints({
        top: (MAIN_HEIGHT - originalMaximumHeight),
        left: 0,
        right: 0,
        bottom: (MAIN_HEIGHT - (isAboveViewPortTop ? (MAIN_HEIGHT) : minimzeHeight))
      })
    }
  }, [minimzeHeight, originalMaximumHeight, isAboveViewPortTop])

  useEffect(() => {
    if(isMobileView && artworkDetailsRef.current && artworkId) {
      if(artworkDetailsRef.current.clientHeight + 110 <= window.innerHeight){
        setMaximizeHeight(artworkDetailsRef.current.clientHeight + 120);
      } else {
        setMaximizeHeight(window.innerHeight)
      }
      setOriginalMaximumHeight(artworkDetailsRef.current.clientHeight + 120);
    }
  }, [artworkDetailsRef, artworkDetailsRef.current, artworkId, artworkPhotos])

  useEffect(() => {
    if(isMobileView){
      if(isActive && defaultHeight) {
          setIsAboveViewPortTop(false);
          setPositionY((MAIN_HEIGHT - defaultHeight) - 16);
          if(isOverlay) {
            setOverlay(false);
          }
        } else {
          setPositionY(MAIN_HEIGHT - 16);
          if(isOverlay) {
            setOverlay(false);
          }
      }
    }
  }, [isActive, defaultHeight, artworkId])

  // * End

  useEffect(() => {
    if(isMobileView && !artworkId && !isLockedInDetailsMode) {
      handleClose();
    }
  }, [artworkId, isLockedInDetailsMode])

  const serialize = (obj) => {
    var str = [];
    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  };


  const getCurateArtworkAddress = () => {
    const curateLink = window.location.href.slice(
      0,
      window.location.href.indexOf("?") + 1
    );
    // const curateLink = window.location.href;
    var hash = window.location.search;
    var array = hash.substring(1).split("&");
    var values,
      form_data = {};

    for (var i = 0; i < array.length; i += 1) {
      values = array[i].split("=");
      form_data[values[0]] = values[1];
    }

    delete form_data["eventId"];

    return `${curateLink}${serialize(form_data)}`;
  };

  useEffect(() => {
    if(isMobileView && isActive){
      // @ts-ignore
      setTooltipStyle(null);
    } else {
      setTooltipStyle(null);
    }
  }, [isActive, currentState, artworkPhotos])

  const loadAudioDetails = async () => {
    if (exhibitionId && artworkId) {
        let resp = await fetchArtworkMedia(exhibitionId, artworkId);
        if(resp?.data[0]?.data?.channel?.data?.mediaType === "audio" && !!resp?.data[0]?.data?.channel?.data?.audio) {
            setAudioUrl(resp.data[0].data.channel.data?.audio?.data?.signedUrl);
        } else if (resp.data && resp.data[0] && resp.data[0].data && resp.data[0].data.channel && resp.data[0].data.channel.data && resp.data[0].data.channel.data.media && resp.data[0].data.channel.data.media.data) {
            setAudioUrl(resp.data[0].data.channel.data.media.data.signedUrl);
        }
    }
    };

useEffect(() => {
  if(artworkId && !!exhibitionId) {
    setAudioUrl('')
    loadAudioDetails();
  }
}, [artworkId, exhibitionId])

useEffect(() => {
  const viewHeightHalf = window.innerHeight / 2;
  const expandedDetailsHeightHalf = desktopExpandedDetailsRef.current?.clientHeight / 2;
  setDesktopDetailedArrowsOffsetY(viewHeightHalf - (expandedDetailsHeightHalf + 24)); // 24 is the radius of the arrow button
}, [desktopExpandedDetailsRef.current, desktopExpandedDetailsRef.current?.clientHeight])

  const handleOpen = () => {
    setIsActive(true);
    onClick(artworkId);
  };

  const handleClose = () => {
    if(!isLockedInDetailsMode) {
      handleCloseforUnlocked();
    } else {
      setCurrentState("down");
    }
  };

  const handleMinimize = () => {
    if(!isLockedInDetailsMode){
      setCurrentState("minimize");
    } else {
      setCurrentState("down");
    }
  }

  const handleCloseforUnlocked = () => {
    setIsActive(false);

    setTimeout(() => {
      setCurrentState("down");
    }, 500);

    if(artworkDetailsCompRef.current) {
      artworkDetailsCompRef.current?.restart();
    }

    onClose();
  }
  // Additional Images fetching

  const loadArtwork = async () => {
    try {
      if (artworkId) {
        const resp = await fetchArtworkDetails(artworkId);
        const tempPhotos = [];
        resp?.data?.photos?.data?.map((item, index) => {
          tempPhotos.push({
            small: constructImageUrl(item, "120"),
            large: constructImageUrl(item, "1920"),
            selected: index === 0,
          });
        });
        setArtworkPhotos(tempPhotos);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // Swiper Funtionality

  const handleSwipe = (val, isContent = false) => {
    if(val === "down") {
      handleMinimize();
      return;
    }
    if(isContent) {
      if(isActive && currentState === "up") {
        setCurrentState(val);
      }
      else {
        if(val === "up") {
          setCurrentState(val);
        } else {
          const elm = document.querySelector(".mobile-artwork-content--dragger");
          if(elm.scrollTop === 0) {
            setCurrentState(val);
          }
        }
      }
    } else {
      if(val === "up") {
        setCurrentState(val);
      }
    }
  };

  const onLeftArrowClick = () => {
    handleLeftClick();
    handleAllTooltips();
    resetDetailsPill();
  }

  const onRightArrowClick = () => {
    handleRightClick();
    handleAllTooltips();
    resetDetailsPill();
  }

  const resetDetailsPill = () => {
    if(isOverlay) {
      setOverlay(false)
    }
    const parent = document.querySelector('.frammer-custom-container');
    parent?.classList?.remove('transformed');
  }

  useImperativeHandle(
    ref,
    () => ({
        reset: () => {
          handleSwipe("down");
        },
        close: () => {
          handleCloseforUnlocked();
        },
        open: () => {
          handleSwipe("up");
        },
        togglePlaying: (val) => {
          togglePlaying(val);
        },
        handleCloseArtworkDetails: () => {
          handleCloseArtworkDetails();
          setTimeout(() => {
            setPositionY(window.innerHeight);
          }, 200)
        },
        setPositionY: (val) => {
          setPositionY(val);
        },
        handleClickOnMiniDetails: () => {
          handleClickOnMiniDetails();
        }
    }),
  );

  const handleClickOnMiniDetails = () => {
      if(isMobileDevice() || window.innerWidth <= 990){
        handleOpen();
        putArtworkInDetailsMode(artworkId);
      } else {
        handleToggleArtworkDetailedView(true);
      }
  }

  const handleCloseArtworkDetails = () => {
    handleClose();
    resetDetailsPill();
  }

  const handleNextBtnClick = (e) => {
    handleRightClick();
    if(!activeMinimizedProp){
      handleActiveMinimizedProp(true);
    }
    resetDetailsPill();
  }

  const handleMinimizedPrev = (e) => {
    handleLeftClick();
    resetDetailsPill();
  }

  const handleMinimizedNext = (e) => {
    handleRightClick();
  }

  const togglePlaying = (val) => {
    AudioRef?.current?.togglePlay(val);
    setPlaying(val);
  }

  const updatePillSticky = () => {
    const parent = document.querySelector('.frammer-custom-container');
    const child = document.querySelector('.frammer-custom-item');
    const isChildAboveParent = child.getBoundingClientRect().top < parent.getBoundingClientRect().top;
    if (isChildAboveParent) {
        parent.classList.add('transformed');
    } else {
        parent.classList.remove('transformed');
    }
  }

  useEffect(() => {
    setArtworkPhotos([]);
    if (artworkId) {
        loadArtwork();
    }
  }, [artworkId])

  useEffect(() => {
    const wrapperWidth =  HorizontalScrollTextWrapperRef.current.clientWidth;
    const textWidth =  HorizontalScrollTextRef.current.clientWidth;
    if(textWidth > wrapperWidth) {
      setHorizontalScroll(true);
      const widthDiff = textWidth - wrapperWidth;
      setVariableWidth(widthDiff+20);
    } else {
      setHorizontalScroll(false);
    }
  }, [artworkId])

  const setVariableWidth = (val) => {
    const doc = document.documentElement;
    doc.style.setProperty('--horizontalScrollText', `${-val}px`);
  }

  return (
    <>
    {(window.innerWidth >= 990 || !isMobileDevice()) && !isSingleArtworkRoom && (
        <IconBtn
          type={
            isMobileDevice() && window.innerWidth <= 550
              ? "mobilePrevIcon"
              : "chevronLeft"
          }
          style={{ top: `${desktopDetailedArrowsOffsetY}px` }}
          className={`tour-control-button active detailed-mode-arrow make-left ${audioDetailsPopup ? 'detailed-mode-arrow-fade-in' : ''}`}
          onClick={onLeftArrowClick}
        />
    )}

    {(window.innerWidth >= 990 || !isMobileDevice()) && !isSingleArtworkRoom && (
        <IconBtn
          type={
            isMobileDevice() && window.innerWidth <= 550
              ? "mobilePrevIcon"
              : "chevronRight"
          }
          style={{ top: `${desktopDetailedArrowsOffsetY}px` }}
          className={`tour-control-button active detailed-mode-arrow next ${audioDetailsPopup ? 'detailed-mode-arrow-fade-in' : ''}`}
          onClick={onRightArrowClick}
        />)
    }
    <div
      className={`curate-mobile-artwork-wrapper ${isActive ? "opened" : ""}`}
    >
      <div className={`curate-mobile-artwork ${isMobileArtwork ? 'visible' : ''} ${isActive ? "active" : ""} ${activeMinimizedProp ? 'active-minimized' : ''} ${audioDetailsPopup ? 'curate-mobile-artwork--details-opened' : ''} `}>
        <div className="curate-mobile-artwork-content">
          <button className="round-btn btn-left" onClick={handleMinimizedPrev}>
            <div className="svg-wrapper">
              <img className="btn-icon" src={ArrowLeft} alt="arrow" />
            </div>
          </button>
          <div className="curate-mobile-artwork-content__left" onClick={handleClickOnMiniDetails}>
            <div ref={HorizontalScrollTextWrapperRef}>
              <Label value={artistName || ""} className="artwork-main-title" />
              <p className="label artwork-main-title artwork-name" >
                  <span className={horizontalScroll ? "bounce" : ""} ref={HorizontalScrollTextRef}>{artworkName}, {yearCreated}</span>
              </p>
            </div>
          </div>
          <div className={`curate-mobile-artwork-content__controls ${isSingleArtworkRoom ? 'curate-mobile-artwork-content__controls--single-artwork-room' : ''}`}>
            {!!audioUrl && <FadeDown variant="fade-in">
              <AudioPlayer
                isPlaying={isPlaying}
                audioUrl={audioUrl}
                currentTime={currentTime}
                totalDuration={totalDuration}
                setIsPlaying={togglePlaying}
                isMinimizedVariant={true}
              />
            </FadeDown>}
            <button className="round-btn expand-details-btn" onClick={handleClickOnMiniDetails}>
              <div className="svg-wrapper">
                <img className="btn-icon" src={ExpandDetailsIcon} alt="arrow" />
              </div>
            </button>
            <button className={`round-btn btn-next ${activeMinimizedProp ? 'btn-next--active' : ''}`} onClick={handleNextBtnClick}>
            <Paragraph value='Next' />
            <div className="svg-wrapper">
              <img className="btn-icon" src={ArrowRight} alt="arrow" />
            </div>
          </button>
            </div>
        </div>
        <div className={`controls`}>
          <button className="round-btn" onClick={onLeftArrowClick}>
            <div className="svg-wrapper">
              <img className="btn-icon" src={ArrowLeft} alt="arrow" />
            </div>
          </button>
          {!isLockedInDetailsMode && <button className="round-btn round-btn-white" onClick={handleCloseArtworkDetails}>
            <div className="svg-wrapper">
              <img className="btn-icon" src={CloseIcon} alt="close" />
            </div>
          </button>}
          <button className="round-btn" onClick={onRightArrowClick}>
            <div className="svg-wrapper">
              <img className="btn-icon" src={ArrowRight} alt="arrow" />
            </div>
          </button>
        </div>
      </div>
      {!!audioUrl && (
        <Audio
          ref={AudioRef}
          // @ts-ignore
          setTotalTime={(val) => setTotalDuration(val)}
          setCurrentTime={(val) => setCurrentTime(val)}
          setPlaying={togglePlaying}
          url={audioUrl}
          play={isPlaying}
        />
      )}
      <div className={`frammer-custom-container__overlay ${isOverlay ? 'frammer-custom-container__overlay--active' : ''}`}></div>
      {
        (isMobileDevice() || window.innerWidth <= 990) ?
        <motion.div
        className="frammer-custom-container"
        ref={constraintsRef}
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
      >
        <div className="frammer-custom-container__pill-top"><span/></div>
        <motion.div
          className="frammer-custom-item"
          drag={isDraggable ? "y" : null}
          onDrag={handleDrag}
          style={{ touchAction: "none" }}
          dragConstraints={dragConstraints}
          onDragEnd={handleDragEnd}
          // dragElastic={0.1}
          dragElastic={0}
          dragMomentum={false}
          dragDirectionLock={true}
          animate={{ y: positionY }}
          transition={{ duration: 0.30 }} // Add transition property for animation
        >
          {/* @ts-ignore */}
          <CurateMobileArtworkDetails
            ref={artworkDetailsCompRef}
            setDraggable={setIsDraggable}
            isDraggable={isDraggable}
            isFullViewMode={isFullViewMode}
            setIsScrollActive={(val) => {}}
            isScrollActive={false}
            isLockedInDetailsMode={isLockedInDetailsMode}
            setMinimizeHeight={(val) => setMinimizeHeight(val)}
            setDefaulHeight={(val) => setDefaulHeight(val)}
            setMaximizeHeight={(val) => setMaximizeHeight(val)}
            artworkDetailsRef={artworkDetailsRef}
            artistName={artistName}
            artworkName={artworkName}
            yearCreated={yearCreated}
            medium={medium}
            reference={reference}
            price={price}
            description={description}
            artworkPhotos={artworkPhotos}
            setArtworkModal={setArtworkModal}
            isPlaying={isPlaying}
            audioUrl={audioUrl}
            currentTime={currentTime}
            isAnime={isAnime}
            setPlaying={setPlaying}
            setAudioUrl={setAudioUrl}
            setIsAnime={setIsAnime}
            exhibitionId={exhibitionId}
            artworkId={artworkId}
            dimensionsInIN={dimensionsInIN}
            dimensionsInCM={dimensionsInCM}
            handleEnquire={handleEnquire}
            isLoggedIn={isLoggedIn}
            handleLogin={handleLogin}
            saveToCollection={saveToCollection}
            toggleModalAuth={toggleModalAuth}
            isFavourite={isFavourite}
            excludedTax={excludedTax}
            chainLink={getCurateArtworkAddress()}
            isArtworkListedForSale={isForSale && isNotMuseum}
            isMuseumType={isMuseumType}
            priceUnavailable={priceUnavailable}
            currency={currency}
            setTooltipBottom={setTooltipBottom}
            setIsFullViewMode={setFullViewMode}
            isActive={isActive}
            isHalf={isActive && currentState === "down"}
            fullOpened={isActive && currentState === "up"}
            isMinimized={isActive && currentState === "minimize"}
            setDescriptionHeight={setDescriptionHeight}
            descriptionHeight={descriptionHeight}
            totalDuration={totalDuration}
            togglePlaying={togglePlaying}
            style={{}}
          />
        </motion.div>
      </motion.div>
      :
      <Fragment>
        {
                <div className={classNames(
                    "bottom-details",
                    "draggable-box",
                    { "bottom-details--sidepanel-active": sidePanel },
                    // { "bottom-details--2dModel": isArtwork2dModal },
                    // { "mobile-room-box": showRoomsBox },
                    // { "full-width": audioDetailsPopup && !viewMap && !viewDesc && !viewEvent && !enquire && !isSidebar },
                    { "full-width": audioDetailsPopup && !sidePanel },
                    // { "half": isSidebar },
                    { "half": sidePanel },
                    { "active": audioDetailsPopup },
                    // { "full": isAudioPopupFull }
                  )}
                  id="draggable-div"
                  ref={desktopExpandedDetailsRef}
                >
                  <ArtworksAudioDetails
                    listview={listview}
                    name={galleryName}
                    showEnquireSideBar={handleEnquire}
                    isMuseumType={isMuseumType}
                    closeDetails={handleCloseArtworkDetails}
                    artworkId={artworkId}
                    isFavourite={isFavourite}
                    exhibitiondId={exhibitiondId}
                    toggleModalAuth={toggleModalAuth}
                    play={false}
                    isNotMuseum={isNotMuseum}
                    isForSale={isForSale}
                    priceUnavailable={priceUnavailable}
                    artworkMedium={medium}
                    artworkCatalogueNumber={artworkCatalogueNumber}
                    artistName={artistName}
                    artworkName={artworkName}
                    yearCreated={yearCreated}
                    description={description}
                    price={price}
                    currency={currency}
                    dimensionsInCM={`${dimensionsInCM} cm`}
                    dimensionsInIN={`${dimensionsInIN} in`}
                    saveToCollection={saveToCollection}
                    resizeCurateCanvas={resizeCurateCanvas}
                    onClose={onCloseDesktopDetails}
                    audioDetailsPopup={audioDetailsPopup}
                    viewportWidth={viewportWidth}
                    handleLogin={handleLoginForDesktop}
                    setCurateAudio={(val) => {
                      // TODO: Need to look into this ;) 
                      // this.handleAudioChange(val);
                    }}
                    handleArtworkDescription={handleArtworkDescriptionForDesktop}
                    isSidebar={sidePanel}
                    // TODO: Need to look into this ;) 
                    audioPlaying={false}
                    // TODO: Need to look into this ;) 
                    audioUrl={audioUrl}
                    // TODO: Need to look into this ;) 
                    audioCurrentTime={'0'}
                    audioTotalTime={'0'}
                    isAnime={isAnime}
                    setIsAnime={() => {}}
                    artworkPhotos={artworkPhotos}
                    isActive={isActive}
                    setActive={(val) => {}}
                    setArtworkModal={setArtworkModal}
                    getCurateHeight={getCurateHeight}
                    excludedTax={excludedTax}
                    // Audio props
                    isPlaying={isPlaying}
                    totalDuration={totalDuration}
                    currentTime={currentTime}
                    togglePlaying={togglePlaying}
                    setCurrentTime={setCurrentTime}
                    isArt={isArt}
                  />
                </div>
              }
      </Fragment>
      }
    </div>
    </>
  );
});

export default CurateMobileArtwork;
