// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import IInputProps from './types';
import DarkEye from '../../assets/icons/eye-close-up.svg';
import BrightEye from '../../assets/icons/eye-close-up-bright.svg';
import EyeIcon from '../../assets/icons/eye.svg';
import EyeVisible from '../../assets/icons/eye-visible.svg';
import { P2 } from '../Typography';
import smoothscroll from 'smoothscroll-polyfill';
import {isMobileDevice} from "../../utils";


const Input: React.FC<IInputProps> = ({important,name,active, label, onChange, value, placeholder, type, className, disabled, onMouseLeave, autoComplete, errorMsg, isModalInput}) => {

    const [focus, setFocus] = useState(false);
    const [passVisible, setPassVisibility] = useState(false);
    const [browser, setBrowser] = useState('');
    const InputRef = useRef(null);
    const fieldRef = React.useRef<HTMLInputElement>(null);

    const handleBlur = () => {
        if(value === ''){
            setFocus(false);
        }
        // document.querySelector('.reduceHeightLogin').style.height='30%';
    };

    const getBrowser = () => {
        var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        var isFirefox = typeof InstallTrigger !== 'undefined';
        var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
        if(isChrome === true){
            return 'chrome';
        }
        else if (isFirefox === true){
            return 'firefox';
        }
        else if (isSafari === true){
            return 'safari';
        }
        else{
            return '';
        }
    }

    const reValue = () => {
        if(browser === 'chrome') {
            const allFields = document.querySelectorAll('.input-field');
            allFields && allFields.forEach(field => (field.value = field.value));
        }
    };

    useEffect(() => {
        setBrowser(getBrowser());
        smoothscroll.polyfill();
    }, []);

    const handleFocus = (el) => {
        if(!isModalInput) { 
            if(browser === 'chrome' && window.innerWidth < 554){
                const elementTop = window.pageYOffset + el.target.getBoundingClientRect().top;
                window.scrollTo(0, elementTop - 180);
            };
        }
    };
    const handleInputFocus = (event) => {
        if(!isModalInput) {
            const titleElement=document.getElementById(event.target.id)
            if(window.innerWidth < 554 || isMobileDevice()) {
                titleElement.scrollIntoView({ behavior: 'smooth', block: "center", inline: "center" ,offset: { top: 50, left: 0 }})
                window.scroll({
                    top:100,
                    left:100,
                    behavior:'smooth',
                })
            }
        }
        // fieldRef.current.scrollIntoView({ behavior: "smooth" });
        // if (window.innerWidth < 991&&isMobileDevice()){
        //     document.querySelector('.reduceHeightLogin').style.height='10%';
        // }
    }

    const handlePasswordVisibility = () => {
        setPassVisibility(!passVisible);
    };


    return (
        <>
        <label ref={fieldRef} id={name} onFocus={(e) => handleFocus(e)}  className={`input ${className} ${type === 'password' ? 'input--password' : ''} ${errorMsg ? 'input--error' : ''} ${(focus ||active|| value || className.includes('input--error')) ? 'active' : ''} ${disabled ? 'input--disabled' : ''} ${name || ''}`} onMouseLeave={onMouseLeave} onClick={() => reValue()} >
            {label?<p className={`label`}>
                {label} {important&&<span>*</span>}
            </p>:''}
                <input
                    ref={InputRef}

                    onFocus={(event) => {
                        setFocus(true);
                        handleInputFocus(event)
                    }}
                    onBlur={handleBlur}
                    disabled={disabled}
                    id={name}
                    name={name}
                    type={type === 'password' ? (passVisible ? 'text' : 'password') : type}
                    autoComplete={autoComplete ? autoComplete : "false"}
                    onChange={onChange}
                    value={value}
                    className="input-field"
                    placeholder={placeholder}
                />
                {
                    type === 'password' &&
                    <div onClick={() => handlePasswordVisibility()} className={`eye-icon ${className.includes('input__bright')  ? '' : 'bright'} ${(isModalInput || passVisible) ? 'password-visible' : ''}`}>
                      <img src={isModalInput ? (passVisible ? EyeVisible : EyeIcon) : (className.includes('input__bright') ? DarkEye : BrightEye)} alt='eye' />
                    </div>
                }
                {window.innerWidth <= 780 && errorMsg && <P2 className={`input-err-msg`} value={errorMsg} />}
            </label>
        </>
    )
};

export default Input;
