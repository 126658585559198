import React, {useEffect, useState} from "react";
import { Title, Title2 } from "../Typography2";
import Button from "../Button";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {acceptInvite, login} from "../../services/api";

const VERIFICATION_STATUS = {
    NULL: 'NULL',
    VERIFYING: 'VERIFYING',
    VERIFIED: 'VERIFIED',
    ERROR: 'ERROR'
}

const CodeVerification = () => {
    const [verificationStatus, setVerificationStatus] = useState(VERIFICATION_STATUS.VERIFYING)
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const parsed = location.search.split('=');
        if(parsed[1]) {
            validateVerificationCode(parsed[1])
        }
        else {
            setVerificationStatus(VERIFICATION_STATUS.NULL)
        }
    }, [])

    const validateVerificationCode = async (code) => {
        try {
            const resp = await acceptInvite(code);
            if(resp && resp.success) {
                setVerificationStatus(VERIFICATION_STATUS.VERIFIED)
            }
        }
        catch (e) {
            setVerificationStatus(VERIFICATION_STATUS.ERROR)
        }
    }

    return (
        <div className={'container not-found-box'}>

            {
                verificationStatus === VERIFICATION_STATUS.NULL &&
                    <>
                      <Title className="head" value="Ooops..."/>
                    </>
            }

            {
                verificationStatus === VERIFICATION_STATUS.VERIFYING &&
                    <>
                      <Title className="head" value="Loading..."/>
                    </>
            }

            {
                verificationStatus === VERIFICATION_STATUS.VERIFIED &&
                    <>
                      <Title className="head" value="Thank you!"/>
                      <Title className="h1" value="You successfully opted out of our mailing list."/>
                    </>
            }

            {
                verificationStatus === VERIFICATION_STATUS.ERROR &&
                    <>
                      <Title className="head" value="Ooops..."/>
                      <Title className="h1" value="Failed to opt you out."/>
                    </>
            }


            <Button
                value='Take me to Discover'
                className='button__bright btn-collective'
                onClick={() => {
                    history.push('/discover')
                } }
            />
        </div>
    )
}

export default CodeVerification;
