export const GET_EXHIBITION_DETAILS = 'GET_EXHIBITION_DETAILS';
export const FETCH_EXHIBITIONS_INIT = 'FETCH_EXHIBITIONS_INIT';
export const FETCH_PRIVATE_PUBLIC_EXHIBITIONS_INIT = 'FETCH_PRIVATE_PUBLIC_EXHIBITIONS_INIT';
export const FETCH_EXHIBITIONS_SUCCESS = 'FETCH_EXHIBITIONS_SUCCESS';
export const GET_EXHIBITION_DETAILS_SUCCESS = 'GET_EXHIBITION_DETAILS_SUCCESS';
export const GET_EXHIBITION_DETAILS_INIT = 'GET_EXHIBITION_DETAILS_INIT';
export const RESET_EXHIBITIONS = 'RESET_EXHIBITIONS';
export const TOGGLE_FAVOURITE = 'TOGGLE_FAVOURITE';
export const UPDATE_SORT_BY = 'UPDATE_SORT_BY';
export const SET_EXHIBITIONS_LOADER = 'SET_EXHIBITIONS_LOADER';
export const SET_ACTIVE_ARTWORK = 'SET_ACTIVE_ARTWORK';
export const SET_ARTICLE_DETAILS_BACK = 'SET_ARTICLE_DETAILS_BACK';
