import React, { useEffect } from 'react';
import { IConversationBoxProps } from './types';
import { Paragraph } from '../Typography2';
import ListHeader from "../ListHeader";
import ChatListItem from "../ChatListItem";
import {getNamesFromChat} from '../../services/sendbird/utils'
import { isMobileDevice } from '../../utils';

const ConversationBox:React.FC<IConversationBoxProps> = ({profile, onListItemClick, conversationList, headerTitle, onClose, className, isConversation}) => {

    useEffect(() => {
        if(isMobileDevice() || window.innerWidth <= 768) {
            const body = document.querySelector('body');
            if(isConversation) {
                body.classList.add('overflow-controls');
            } else {
                body.classList.remove('overflow-controls');     
            }
        }
    }, [isConversation])
    
    return (
        <div className={`conversation-box${className ? (' ' + className) : ''}`}>
            <ListHeader noConversations={conversationList.length < 1} title={headerTitle} onCrossClick={onClose} />
            <div className='chat-list-wrapper'>
                {
                    conversationList.length > 0 ? conversationList.map((channel, index) => {
                        const {name,  galleryTime, lastMessage, members} = channel;
                        let [userName, galleryName] = getNamesFromChat(name, members, profile.id);
                        return (
                            <ChatListItem key={index} title={galleryName} galleryName={userName} galleryTime={galleryTime} message={lastMessage.message} onClick={() => onListItemClick(channel)} />
                        )
                    }) : (
                        <div className='zero-chat-wrapper'>
                            <Paragraph value="You have no conversations. Please inquire on artworks to start talking to galleries." />
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default ConversationBox;
