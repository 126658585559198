import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useRouteMatch} from "react-router";
import {fetchCollectiveDetails, fetchCollectiveExhibitions, fetchCollectiveGalleries} from "../../../services/api";
import Exhibition from "../../../components/Exhibition";
import Card from "../../../components/Card";
import DetailsCard from "../../../components/DetailsCard";
import Spinner from "../../../components/Spinner";
import {ICollectiveDetailState} from "./types";
import ArticleBox from '../../../components/Article'
import {constructImageUrl, getIdFromSlug} from '../../../utils'
import DetailsHeader from "../../../components/DetailsHeader";
import ScrollAnimation from '../../../components/ScrollAnimation/ScrollAnimation';
import { Paragraph } from '../../../components/Typography2';
import {
    favouriteExhibition,
    favouriteCollectives,
    fetchCollectiveArticles,
} from "../../../services/api";
import HeadTags from "../../../components/Helmet/HeadTags";

const CollectiveDetails = (props) => {

    const initialDetails = {
        id: null,
        slug: '',
        name: '',
        image: {},
        description: '',
        shortDescription:'',
    }
    const route = useRouteMatch();
    const history = useHistory();
    const [details, setDetails] = useState<ICollectiveDetailState>(initialDetails)
    const [exhibitions, setExhibitions] = useState([])
    const [articles, setArticles] = useState([]);
    const [galleries, setGalleries] = useState([])
    const [isLoadingDetails, setLoadingDetails] = useState(true)
    const [isLoadingGalleries, setLoadingGalleries] = useState(true)
    const [isLoadingExhibitions, setLoadingExhibitions] = useState(true)
    const CardsView = useRef(null);
    const articlesRef = useRef(null);
    const CollectivesCards = useRef(null);

    // @ts-ignore
    const collectiveId = route && route.params && route.params.id;

    const loadCollectiveDetails = async () => {
        return new Promise(async (resolve, reject) => {
            const galleryDetails = await fetchCollectiveDetails(collectiveId);
            if (galleryDetails.data) {
                setDetails(galleryDetails.data)
                setLoadingDetails(false);
                resolve(true)
            } else {
                resolve(true)
            }
        })
    };

    const loadCollectiveExhibitions = async () => {
        const exhibitionsResp = await fetchCollectiveExhibitions(getIdFromSlug(collectiveId));
        if (exhibitionsResp.data) {
            setExhibitions(exhibitionsResp.data);
            setLoadingExhibitions(false);
        }
    };

    const loadCollectiveGalleries = async () => {
        return new Promise(async (resolve, reject) => {
            const galleriesResp = await fetchCollectiveGalleries(getIdFromSlug(collectiveId));
            if (galleriesResp.data) {
                setGalleries(galleriesResp.data);
                setLoadingGalleries(false);
                resolve(true)
            } else {
                resolve(true)
            }
        })
    };

    const loadCollectiveArticles = async () => {
        return new Promise(async (resolve, reject) => {
            const articleResp = await fetchCollectiveArticles(getIdFromSlug(collectiveId));
            if (articleResp.data) {
                const articlesResponse = articleResp.data;
                articlesResponse.sort(function (a, b) {
                    return a.data.y - b.data.y
                });
                setArticles(articlesResponse);
                if (props.isArticleDetailsBack) {
                    articlesRef.current.scrollIntoView({behavior: 'smooth'});
                    props.setArticlesDetailsBack(false);
                }
                resolve(true)
            } else {
                resolve(true)
            }
        })
    };
    const fetchDetails = async () => {
        const details = await loadCollectiveDetails();
        if (details) {
            const galleries = await loadCollectiveGalleries();
            if (galleries) {
                const articles = await loadCollectiveArticles();
                if (articles) {
                    loadCollectiveExhibitions();
                }
            }
        }
    };

    const handleScrollLeft = () => {
        CardsView.current && CardsView.current.scroll({left: CardsView.current.scrollLeft - 350, behavior: 'smooth'});
    };
    const handleScrollRight = () => {
        CardsView.current && CardsView.current.scroll({left: CardsView.current.scrollLeft + 350, behavior: 'smooth'});
    };

    const handleScroll = (e) => {}

    useEffect(() => {
        window.scrollTo(0, 0)
        props.setRedirectPath('');
        fetchDetails();

    }, [])

    useEffect(() => {
    }, [details])

    const handleGalleryClick = (galleryId) => {
        history.push(`/galleries/${galleryId}`)
    };

    const handleExhibitionClick = (id) => {
        history.push(`/exhibitions/${id}`)
    };


    const toggleFavourite = async (collectiveId) => {
        if (props.globalProps.isLoggedIn) {
            const resp = await favouriteCollectives(collectiveId);
            if (resp.data) {
                const updatedDetails = {
                    ...details,
                    isFavourite: resp.data.isFavourite
                };
                props.updateFavourite();
                setDetails(updatedDetails);
            }
        } else {
            history.push(`/login?/collectives/${collectiveId}`)
        }
    };



    const toggleExhibitionFavourite = async (exhibitionId) => {
        if (props.globalProps.isLoggedIn) {
            let resp = await favouriteExhibition(exhibitionId)
            if (resp.data) {
                let newExhibitions = [];
                exhibitions.map(exhibition => {
                    if (exhibition.data.id === exhibitionId) {
                        exhibition = {
                            ...exhibition,
                            data: {
                                ...exhibition.data,
                                isFavourite: resp.data.isFavourite
                            }
                        }
                    }
                    newExhibitions.push(exhibition)
                    return exhibition;
                })
                setExhibitions(newExhibitions)
            }
        } else {
            history.push(`/login?/galleries/${exhibitionId}`)
        }
    }

    return (
        (isLoadingDetails === false) ?
            <div>
                <HeadTags
                    title={details.name}
                    description={details.shortDescription}
                    image={constructImageUrl(details.image,"350")}
                    type={'collectives'}
                    id={details.id}
                />
                <div className='container'>
                    <DetailsCard
                        id={details.id}
                        type="collective"
                        slug={details.slug}
                        title={details.name}
                        imgSrc={details.image && details.image.data && [constructImageUrl(details.image,"820"), constructImageUrl(details.image,"820",false)]}
                        description={details.description}
                        isFavourite={details && details.isFavourite}
                        toggleFavourite={(id) => toggleFavourite(id)}
                    />
                </div>
                
                {
                    articles && articles.length > 0 && 
                    <ScrollAnimation>
                            <div className='container'>
                                <div className="articles-wrapper" ref={articlesRef}>
                                    <DetailsHeader title="Collateral Content"/>
                                    <div className="articles">
                                        {
                                            articles.map((article, index) => {
                                                const {title, author, writtenDate, id} = article.data;
                                                return (
                                                    <React.Fragment key={index}>
                                                        <ArticleBox
                                                            articleTitle={title}
                                                            id={id}
                                                            authorName={author} date={writtenDate}/>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                    </ScrollAnimation>
                }
                {
                    isLoadingGalleries === false &&
                    <ScrollAnimation>
                <div className="container collective-details-wrapper">
                        <DetailsHeader  CardsView={CardsView} CollectivesCards={CollectivesCards} className="inline-arrows" title="Galleries" position=" "
                                       handleLeftClick={() => handleScrollLeft()}
                                       handleRightClick={() => handleScrollRight()}/>

                    <div className="cards-container-wrapper">
                     {
                        galleries.length > 0 ?
                          <div className="cards-container" ref={CardsView} onScroll={(e) => handleScroll(e)}>
                                  <div className="cards" ref={CollectivesCards}>
                                      {
                                              <>
                                                  {galleries.map((gallery: any, index) => {
                                                      const {id, name, image, locations, overview} = gallery.data;
                                                      return (
                                                          <Card
                                                              imgSrc={image && image.data && [constructImageUrl(image,"720") , constructImageUrl(image,"720",false)]}
                                                              title={name}
                                                              description={overview}
                                                              location={locations}
                                                              noFade
                                                              className={index + 1 === galleries.length ? 'last' : ''}
                                                              onClick={() => handleGalleryClick(id)}
                                                          />
                                                      )
                                                  })
                                                  }
                                                  {/*TODO: We are going to use this back soon.*/}
                                                  {/*{*/}
                                                  {/*    cardsScrollLeft && (*/}
                                                  {/*        <div className="arrow prev" onClick={handleScrollLeft}>*/}
                                                  {/*            <img src={Forward} alt="backword"/>*/}
                                                  {/*        </div>*/}
                                                  {/*    )*/}
                                                  {/*}*/}
                                                  {/*{*/}
                                                  {/*    collectivesCardsWidth > cardsViewWidth && (*/}
                                                  {/*        <div className="arrow" onClick={handleScrollRight}>*/}
                                                  {/*            <img src={Forward} alt="forward"/>*/}
                                                  {/*        </div>*/}
                                                  {/*     )*/}
                                                  {/*}*/}
                                              </>
                                      }
                                  </div>
                          </div>
                                : <Paragraph value="No Galleries Found."/>
                            }
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                      </div>
                    </ScrollAnimation>
                }

                {
                    isLoadingExhibitions === false &&
                    <div className='container collective-details-wrapper'>
                      <ScrollAnimation>
                        <DetailsHeader title={"Exhibitions"}/>
                        <div className="collective-details-exhibitions">
                            {exhibitions.length > 0 ?
                                exhibitions.map(exhibition => {
                                    const {id, slug, name, image, startedAt, endedAt, organisation, isFavourite, uuid, isDateVisible} = exhibition.data;
                                    return (
                                        <React.Fragment key={id}>
                                            {/*TODO: Need to handle Favourites here */}
                                            <Exhibition
                                                id={id}
                                                slug={slug}
                                                uuid={uuid}
                                                onClick={() => handleExhibitionClick(slug || uuid)}
                                                exhibitionImg={image && [constructImageUrl(image,"1920"), constructImageUrl(image,"1920")]}
                                                title={name}
                                                galleryName={organisation && organisation.data.name}
                                                startedAt={startedAt}
                                                endedAt={endedAt}
                                                noScroll={false}
                                                isFavourite={isFavourite}
                                                toggleFavourite={toggleExhibitionFavourite}
                                                locations={organisation && organisation.data.locations}
                                                isDateVisible={isDateVisible}
                                                />
                                        </React.Fragment>
                                    )
                                })
                                : <Paragraph value="No Exhibitions Found."/>
                            }
                        </div>
                      </ScrollAnimation>
                    </div>
                }
            </div> :
            <Spinner/>
    )
}

export default CollectiveDetails;


