// @ts-nocheck
import React, { Component, useCallback, useEffect, useRef, useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import styles from './ChatBox.styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { SendBirdAction } from '../NewChat/sendbird/SendBirdAction';
import { initSync } from '../NewChat/sendbird/main';
// import CloseIcon from '@material-ui/icons/Close';
// import RemoveIcon from '@material-ui/icons/Remove';
import {getNamesFromChat} from '../../services/sendbird/utils'

// import AddIcon from '@material-ui/icons/Add';
// import CmsSend from '../../../icons/CmsSend.icon';
import SendBirdSyncManager from 'sendbird-syncmanager';
// import AttachFileIcon from '@material-ui/icons/AttachFile';
import InboxComp from '../../components/Inbox';
import logger from '../../utils/logger';

const sendbirdAction = SendBirdAction.getInstance();

class ChatBoxContainer extends Component {
    // constructor(props) {
    //   super(props);
    //   this.fetchArtworkDetails = this.fetchArtworkDetails.bind(this);
    // }

    state = {
        isOpen: true,
        isFirstLoad: true,
        isArtDialogOpen: false,
        collection: null,
        selectedFile: null,
        smsTextValue: '',
        messages: [],
        artworkDetail: {},
        collectionHandler: null,
        editor: null,
        artworkId: null,
        channel: null,
        isShown: true
    };

    componentDidMount() {
        const self = this;
        const {userId, name} = this.props;
        logger.debug('this.props.channel = ', userId, name)
        logger.debug('nextProps.enquireMessage = ', this.props.enquireMessage);

        initSync(userId.toString(), name, '', 'vip', () => {
            // let chatLeft = new ChatLeftMenu(self.handleChannelEvent);
            // chatLeft.loadGroupChannelList(true);
            this._initElement(this.props.channel);
            // self.loadChat('Tabasco', 'sendbird_group_channel_11520_714c46480854417942db741f16a2fb9886d8374f');
            // console.log('chatLeft = ', chatLeft)
        });
        // console.log('this.props = ', this.props.channel);
        // this._initElement();
    }

    handleArtwordDetails = (details) => {
        // this.setState({ artworkDetail: details }, () => {
        //   // console.log('details = ', details.thumbnailUrl)
        //   let artworkId = details.id;
        //   if (document.getElementById(artworkId)) {
        //     let editor = new ObjectViewer(artworkId, details.thumbnailUrl.signedUrl, null);
        //     this.setState({ editor: editor });
        //     window.addEventListener('resize', this.sceneResizeListener);
        //     return this.cleanUpThreeJSView;
        //   }
        // });
    };

    fetchArtworkDetails = async (id) => {
        // try {
        //   const result = await axios.get(`${ROOT_URL}/user/artworks/${id}`).catch((err) => {
        //     console.log('err = ', err);
        //     if (err && err.response && err.response.status === 400) {
        //       return Promise.reject(new Error('Request failed with status code 400'));
        //     }
        //     return Promise.reject(new Error(JSON.stringify(err.response.data)));
        //   });
        //   if (result) {
        //     this.handleArtwordDetails(result && result.data && result.data.data);
        //   }
        // } catch (e) {
        //   return e;
        // }
    };

    toggleArtworkDialog = (artworkId) => {
        const { isArtDialogOpen } = this.state;
        logger.debug('artworkId = ', artworkId);
        this.setState(
            (prevState) => {
                return {
                    isArtDialogOpen: !prevState.isArtDialogOpen,
                    artworkId: artworkId,
                };
            },
            () => {
                if (isArtDialogOpen === false) {
                    this.fetchArtworkDetails(artworkId);
                } else {
                    setTimeout(() => {
                        this.setState({ artworkDetail: {} });
                    }, 1000);
                }
            },
        );
    };

    onScrollToTop = () => {
        this.state.collection.fetchSucceededMessages('prev', (e, v) => {
            // console.log('fetchSucceededMessages = ', e,v);
        });
    };

    cleanUpThreeJSView = () => {
        if (document.getElementById(this.state.artworkId)) {
            window.removeEventListener('resize', this.sceneResizeListener);
            document.getElementById(this.state.artworkId).innerHTML = null;
        }
    };

    sceneResizeListener = () => {
        const { editor } = this.state;
        if (editor) {
            editor.resize();
        }
    };

    //   useEffect(() => {
    //   if (modelUrl) {
    //     setFile(modelUrl);
    //   }
    //   if (document.getElementById(artworkId)) {
    //   let editor = new ObjectViewer(artworkId, file, null);
    //   setEditor(editor);
    //   window.addEventListener('resize', sceneResizeListener);
    //   return cleanUpThreeJSView;
    // }
    // }, [artworkId, cleanUpThreeJSView, file, modelUrl, sceneResizeListener, style]);
    //
    // const sceneResizeListener = useCallback(() => {
    //   if (editor) {
    //     editor.resize();
    //   }
    // });

    componentWillUnmount() {
        // console.log('componentWillUnmount = ', this.state.collectionHandler);
        logger.debug('componentWillUnmount = ', this.state.collection);
        this.state.collection && this.state.collection.removeCollectionHandler();
        this.state.collection && this.state.collection.remove();
        this.state.collectionHandler && this.state.collectionHandler.remove && this.state.collectionHandler.remove();
        this.state.collectionHandler && this.state.collectionHandler.removeCollectionHandler && this.state.collectionHandler.removeCollectionHandler();
    }

    loadChat = async (eventTitle, channelURL) => {
        logger.debug('eventTitle, channelURL = ', eventTitle, channelURL)
        // const sb = new SendBirdAction();
        // const {setCurrentChannel} = this.props;
        let channel = await sendbirdAction.getGroupChannel(channelURL);
        logger.debug('channel = ', channel)
        this.setState({
            channel
        })
        this._initElement(channel);
        // setCurrentChannel(channel);
    };

    _initElement = (channel) => {
        const { _messageEventHandler, _onNewMessageEventHandler } = this;
        const { collection, messages } = this.state;
        // const { channel } = this.state;
        const self = this;
        logger.debug('channel = ', channel);
        if (collection) {
            self.state.collection.remove();
            self.state.collection.removeCollectionHandler();
        }
        const _collection = new SendBirdSyncManager.MessageCollection(channel);
        _collection.limit = 10;
        _collection.reverse = true;

        logger.debug('initElement sendbirdAction = ', sendbirdAction);
        logger.debug('initElement _collection = ', _collection);
        // var listQuery = channel.createPreviousMessageListQuery();
        // // listQuery.limit = 20;
        // listQuery.reverse = true;
        //
        // listQuery.load(function(messageList, error) {
        //   console.log('messageList, error = ', messageList, error)
        //   if (error) {
        //     // Handle error.
        //   }
        //
        // });

        this.setState(
            {
                collection: _collection,
            },
            () => {
                self.state.collection.fetchSucceededMessages('prev', () => {
                    // console.log('fetchSucceededMessages = ');
                });

                // console.log('_collection = ', _collection);
                self.setState(
                    {
                        collectionHandler: new SendBirdSyncManager.MessageCollection.CollectionHandler(),
                    },
                    () => {
                        self.sendEnquireMessage(self.props.enquireMessage);
                        logger.debug('initElement collectionHandler = ', self.state.collectionHandler);
                        // collectionHandler =
                        self.state.collectionHandler.onSucceededMessageEvent = _messageEventHandler;
                        // collectionHandler.onFailedMessageEvent = _messageEventHandler;
                        // collectionHandler.onPendingMessageEvent = _messageEventHandler;
                        self.state.collectionHandler.onNewMessage = (event) => {
                            _onNewMessageEventHandler(event, self.state.collection);
                        };
                        _collection.setCollectionHandler(self.state.collectionHandler);
                        // if(self.props.enquireMessage) {
                        //     self.sendEnquireMessage(self.props.enquireMessage);
                        // }
                    },
                );
            },
        );
    };

    _onNewMessageEventHandler = (event, col) => {
        const messages = col.messages;
        const self = this;
        let isOnNewMessage = !messages.every((message) => message.messageId !== event.messageId);
        // console.log('_onNewMessageEventHandler = ', messages);
        // console.log('_onNewMessageEventHandler = ', isOnNewMessage, event);
        // if (isOnNewMessage) {
        //   self.setState((prevState) => {
        //     // console.log('prevState = ', prevState.messages);
        //     return {
        //       // messages: mergeArrays(prevState.messages, _messages.reverse().map((message) => formatMessage(message))),
        //       messages: [...prevState.messages, self.formatMessage(event)],
        //       isFirstLoad: false,
        //     };
        //   });
        // }
    };

    _messageEventHandler = (_messages, action, reason) => {
        const self = this;
        // const keepScrollToBottom = this.element.scrollTop >= this.element.scrollHeight - this.element.offsetHeight;
        // messages.sort((a, b) => a.createdAt - b.createdAt);
        const { messages } = this.state;
        // console.log('new messages = ', _messages);
        // console.log('old messages = ', messages);
        // console.log('action = ', action);
        const { formatMessage } = this;
        // console.log('reason = ', reason);
        // console.log(
        //   '_messageEventHandler action = ',
        //   action,
        //   [..._messages].map((message) => message.message),
        // );
        // console.log('_messageEventHandler = ', action, _messages);
        switch (action) {
            case 'insert': {
                logger.debug('insert = ', _messages);
                self.setState((prevState) => {
                    let isOnNewMessage = false;
                    if (_messages.length === 1) {
                        isOnNewMessage = prevState.messages.every((message) => message.messageId !== _messages[0].messageId);
                    }
                    if(isOnNewMessage) {
                        self.toggleScroll(true)
                    }

                    // if (prevState.isFirstLoad) {
                    logger.debug('isOnNewMessage = ', isOnNewMessage, prevState.messages);
                    return {
                        // messages: mergeArrays(prevState.messages, _messages.reverse().map((message) => formatMessage(message))),
                        // messages: isOnNewMessage ? [..._messages.map((message) => formatMessage(message)), ...prevState.messages, ] : [...prevState.messages, ..._messages.map((message) => formatMessage(message))],
                        messages: isOnNewMessage
                            ? [...prevState.messages, ..._messages.reverse().map((message) => formatMessage(message))]
                            : [..._messages.reverse().map((message) => formatMessage(message)), ...prevState.messages],

                        isFirstLoad: false,
                    };
                    // }
                });
                break;
            }
            case 'update': {
                // TODO: Duplicating messages on read from mobile app.
                // - Send message form here to mobile, Open mobile chat,
                // it updates the read status, this event gets emitted, and it pushes the message in array :/
                logger.debug('update = ', _messages);
                // self.setState((prevState) => {
                //   return {
                //     messages: [...prevState.messages, ..._messages.reverse().map((message) => formatMessage(message))],
                //   };
                // });
                break;
            }
            // case 'remove': {
            //   this._removeMessages(messages);
            //   break;
            // }
            // case 'clear': {
            //   this._clearMessages();
            //   break;
            // }
            default:
                break;
        }
    };

    renderTitle = () => {
        let title = [];
        const { classes, channel, userId } = this.props;
        // const {  } = this.state;
        channel.members.map((member) => {
            if (member.userId !== userId.toString()) {
                title.push(member.nickname.toLocaleUpperCase());
            }
        });
        return (
            <Typography className={classes.title} variant="body2" title={[title.join(', ')]}>
                {title.join(', ')}
            </Typography>
        );
    };

    checkIfSystemMessage = (message) => {
        const regex = /^@.*@$/;
        const isSystem = regex.test(message);
        const reply = isSystem ? message && message.replace(/@/g, '') : message;
        return {
            message: reply,
            isSystemMessage: isSystem,
        };
    };

    formatMessage = (message) => {
        const {userId} = this.props;
        const data = this.checkIfSystemMessage(message.message);
        // console.log('data = ', message);
        return {
            isMyMessage: message._sender.userId === userId.toString(),
            senderName: message._sender.nickname,
            profileUrl: message._sender.plainProfileUrl,
            messageType: message.messageType,
            type: message.type,
            plainUrl: message.plainUrl,
            // isSystemMessage: checkIfSystemMessage(message.message),
            // message: message.message,
            messageId: message.messageId,
            createdAt: message.createdAt,
            ...data,
        };
    };

    componentWillReceiveProps(nextProps) {
        logger.debug('nextProps.enquireMessage = ', nextProps.enquireMessage, this.props.enquireMessage, nextProps.enquireMessage !== this.props.enquireMessage && !!nextProps.enquireMessage)
        if(nextProps.enquireMessage !== this.props.enquireMessage && !!nextProps.enquireMessage) {
            this.sendEnquireMessage(nextProps.enquireMessage);
            // console.log('componentWillReceiveProps = ', nextProps.enquireMessage , this.props.enquireMessage)
        }
    }

    sendEnquireMessage = (enquireMessage) => {
        const {channel, setEnquireMessage} = this.props;
        const {collection, messages, isLoading} = this.state;
        let oldArtworkId = null;
        // debugger;
        const newArtworkId = retrieveArtworkIdFromSystemMessage(enquireMessage);

        const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;
        if(lastMessage) {
            oldArtworkId = retrieveArtworkIdFromSystemMessage(lastMessage.message);
        }
        logger.debug('sendEnquireMessage = ', lastMessage, newArtworkId,oldArtworkId)
        const self = this;
        if((!!enquireMessage && !!channel && oldArtworkId && !newArtworkId.includes(oldArtworkId)) || lastMessage === null) {
            let pendingMessage = sendbirdAction.sendUserMessage({
                channel,
                message: enquireMessage,
                handler: (message, error) => {
                    collection.handleSendMessageResponse(error, message);
                    sendbirdAction.markAsRead(channel);
                    setEnquireMessage(null)
                    logger.debug('sendEnquireMessage = ', message, error);
                    self.setState({
                        // messages: [...messages, self.formatMessage(message)],
                        smsTextValue: '',
                        disableSend: false,
                    });
                },
            });
            // console.log('pendingMessage = ', pendingMessage);
            collection.appendMessage(pendingMessage);
        }
        else {
            setEnquireMessage(null)
        }
    }

    handleClose = (url) => {
        this.props.onCloseChannel(url);
    };

    handleMinimize = () => {
        this.setState((prevState) => {
            return {
                isOpen: !prevState.isOpen,
            };
        });
    };

    handleInputClick = () => {
        this.markAsRead();
        // const { channel } = this.props;
        // sendbirdAction.markAsRead(channel);
    };

    sendMessage = (type) => {
        const { channel } = this.props;

        logger.debug('sendMessage channel = ', channel)
        // const { channel } = this.props;
        const { smsTextValue, messages, selectedFile, collection } = this.state;
        // console.log('sendMessage = ', type, selectedFile);
        if (type === 'text' && smsTextValue) {
            const { formatMessage } = this;
            const self = this;
            logger.debug('sendMessage = ', smsTextValue, channel);
            let pendingMessage = sendbirdAction.sendUserMessage({
                channel,
                message: smsTextValue,
                handler: (message, error) => {
                    collection.handleSendMessageResponse(error, message);
                    sendbirdAction.markAsRead(channel);
                    logger.debug('sendMessage = ', message);
                    logger.error('sendMessage err = ', error);
                    self.setState({
                        // messages: [...messages, formatMessage(message)],
                        smsTextValue: '',
                    });
                },
            });
            // console.log('pendingMessage = ', pendingMessage);
            collection.appendMessage(pendingMessage);
        }
        if (type === 'file' && selectedFile) {
            const self = this;
            // console.log('sendMessage = ', selectedFile, channel);
            sendbirdAction.sendFileMessage({
                channel,
                file: selectedFile,
                thumbnailSizes: null,
                handler: (message, error) => {
                    // console.log('sendMessage = ', message, error);
                    sendbirdAction.markAsRead(channel);
                    // console.log('sendMessage = ', message, error);
                    self.setState({
                        messages: [...messages, self.formatMessage(message)],
                        smsTextValue: '',
                        selectedFile: '',
                    });
                },
            });
        }
        this.toggleScroll(true);
    };

    handleOnClickArtworkCard = (id) => {
        // this.setState({artworkId: id})
        this.props.setArtworkId(id);
    };


    markAsRead = () => {
        const { channel } = this.props;
        const { unreadMessageCount } = this.props;
        if (unreadMessageCount > 0) {
            // console.log('unreading message');
            SendBirdAction.getInstance().markAsRead(channel);
        }
    };

    handleEnterPress = (e) => {
        if (e.keyCode === 13 && !!e.target.value) {
            this.sendMessage('text');
        }
    };

    onChangeAttachment = (e) => {
        this.markAsRead();
        const self = this;
        if (e.target.files && e.target.files[0]) {
            // console.log('onChangeAttachment = ', e.target.files)
            const file = e.target.files[0];
            this.setState(
                {
                    selectedFile: file,
                    type: 'file',
                },
                () => {
                    self.sendMessage('file');
                },
            );
        }
        return;
    };

    getDimensionsInCM = () => {
        const { artworkDetail } = this.state;
        return `${parseFloat(artworkDetail.height).toFixed(1)} x ${parseFloat(artworkDetail.width).toFixed(
            1,
        )} x ${parseFloat(artworkDetail.depth).toFixed(1)} cm`;
    };

    getDimensionsInIN = () => {
        const { artworkDetail } = this.state;
        return `${(parseFloat(artworkDetail.height) * 0.393701).toFixed(1)} x ${(
            parseFloat(artworkDetail.width) * 0.393701
        ).toFixed(1)} x ${(parseFloat(artworkDetail.depth) * 0.393701).toFixed(1)} in`;
    };

    toggleConversation = () => {
        this.setState((prevState) => {
            return {
                isShown: !prevState.isShown
            }
        })
    }

    toggleScroll = (shouldScroll) => {
        this.setState({
            shouldScroll
        })
    }

    render() {
        const { classes, userId, unreadMessageCount, eventsChat, isCurateChat, onBackClick, channel: {name, members} } = this.props;
        const { isOpen, messages, smsTextValue, selectedFile, type, artworkDetail, isShown, shouldScroll } = this.state;
        const { handleClose, handleInputClick, handleEnterPress, handleMinimize, renderTitle, sendMessage, toggleConversation } = this;
        const hasNewUnreadMessage = unreadMessageCount < 1;
        let [userName, galleryName] = getNamesFromChat(name, members, userId);

        logger.debug('isShown = ', isShown);
        return (
            <InboxComp
                onChangeInputValue={(e) => this.setState({smsTextValue: e, type: 'text'})}
                handleInputClick={this.handleInputClick}
                handleOnClickArtworkCard={this.handleOnClickArtworkCard}
                smsTextValue={smsTextValue}
                onScrollToTop={this.onScrollToTop}
                onChangeAttachment={this.onChangeAttachment}
                handleEnterPress={this.handleEnterPress}
                messages={messages} title={userName} galleryName={galleryName}
                onBackClick={onBackClick}
                onClose={this.handleClose}
                isLoading={false}
                isSendDisabled={false}
                sendMsg={this.sendMessage}
                eventsChat={eventsChat}
                isCurateChat={isCurateChat}
                toggleScroll={this.toggleScroll}
                shouldScroll={shouldScroll}
            />
        );
    }
}

const MessageList = React.memo(({ messages, classes, toggleArtworkDialog, onScrollToTop }) => {
    useEffect(() => {
        logger.debug('scrollToBottom = ', messages.length);
        if (messages.length > 0) {
            scrollToBottom();
        }
    }, [messages.length]);

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        logger.debug('scrolled to bottom called', messagesEndRef.current);
        messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;

        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleMessageScroll = (e) => {
        // const chatWindow = document.getElementById('chat-list');
        // var xH = chatWindow.scrollHeight;
        // chatWindow.scrollTo(0, xH);
        var element = e.target;
        // console.log('scroll - ', element.scrollHeight, element.scrollTop, element.clientHeight, element.scrollHeight - element.scrollTop-1 )
        // if (element.scrollHeight - element.scrollTop-1 < element.clientHeight )
        // {
        //   onScrollToTop()
        //   console.log('scrolled');
        // }

        // console.log('e.target.scrollTop = ', e.target, e.target.scrollTop, chatWindow.scrollBottom, chatWindow.scrollHeight, chatWindow.scrollTop, chatWindow.offsetHeight, chatWindow.maxScrollTop)
        if (e.target.scrollTop === 0) {
            onScrollToTop();
        }
    };

    // console.log('messages = ', messages);
    return (
        <div id={'chat-window'} className={classes.chatListWrapper}>
            <ul id={'chat-list'} className={classes.message_outer} onScroll={handleMessageScroll}>
                {messages.map((message) => {
                    if (message.isSystemMessage) {
                        return <RenderSystemMessage onClick={toggleArtworkDialog} classes={classes} message={message} />;
                    } else if (message.isMyMessage) {
                        return <MyMessage classes={classes} message={message} />;
                    } else {
                        return <OtherMessage classes={classes} message={message} />;
                    }
                })}
                <div ref={messagesEndRef} />
            </ul>
        </div>
    );
});

const RenderSystemMessage = ({ classes, message, onClick }) => {
    const splittedData = message.message.split('!,!');
    // console.log(message);

    const draft = {
        data: {
            id: splittedData[5],
            title: splittedData[1],
            yearCreated: splittedData[2],
            medium: splittedData[3],
            status: '',
            isTouched: true,
            isPublished: true,
            artist: {
                data: {
                    id: 1,
                    name: splittedData[0],
                },
            },
            image: {
                data: {
                    aspectRatio: 2.0,
                },
            },
        },
    };
    //   return <ArtworkCard artwork={draft} showBanner={<img src={splittedData[4]} />} preview />;
    // =======
    return (
        <div className={classes.cardWrapper} onClick={() => onClick(splittedData[5])}>
            <img className={classes.cardinnerimage} src={splittedData[4]} />
            <Typography className={classes.artisttitle} variant={'h4'}>
                {splittedData[0] || ''}, {splittedData[1] || ''}
            </Typography>
            <Grid container direction={'column'} justify={'center'}>
                <Typography className={classes.year} variant={'overline'}>
                    {splittedData[2] || ''}
                </Typography>
                <Typography className={classes.artistcollection} variant={'overline'}>
                    {splittedData[3] || ''}
                </Typography>
            </Grid>
        </div>
    );
    // >>>>>>> 11a61c5836c3e86d6e4046fa8c2c4bbfb9ab7f1c
};

const MyMessage = ({ classes, message }) => {
    // console.log('MyMessage = ', message);
    return (
        <li className={classes.message_recvie}>
            {message.profileUrl ? (
                <img src={message.profileUrl} className={classes.recive_profile_icon} />
            ) : (
                <span className={classes.recive_profile_icon} />
            )}
            {message.messageType === 'file' && message.plainUrl ? (
                <div className={classes.message_file_sent}>
                    {message.type.includes('video') ? (
                        <video className={classes.mediaMessage} controls src={message.plainUrl} />
                    ) : (
                        <img className={classes.mediaMessage} src={message.plainUrl} />
                    )}
                </div>
            ) : (
                <Typography variant="body2" gutterBottom className={classes.recive_message_text}>
                    {message.message}
                </Typography>
            )}
            {/*<Typography variant="body2" gutterBottom className={classes.}>*/}
            {/*  {message.message}*/}
            {/*</Typography>*/}
            {/*<Typography variant='body2' gutterBottom className={classes.sender_name}>yo</Typography>*/}
        </li>
    );
};

const OtherMessage = ({ classes, message }) => {
    return (
        <li className={classes.message_sent}>
            {message.profileUrl ? (
                <img src={message.profileUrl} className={classes.sent_profile_icon} />
            ) : (
                <span className={classes.sent_profile_icon} />
            )}
            {message.messageType === 'file' && message.plainUrl ? (
                <div className={classes.message_file_received}>
                    {message.type.includes('video') ? (
                        <video className={classes.mediaMessage} src={message.plainUrl} />
                    ) : (
                        <img className={classes.mediaMessage} src={message.plainUrl} />
                    )}
                </div>
            ) : (
                <Typography variant="body2" gutterBottom className={classes.message_text}>
                    {message.message}
                </Typography>
            )}
            <Typography variant="body2" gutterBottom className={classes.sender_name}>
                {message.senderName}
            </Typography>
        </li>
    );
};

const retrieveArtworkIdFromSystemMessage = (message) => {
    if(message) {
        const splittedData = message.split('!,!');
        return splittedData && splittedData[5];
    }
    return null;
}

export default withStyles(styles)(ChatBoxContainer);
