import React from "react";
import { Title2 } from "../Typography2";
import SvgHeader from "../SvgHeader";
import Button from "../Button";
import {useHistory} from "react-router-dom";
import Wrong from '../../assets/font-svgs/Wrong.svg'

const NotFound = () => {
    const history = useHistory();
    const email=history.location.state
    return (
        <div className={'container not-found-box'}>
            <SvgHeader type="Opps" noAnimate={true} />
            <div className={'error-wrong'}>
                <img src={Wrong} />
            </div>
            <Title2 value={email? `You are already login with this email ${email}` : 'Please bear with us while we take a look under the hood. In the meantime there’s more works to discover....'}/>
            <Button
                value='Take me to Discover'
                className='button__bright btn-collective'
                onClick={() => {
                    history.push('/discover')
                } }
            />
        </div>
    )
}

export default NotFound;
