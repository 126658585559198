import React from "react";
import { Paragraph } from "../Typography2";
import { constructImageUrl } from "../../utils/index";
import "./style.scss";

const ArtworksList = ({
  artworks,
  handleArtworkClick,
  roomId,
  isOldExhibition,
}) => {
  return (
    <div className="artworks-list-wrapper">
      <div className="boxes-wrapper">
        <div className={`boxes`}>
          {artworks?.length > 0 &&
            artworks
              .filter(
                (artwork) =>
                  !artwork.data.isCollateral &&
                  (isOldExhibition ||
                    parseInt(roomId) === artwork.data.exhibitionSceneId)
              )
              .map((item, index) => {
                return (
                  <div className="border" key={index}>
                    <div
                      className="box"
                      key={item.data.id}
                      onClick={() => handleArtworkClick(item.data.id)}
                    >
                      <div className="img">
                        {item.data.type === "2d" ? (
                          <img
                            src={constructImageUrl(item?.data?.image, "300")}
                            alt="artwork img"
                          />
                        ) : item.data.type === "3d" ? (
                          <img
                            src={constructImageUrl(
                              item?.data?.thumbnail,
                              "300"
                            )}
                            alt="artwork img"
                          />
                        ) : (
                          <img
                            src={constructImageUrl(
                              item?.data?.videoThumbnail,
                              "300"
                            )}
                            alt="artwork img"
                          />
                        )}
                      </div>
                      <div className="details">
                        <Paragraph
                          className="description"
                          value={item.data?.artist?.data?.name}
                        />
                        <Paragraph
                          className="title artwork-title"
                          value={item.data?.title}
                        />
                      </div>
                      <div className="hover-on-animate"></div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default ArtworksList;
