import React from 'react';
import { P1 } from '../Typography';
import { IReceivedProps } from './types';
import ImageLoader from "../ImageLoader";

const Message : React.FC<IReceivedProps> = ({isFirst, profileUrl, isLoading, isFile, type, imgUrl, msg, senderName, time, isMyMsg, isSeen, hideStatus }) => {
    return (
        <div className={`msg-wrapper ${isMyMsg ? 'my' : ''} ${isFirst ? 'first' : ''} ${isSeen ? 'seen' : ''} ${hideStatus ? 'hide-status' : ''}`}>
            <div className={`msg`}>
                {!isMyMsg && <div className="msg__profile">
                    {profileUrl && <img src={profileUrl} alt='profile-image' />}
                </div>}
                <div className={`${isFile ? 'msg__file' : '' } msg__box`}>
                    {
                        isFile ?
                        type.includes('video') ?
                            <video style={{width: '100%', height: '100%'}} controls src={imgUrl} />
                            :
                            <ImageLoader isOverlayed={!!isLoading} imgUrls={[imgUrl]} />
                            :
                            <P1 value={msg} />

                    }
                </div>
            </div>
            <div className='msg-details'>
                {time && <P1 className='time' value={time} />}
                {senderName && <P1 className='name' value={senderName} />}
                {isMyMsg && <svg className='msg-check' width="8" height="6" viewBox="0 0 8 6" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.71715 5.7514L0.117152 3.239C-0.0390508 3.08806 -0.0390508 2.84332 0.117152 2.69237L0.682825 2.14574C0.839028 1.99478 1.09231 1.99478 1.24851 2.14574L3 3.8382L6.75149 0.21312C6.90769 0.0621794 7.16097 0.0621794 7.31718 0.21312L7.88285 0.759749C8.03905 0.91069 8.03905 1.15542 7.88285 1.30638L3.28284 5.75142C3.12662 5.90236 2.87336 5.90236 2.71715 5.7514Z" />
                </svg>}
            </div>
        </div>
    )
};

export default Message;
