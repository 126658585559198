import React from 'react';
import ReactDOM from 'react-dom';
import packageJson from '../package.json';
import logger from './utils/logger'
import App from "./App";

logger.info("Version: ", packageJson.version);

ReactDOM.render(
  <App/>,
  document.getElementById("root")
);
