import React, { useState } from 'react'
import "./NewsLetterPopup.scss";
import {submitNewsLetter} from '../../../../services/api'
import Send from '../../../../assets/icons/send-white.svg';
import Modal from '../../../../components/Modal';
import {Heading, Paragraph} from "../../../../components/Typography2";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import rudderStackEvents from "../../../../services/rudderstack-events";

export default function NewsLetterPopup({
    handleClose,
    setCookie,
    isNewsLetterSubmited
}) {
    const [state, setState] = useState({
        name:"",
        email:""
    })
    const [nameErr, setNameErr] = useState(false)
    const [emailErr, setEmailErr] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const [isHiding, setIsHiding] = useState(false)

    const handleSubmit = async (e) => {
        if(!state.name) {
            setNameErr(true)
        }
        else {
            setNameErr(false)
        }
        if(!state.email) {
            setEmailErr(true)
        }
        else if(!validateEmail(state.email)){
            setEmailErr(true)
        }
        else {
            setEmailErr(false)
        }
        if(!state.name || !state.email || !validateEmail(state.email)){
            return;
        }
        setIsSubmit(false);
        try {
            rudderStackEvents.onJoiningMailingList('join_mailing_list')
            const resp = await submitNewsLetter(state)
            if (resp.success) {
                setIsHiding(true);
                setCookie();
            } else {
            }
            setIsSubmit(false);
        } catch (e) {
            setIsSubmit(false);
        }
    }
    const handleChange = ({target:{name, value}}) => {
        if(name === "name") {
            setNameErr(false)
        }
        else if(name === "email") {
            setEmailErr(false)
        }
        setState(prevState => ({
            ...prevState,
            [name]:value
        }))
    }
    function validateEmail(email)
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    const handleCloseModel = () => {
        setCookie();
        handleClose();
    }
    return (
        <Modal onClose={handleCloseModel} closeBtn={!isNewsLetterSubmited} className={'details-newsLatter'}>

            <div
                className={`newsLetterDetailPage`}
            >
                <div className={'newsLetter-top'}>
                    <Heading value={isNewsLetterSubmited ? "Thanks for subscribing." : "Enjoyed the exhibition?"}/>
                    <Paragraph value={
                        isNewsLetterSubmited ?
                        "Keep an eye on your inbox for updates on new exhibitions, talks, and exclusive content."
                        :
                        "Join our mailing list today to learn about new exhibitions and upcoming Vortic Sessions from the world’s best institutions."}
                    />
                </div>
                {
                    !isNewsLetterSubmited &&
                    <>
                        <div className="inputSection">
                            <Input
                                label={'Name'}
                                name="name"
                                active={true}
                                important={true}
                                errorMsg={nameErr}
                                onChange={handleChange}
                                className={``}
                                placeholder="Enter name"
                                type="text"
                            />
                            <Input
                                label={'Email address'}
                                important={true}
                                active={true}
                                name="email"
                                errorMsg={emailErr}
                                onChange={handleChange}
                                className={``}
                                placeholder="Enter email"
                                type="email"
                            />
                        </div>

                    </>
                }
                <div className="buttonContainer">
                    {

                        isNewsLetterSubmited ?
                        <Button
                            value={'Close'}
                            iconType={"cross-right"}
                            className={"newLetterButton"}
                            onClick={()=>{handleClose()}}
                        />
                        :
                        <Button
                            value={'Submit'}
                            iconType={"submit"}
                            disable={!(!!state.name && !!state.email && validateEmail(state.email))|| isSubmit}
                            className={"newLetterButton"}
                            onClick={handleSubmit}
                        />
                    }
                    {
                        !isNewsLetterSubmited &&
                        <a href="#" onClick={(e) => {e.preventDefault(); handleCloseModel()}}>
                            Don't show this message again
                        </a>
                    }
                </div>
            </div>

        </Modal>
    )
}
