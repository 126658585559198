import React, { Component } from 'react';
import MyContext from './MyContext';
import { initSync } from '../sendbird/main';
import {ChatLeftMenu} from '../sendbird/ChatLeftMenu';
import logger from '../../../utils/logger';
export default class MyProvider extends Component {
  state = {
    channels: [],
    activeChannel: null,
    totalUnreadMessages: 0,
  };

  addActiveChannel = (activeChannel) => {
    this.setState({
      activeChannel
    })
  }

  setChannels = (channels) => {
    function compare(a, b) {
      if (a.lastMessage.createdAt > b.lastMessage.createdAt) {
        return -1;
      }
      if (a.lastMessage.createdAt < b.lastMessage.createdAt) {
        return 1;
      }
      return 0;
    }
    let totalUnreadMessages = 0;
    channels.map((channel) => {
      totalUnreadMessages += channel.unreadMessageCount;
    });
    this.setState({
      channels: channels.sort(compare),
      totalUnreadMessages,
    });
  };

  handleChannelsUpdate = ({ action, channels: _channels }) => {
    // console.log('action = ', action, _channels);
    const { channels } = this.state;
    const { setChannels } = this;
    if (action === 'insert') {
      setChannels([...channels, ..._channels]);
    } else if (action === 'forceInsert' && channels.length === 0) {
      setChannels([...channels, ..._channels]);
    } else if (action === 'update' || action === 'move') {
      let updateChannels = [...channels].map((channel) => {
        _channels.map((_channel) => {
          if (channel.url === _channel.url) {
            channel = _channel;
          }
        });
        return channel;
      });
      setChannels(updateChannels);
    }
  };

  componentDidMount() {
    const { user } = this.props;
    const self = this;
    if (user.role === 'organisation admin' || user.role === 'vortic admin') {
      // initSync(
      //   user.organisation.id.toString(),
      //   user.name,
      //   user.image && user.image.data.signedUrl120,
      //   user.role.replace(' ', '_'),
      // );
    } else {
      initSync(user.id.toString(), user.name, user.image && user.image.data.signedUrl120, user.role.replace(' ', '_'), () => {
        let chatLeft = new ChatLeftMenu(self.handleChannelEvent);
        chatLeft.loadGroupChannelList(true);
      });
    }
  }

  handleChannelEvent = (action, channels) => {
    this.setState((prevState) => {
      return {
        channels: action === "insert" ? [...prevState.channels, ...channels] : prevState.channels
      }
    })
  }

  render() {
    return (
      <MyContext.Provider
        value={{
          addActiveChannel: this.addActiveChannel,
          channels: this.state.channels,
          activeChannel: this.state.activeChannel,
          totalUnreadMessages: this.state.totalUnreadMessages,
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    );
  }
}
