import { isMobileDevice } from './../../../utils/index';
import {LOCAL_STORAGE_KEYS} from '../../../components/Constants'
import {
    LOGIN,
    CHANGE_SEARCH,
    SET_REDIRECT_PATH,
    SET_CONVERSATION,
    SET_VIEW_HEIGHT,
    SET_FIRST_LOAD,
    SET_ARTWORK_ID,
    SET_IS_ERROR,
    SET_ARTWORKS_IDS,
    SET_EXHIBITION_ID,
    SET_IS_MOBILE_FORM
} from './actionTypes';
import logger from '../../../utils/logger';
import MobileDetect from 'mobile-detect';

const getDevice = () => {
    let md = new MobileDetect(window.navigator.userAgent)
    logger.debug(md.mobile());          // 'Sony'
    logger.debug(md.phone());           // 'Sony'
    logger.debug(md.tablet());          // null
    logger.debug(md.userAgent());       // 'Safari'
    logger.debug(md.os());              // 'AndroidOS'
    logger.debug(md.is('iPhone'));      // false
    logger.debug(md.is('bot'));         // false
    logger.debug(md.version('Webkit'));         // 534.3
    logger.debug(md.versionStr('Build'));       // '4.1.A.0.562'
    logger.debug(md.match('playstation|xbox')); // false
    return md;
};

const initialState = {
    isLoggedIn: !!window.localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN),
    searchQuery: '',
    redirectPath: '',
    device: getDevice(),
    isConversation: false,
    viewHeight: false,
    isFirstLoad: false,
    artworkId: null,
    exhibitionId: null,
    artworkIds: [],
    isError: false,
    errorMessage: '',
    isMobileForm: false
};

function getLogin(state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            return {...state, isLoggedIn: true};

        case CHANGE_SEARCH:
            return {...state, searchQuery: action.payload};

        case SET_REDIRECT_PATH:
            return {...state, redirectPath: action.payload};

        case SET_CONVERSATION:
            return {...state, isConversation: action.payload};

        case SET_VIEW_HEIGHT:
            return {...state, viewHeight: action.payload};

        case SET_FIRST_LOAD:
            return {...state, isFirstLoad: action.payload};

        case SET_ARTWORK_ID:
            return {...state, artworkId: action.payload};

        case SET_EXHIBITION_ID:
            return {...state, exhibitionId: action.payload};

        case SET_ARTWORKS_IDS:
            return {...state, artworkIds: action.payload};

        case SET_IS_ERROR:
            return {...state, isError: action.payload.value, errorMessage: action.payload.message};

        case SET_IS_MOBILE_FORM:
            return {...state, isMobileForm: action.payload};

        default:
            return state
    }
}

export default getLogin
