// @ts-nocheck

// import RenderView from '../Renderer/RenderView.ts'
//import RenderView from 'vortic-curate'
//import RenderView from '../Renderer/RenderView.js'
// import RenderView from '../../../../vortic-curate/src/js/main.js'
//import { RenderView } from '@superbright/voritc-curate/build/';
//import RenderView from '/Users/igaln/Development/_Vortic/_DEVELOPMENT/vortic-curate/build/index.js'

// import { RenderView } from '/Users/dexter/super-bright/vortic-curate/src/js/main'
import { RenderView } from '@superbright/voritc-curate/build'
// import { RenderView } from '/Users d/igal/Code/vortic-curate/build/'

import {isMobileDevice, isAndroidDevice} from '../../utils';
import logger from "../../utils/logger";


// TODO: ongoings code cleanup & refactors
export default class GalleryView {
    constructor(target_id, galleryFiles, loadingProgress, toggleViewMode, userId, onVideoStateChange, closeDetails, onGuidedModeArtworkChange, onHoverArtworkChanged, onSeenArtworkChanged, onDoneCurateLoading) {
        const config = {
			doubleClickForArtworkDetails: isMobileDevice(),
            useUnsignedByteForHDR: isAndroidDevice(),
            isDemoMode: false,
            animation_props: galleryFiles.animation_props,
            isLockedInDetailsMode: galleryFiles.isLockedInDetailsMode === 1,
            // guidedModeSwipeEnabled: !isMobileDevice(),
        }
     // logger.log('config = ', config)
        logger.log('galleryFiles = ', galleryFiles)
        this.renderView = new RenderView(target_id, galleryFiles, loadingProgress, document.getElementById('hiddenVideoElement'), config)
        this.renderView.onSelectArtwork = this.onSelectArtwork.bind(this)
        this.renderView.onToggleViewMode = this.onToggleViewMode.bind(this)
        this.renderView.onHoverArtworkChanged = onHoverArtworkChanged
        this.renderView.onSeenArtworkChanged = onSeenArtworkChanged
        this.renderView.onGuidedModeArtworkChange = onGuidedModeArtworkChange
		this.renderView.onVideoStateChange = onVideoStateChange
		this.renderView.onDoneLoading = onDoneCurateLoading
        this.toggleViewMode = toggleViewMode
        this.userId = userId
        this.renderView.closeDetails = closeDetails
        this.renderView.setSeenArtworkDetectionEnabled(isMobileDevice())

    }

    setUserId(userId) {
        this.userId = userId
    }

    onToggleViewMode(artworkId) {
        //? Is this for putting 2D ui visible for detail mode
        this.toggleViewMode(artworkId)
    }

    userInteractionStarted() {
        this.renderView.userInteractionStarted();
    }

    onSelectArtwork(obj) {
       // this.renderView.focusOnArtwork(obj)
    }

    stopViewMode() {
        this.renderView.exitDetailViewMode()
    }

    skipIntroGuidedAnimation() {
        this.renderView && this.renderView.skipIntroGuidedAnimatin();
    }

    startViewForID(artwork_id) {
        //this.renderView.startViewForID(artwork_id)

     //  this.renderView.focusOnArtwork(artwork_id)

    }

    focusOnArtwork(artwork_id) {
        this.renderView.focusOnArtwork(artwork_id)
    }

    cleanup() {

        try{
            this.renderView.onSelectArtwork = null;
            this.renderView.onToggleViewMode = null;
            this.renderView.onGuidedModeArtworkChange = null;
            this.renderView.onVideoStateChange = null;
            this.renderView.closeDetails = null;
            this.renderView.onHoverArtworkChanged = null;
            this.renderView.onSeenArtworkChanged = null;

            this.renderView && this.renderView.cleanup();
            this.renderView = null;
        } catch (e) {
            console.log('e => ', e)
        }
    }

    stop() {
        cancelAnimationFrame(this.frameId)
    }

    setGuidedNavMode(val) {
        this.renderView && this.renderView.setGuidedNavMode(val)
    }

    guidedNavPrev() {
        this.renderView && this.renderView.guidedNavPrev()
    }

    guidedNavNext() {
        this.renderView && this.renderView.guidedNavNext()
    }

    resetGuidedMode() {
        return this.renderView && this.renderView.resetGuidedMode()
    }
    pauseAudio(val){
        return () => {
            try {
                this.renderView && this.renderView.pauseAudio(val)
            } catch (err) {
                console.error(err);
            }
        }
    }

	// onVideoStateChange(artworkId, state) {
    //     this.onVideoStateChange(artworkId, state);
	// }

	setVideoArtworkStateByID(artworkId, state) {
		this.renderView.setVideoArtworkStateByID(artworkId, state)
	}

    //@TODO IGAL DISABLED THIS, NO REFERENCES SHOULD BE ATTACHED TO RENDERVIEW
    // getCameraTransform() {
    //     //logger.debug('this.renderView = ', this.renderView.camera)
    //     return this.renderView.camera.position.x + " " + this.renderView.camera.position.y + " " + this.renderView.camera.position.z + " " + this.renderView.controls.target.x + " " + this.renderView.controls.target.y + " " + this.renderView.controls.target.z;
    // }

     //@TODO IGAL DISABLED THIS, NO REFERENCES SHOULD BE ATTACHED TO RENDERVIEW
    // setCameraTransform(str) {
    //     //logger.debug('setCameraTransform = ', str)
    //     let data = str.split(" ")
    //     this.renderView.camera.position.set(parseFloat(data[0]), parseFloat(data[1]), parseFloat(data[2]))
    //     this.renderView.controls.target.set(parseFloat(data[3]), parseFloat(data[4]), parseFloat(data[5]))
    //     this.renderView.controls.update()
    //     this.renderView.needsRender = true
    // }

      //@TODO IGAL DISABLED THIS, NO REFERENCES SHOULD BE ATTACHED TO RENDERVIEW
    // lockControls(val) {
    //     this.renderView.controlsLocked = val
    // }

    resize() {
        this.renderView && this.renderView.resize();
    }

    setTonemappingSettings(settings) {
       // logger.debug('setTonemappingSettings = ', settings)
        this.renderView && this.renderView.setTonemappingSettings(settings);
    }

    loadPlacementConfig(data) {
        console.log('loadPlacementConfig = ', data)
        this.renderView.loadPlacementConfig(data)
    }


}
