import React, { useEffect, useRef } from "react";
import "./style.scss";
import Masonry from "masonry-layout";
import ArtworkCard from "../ArtworkCard";
import { useOnLoadImages } from "./hooks/useOnLoadImages";

const ListviewArtworkGrid = ({
  artworks,
  setIs3dViewOpen,
  handleArtworkClick,
  isMuseumType,
  isArtistVisible
}) => {
  const masonaryRef = useRef(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const createMasonary = () => {
    var grid = document.querySelector(".grid");
    masonaryRef.current = new Masonry(grid, {
      itemSelector: ".grid-item",
      columnWidth: 0,
      gutter: 24,
      percentPosition: true,
      horizontalOrder: true,
    });
  };

  useEffect(() => {
    if (artworks.length > 0 && imagesLoaded) {
      createMasonary();
      document.querySelectorAll(".grid-item").forEach((element, index) => {
        setTimeout(function () {
          element.classList.add("is-visible");
        }, 40 * index);
      });
    }
  }, [artworks, imagesLoaded]);

  const reloadMasonry = () => {
    createMasonary();
    masonaryRef.current?.layout();
  };

  window.addEventListener("resize", reloadMasonry);

  return (
    <div className="grid" ref={wrapperRef}>
      {artworks.length > 0 &&
        artworks.map((item, index) => {
          return (
            <div className="grid-item" key={index}>
              <ArtworkCard
                handleArtworkClick={(val) => {
                  setIs3dViewOpen(false);
                  handleArtworkClick(val);
                }}
                id={item?.data?.id}
                type={item?.data?.type}
                videoThumbnail={item?.data?.videoThumbnail}
                thumbnail={item?.data?.thumbnail}
                image={item?.data?.image}
                title={item?.data?.title}
                yearCreated={item?.data?.yearCreated}
                name={isArtistVisible && item?.data?.artist?.data?.name}
                medium={item?.data?.medium}
                salesStatus={item?.data?.salesStatus}
                galleryType={item?.data?.organisation?.data?.type}
                isMuseumType={isMuseumType}
                isForSale={item?.data?.isForSale}
                price={item?.data?.price}
                currency={item?.data?.currency}
                height={item?.data?.height}
                width={item?.data?.width}
                depth={item?.data?.depth}
                excludedTax={item?.data?.excludedTax}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ListviewArtworkGrid;
