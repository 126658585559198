import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { Paragraph, Title } from "../Typography2";
import AudioPlayer from "../AudioPlayer";
import Favourite from "../../assets/icons/favourite2.svg";
import FavouriteOn from "../../assets/icons/favourite-on.svg";
import Share from "../../assets/icons/share-2.svg";
import DummyImage from "../../assets/images/CarouselDummyPreview.svg";
import "./style.scss";
import { ICurateMobileArtworkDetails } from "./types";
import { isHTML, getShareURL } from "../../utils/index";
import Audio from "../Audio";
import { fetchArtworkMedia } from "../../services/api";
import { secondsToHms, formatePrice } from "../../utils/index";
import ShareBtn from "../ShareBtn/ShareBtn";
import SwitchBox from "../Switch";
import { motion } from "framer-motion/dist/framer-motion";
import RenderHtml from "./RenderHtml";

const CurateMobileArtworkDetails: React.FC<ICurateMobileArtworkDetails> = React.forwardRef(
  ({
  style,
  artworkDetailsRef,
  artistName,
  artworkName,
  yearCreated,
  medium,
  reference,
  price,
  description,
  artworkPhotos,
  setArtworkModal,
  isPlaying,
  progress,
  currentTime,
  audioUrl,
  setPlaying,
  exhibitionId,
  artworkId,
  totalTime,
  // setCurrentTime,
  dimensionsInIN,
  dimensionsInCM,
  handleEnquire,
  isLoggedIn,
  handleLogin,
  saveToCollection,
  isFavourite,
  chainLink,
  excludedTax,
  isArtworkListedForSale,
  priceUnavailable,
  currency,
  isHalf,
  setTooltipBottom,
  fullOpened,
  isMinimized,
  isActive,
  toggleModalAuth,
  setMinimizeHeight,
  setMaximizeHeight,
  setDefaulHeight,
  isLockedInDetailsMode,
  isFullViewMode,
  setDraggable,
  isDraggable,
  setIsFullViewMode,
  isScrollActive,
  setIsScrollActive,
  setDescriptionHeight,
  descriptionHeight,
  isMuseumType,
  totalDuration,
  togglePlaying
  // currentMobileExpandedState
  // setArtworkPhotos
  // setActive
}, ref) => {
  const [customHeight, setCustomHeight] = useState();
  const [customMinimizedHeight, setCustomMinimzedHeight] = useState();
  const childRef = useRef(null);
  const [dimension, setDimension] = useState(dimensionsInCM || '');
  const mediumWrapperRef = useRef(null);
  const dimensionsWrapperRef = useRef(null);
  const buttonsRef = useRef(null);
  const photosRef = useRef(null);
  const titleWrapperRef = useRef(null);
  const mainWrapperRef = useRef(null);
  const mobileArtworkContentRef = useRef(null);
  const artworkDetailsContentRef = useRef(null);
  const [isMobileDetailsScrollable, setIsMobileDetailsScrollable] = useState(null);
  const [halfOpenTop, setHalfOpenTop] = useState(null);
  const [isSrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const gap = 0;
    const pillHeight = 34;
    const titleHeight = titleWrapperRef?.current?.clientHeight;
    const mediumHeight = mediumWrapperRef?.current?.clientHeight;
    const dimensionsHeight = dimensionsWrapperRef?.current?.clientHeight;
    const photosHeigth = (photosRef?.current?.clientHeight || 0) + 16; // 16 is the margin-bottom of the photos
    const buttonsHeight = buttonsRef?.current?.clientHeight + 32; // 32 is the margin top + margin bottom
    const artworkDetailsContentHeight = artworkDetailsContentRef?.current?.clientHeight;
    const contentWrapperPadding = 32;
    const bottomActionBtn = 72;
    const totalSpace = gap + pillHeight + titleHeight + mediumHeight + dimensionsHeight + contentWrapperPadding + bottomActionBtn - 10; // removing 10 pixes to make sure details overlaps with shadow
    const totalMinimizeSpace = gap + pillHeight + titleHeight + bottomActionBtn;
    // const description = gap + pillHeight + titleHeight + mediumHeight + dimensionsHeight + contentWrapperPadding + bottomActionBtn + buttonsHeight + 52 + photosHeigth; // 32 here is the padding of the description wrapper and 20 px is some extra space to make it more prominent, 32 + 20 = 52
    const description = gap + pillHeight + titleHeight + artworkDetailsContentHeight + contentWrapperPadding + bottomActionBtn + buttonsHeight + 52 + photosHeigth; // 32 here is the padding of the description wrapper and 20 px is some extra space to make it more prominent, 32 + 20 = 52

    if(isActive) {
      setTooltipBottom(gap + pillHeight + titleHeight);
      setHalfOpenTop(totalSpace);
      setDefaulHeight(totalSpace);
      setCustomMinimzedHeight(totalMinimizeSpace);
      setDescriptionHeight(description);
      setMinimizeHeight(isLockedInDetailsMode ? totalSpace : totalMinimizeSpace);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediumWrapperRef?.current?.clientHeight, titleWrapperRef?.current?.clientHeight, dimensionsWrapperRef?.current?.clientHeight, isActive, isLockedInDetailsMode, photosRef?.current, photosRef?.current?.clientHeight, artworkId, mainWrapperRef?.current?.clientHeight, description, artworkDetailsContentRef?.current?.clientHeight, photosRef?.current?.clientHeight])

  useEffect(() => {
    if(isFullViewMode && (mobileArtworkContentRef.current.clientHeight > (window.innerHeight - 82))) {
      setIsMobileDetailsScrollable(true);
      // debugger;
    } else {
      setIsMobileDetailsScrollable(false);
      // debugger;
    }
  }, [mobileArtworkContentRef?.current?.clientHeight, isFullViewMode])


  useEffect(() => {
    if(!isActive) {
      setTooltipBottom(null)
    }
  }, [isActive])

  useEffect(() => {
    if(dimensionsInCM) {
      setDimension(dimensionsInCM);
    }
  }, [dimensionsInCM])

  const toggleDimension = () => {
    if(dimension === dimensionsInIN) {
      setDimension(dimensionsInCM)
    } else {
      setDimension(dimensionsInIN)
    }
  }

  useEffect(() => {
    if (!!audioUrl) {
      togglePlaying(false);
    }
  }, [audioUrl]);

  useEffect(() => {
    const calcHeight =
      artworkDetailsRef.current.clientHeight + 110 <= window.innerHeight
        ? artworkDetailsRef.current.clientHeight
        : window.innerHeight - 140;
    setCustomHeight(calcHeight + 50);
  }, [artistName, artworkPhotos]);

  // const togglePlaying = (val) => {
  //   childRef?.current?.togglePlay(val);
  //   setPlaying(val);
  // }

  useImperativeHandle(
    ref,
    () => ({
        resetAudioPlayer: () => {
          childRef?.current?.reset();
        },
        restart: () => {
          childRef?.current?.restart();
        }
    }),
  );

  return (
    <motion.div className={`curate-mobile-artwork-details ${isSrollable ? 'full-view-mode' : ''}`} ref={mainWrapperRef}>
      <div className="pill-wrapper mobile-artwork-pill--dragger" id="mobile-artwork-details-pill">
        <div className="pill" />
      </div>
      <div className="curate-mobile-artwork-details--wrapper">
      <div
        className="scrollable-content mobile-artwork-content--dragger" ref={mobileArtworkContentRef} 
        >
        <div ref={artworkDetailsRef}>
          <div className="title-wrapper mobile-artwork-pill--dragger" ref={titleWrapperRef} id="curate-mobile-artwork-title">
            <div>
              <Title>
                {artistName}
              </Title>
              <Paragraph className="title-wrapper-artwork-name" value={`${artworkName}, ${yearCreated}`} />
            </div>
            {!!audioUrl && (
              <AudioPlayer
                isPlaying={isPlaying}
                audioUrl={audioUrl}
                currentTime={currentTime}
                totalDuration={totalDuration}
                setIsPlaying={togglePlaying}
              />
            )}
          </div>
          <div className="details-wrap" id="artwork-dimensins-wrapper" ref={artworkDetailsContentRef}>
            {!!medium && medium.trim() !== "" &&  (
              <div ref={mediumWrapperRef}>
                <Paragraph className="bold mobile-artwork-medium-wrapper">
                  Medium: <span>{medium}</span>
                </Paragraph>
              </div>
            )}
            {!!dimension && (
              <div className="mobile-artwork-dimension-wrapper" ref={dimensionsWrapperRef}>
                <Paragraph className="bold">
                  Size: <span>{dimension}</span>
                </Paragraph>
                <SwitchBox value={dimension === dimensionsInIN} onChange={toggleDimension} />
              </div>
            )}
            {reference && (
              <Paragraph className="bold">
                Ref: <span>{reference}</span>
              </Paragraph>
            )}
            {isArtworkListedForSale && price && Number(price) !== 0 && !priceUnavailable && (
              <Paragraph className="bold">
                Price: <span>{formatePrice(price, currency)}</span> {!!excludedTax && <Paragraph className='ex-tax dark-gray' value="(ex tax)" />}
              </Paragraph>
            )}
          </div>
          <div className={`buttons-wrapper ${isMuseumType ? 'no-enquire' : ''} `} ref={buttonsRef}>
            <button
              className="btn"
              onClick={() => {isLoggedIn ? saveToCollection() : toggleModalAuth(true)}}
            >
              <div className="icon-wrapper favourite">
                <img className="btn-icon" src={isFavourite ? FavouriteOn : Favourite} />
              </div>
            </button>
            <button className="btn mobile-artwork-share-btn">
              <ShareBtn up shareUrl={getShareURL("artwork", artworkId)} chainLink={chainLink} isGray={true} />
              <div className="icon-wrapper">
                <img className="btn-icon" src={Share} />
              </div>
            </button>
            {!isMuseumType && <button className="btn btn-white" onClick={handleEnquire}>
              Enquire
            </button>}
          </div>

          {artworkPhotos?.length > 0 && (
            <div className={`images-box-wrapper ${artworkPhotos?.length > 3 ? 'images-box-wrapper--scroll-active' : ''}`} ref={photosRef}>
              <div className="images-box">
                {artworkPhotos.map((photo, index) => (
                  <div
                    className="image-wrapper"
                    onClick={(e) => {
                        setArtworkModal(true, artworkPhotos, index);
                        e.stopPropagation();
                      }
                    }
                    key={index}
                  >
                    <img key={index} src={photo.small} alt="artwork" className="image" />
                  </div>
                ))}
              </div>
            </div>
          )}

          {!!description && description.trim() !== "" && (
            // @ts-ignore
            <RenderHtml response={description} style={{}} />
          )}
        </div>
      </div>
      </div>

      {/* {!!audioUrl && (
        <Audio
          ref={childRef}
          // @ts-ignore
          setTotalTime={(val) => setTotalTime(val)}
          setCurrentTime={(val) => setCurrentTime(val)}
          setPlaying={setPlaying}
          url={audioUrl}
          play={isPlaying}
        />
      )} */}
    </motion.div>
  );
});

export default CurateMobileArtworkDetails;

// Title, Title2, Heading, Paragraph, Timeline, Label, SmallLabel, Anchor, HeadTitle
