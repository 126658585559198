import React from 'react';
import { Title, Paragraph, Label, Heading } from '../Typography2';
import {IBannerItemProps} from './types';
import {useHistory} from "react-router";
import LogoURL from '../../assets/icons/vortic-brigth-logo.svg';
import Arrow from '../../assets/icons/arrow-down.svg';


const BannerItem:React.FC<IBannerItemProps> = ({ fullImage, title, exhibitionId, exhibitionSlug, description, timeline, image, galleryName, listview, descriptionHtml, uuid, isDateVisible }) => {
    const history = useHistory();
    return (
        <div className={`bannerItem${fullImage?'__full-image':''} ${listview ? 'bannerItem--listview' : ''} `} style={!listview ? {background:`${fullImage?'':`url(${image})`}`} : {}}
        onClick={()=>{if(!listview) {  history.push(`/exhibitions/${exhibitionSlug || uuid }`)}}}
        >
        {!listview && <img src={image} alt="banner" className={`bannerImage ${fullImage?'full-image':''}`} />}
            <div className="bannerItem__content">
                <div className ="textContent">
                    <Title className="bright" value={title}  />
                    {galleryName && <Heading className="text-xl bright" value={galleryName}  />}
                    {!descriptionHtml ? <Paragraph className='lineheight-19-5' value={description} /> : <p className='paragraph lineheight-19-5' dangerouslySetInnerHTML={{ __html: descriptionHtml }}></p> }
                    {isDateVisible && <Label value={timeline} />}
                </div>
                {fullImage &&
                <div className="logo">
                  <img src={LogoURL}  alt="Logo"/>
                </div>
                }
            </div>

            {listview && 
                <>
                <div className='arrow-down' style={{cursor:'pointer'}} onClick={()=>{
                    document.querySelector('.arrow-down').scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                }} >
                    <figure className='img'>
                        <img src={Arrow} alt="arrow-down" />
                    </figure>
                </div>
                </>
            }


        </div>
    )
}

export default BannerItem;
