// @ts-nocheck
import React, {Component, useEffect, useState} from 'react';
import ConversationBox from '../../components/ConversationBox';
import MyContext from '../../containers/NewChat/ContextAPI/MyContext';
import Inbox from "../Curate/ChatCurate";
import logger from "../../utils/logger";

export default class Conversation extends Component {
    state = {
        // activeChannel: ''
    }

    setActiveChannel = (channel) => {
        this.props.setCurrentConversation(channel)
    }

    onCloseChat = () => {
        setTimeout(() => {
            this.props.setChat(false)
            this.props.setCurrentConversation('')
        }, 500)
    }

    handleChatBack = () => {
        this.props.setCurrentConversation(null)
    }

    addActiveChannel = (channel) => {
        this.props.setCurrentConversation(channel)
    };

    onCloseChannel = (channel) => {
        this.setActiveChannel('')
    };

    render() {
        const { profile, setArtworkId, setEnquireMessage, setChat, enquireMessage, currentConversation, isConversation } = this.props;
        const { activeChannel } = this.state;
        const isInbox = !!currentConversation;

        if(profile && profile.id) {
            return (
                <>
                    <MyContext.Consumer>
                        {(context) => (
                            <ConversationBox
                                profile={profile}
                                className={isInbox ? 'inbox' : ''}
                                conversationList={context.channels}
                                onListItemClick={this.setActiveChannel}
                                headerTitle='View By'
                                tabs={['Sales Agent', 'Artwork']}
                                onClose={() => setChat(false)}
                                context={context}
                                channels={context.channels}
                                addActiveChannel={this.setActiveChannel}
                                isConversation={isConversation}
                                // updateActiveChannel={updateActiveChannel}
                            />
                        )}
                    </MyContext.Consumer>
                    {isInbox && <Inbox
                      onBackClick={() => this.handleChatBack()}
                      onClose={() => setChat(false)}
                      setEnquireMessage={setEnquireMessage}
                      enquireMessage={enquireMessage}
                      setArtworkId={setArtworkId}
                      userId={profile && profile.id}
                      profileUrl={profile && profile.image ? profile.image.data.signedUrl120 : ''}
                      name={profile && profile.name}
                        // unreadMessageCount={activeChannel.unreadMessageCount}
                        onCloseChannel={this.onCloseChat}
                      channel={currentConversation}
                    />}
                </>
            )
        }
        else return null
    }
}

const ConversationII = ({ setCurrentConversation, profile, conversationList, setArtworkId, setEnquireMessage, onClose, currentConversation, setChat }) => {
    const [activeChannel, setActiveChannel] = useState('');
    const handleListItemClick = (conversation) => {
        // setCurrentConversation(conversation)
    };

    useEffect(() => {
        // const chatLeft = new ChatLeftMenu();
        // chatLeft.loadGroupChannelList();
        // logger.debug('chatLeft => ', chatLeft);
        // loadChannels()
    }, [])

    const isInbox = !!activeChannel;

    logger.debug('activeChannel = ', activeChannel, isInbox)
    if(profile && profile.id) {
        return (
            <>
                <MyContext.Consumer>
                    {(context) => (
                        <ConversationBox
                            profile={profile}
                            className={isInbox ? 'inbox' : ''}
                            conversationList={context.channels}
                            onListItemClick={addActiveChannel}
                            headerTitle='View By'
                            tabs={['Sales Agent', 'Artwork']}
                            onClose={() => setChat(false)}
                            context={context}
                            channels={context.channels}
                            addActiveChannel={addActiveChannel}
                            // updateActiveChannel={updateActiveChannel}
                        />
                    )}
                </MyContext.Consumer>
                {isInbox && <Inbox
                  onBackClick={() => handleChatBack()}
                  onClose={() => setChat(false)}
                  setEnquireMessage={setEnquireMessage} setArtworkId={setArtworkId}
                    userId={profile && profile.id}
                  profileUrl={profile && profile.image ? profile.image.data.signedUrl120 : ''}
                  name={profile && profile.name}
                    // unreadMessageCount={activeChannel.unreadMessageCount}
                    onCloseChannel={onCloseChannel}
                  channel={activeChannel}
                />}
            </>
        )
    }
    else return null
};

// export default Conversation;
