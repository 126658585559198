import React, {useEffect, useState, useRef, useContext} from 'react'
import drawerIcon from '../../assets/icons/drawer-bar.svg'
import {IProps, listHeaderProps, InitialData, FilterData} from "./types";
import {GalleryList} from "../GalleryList/GalleryList";
import './styles.scss'
import {SearchResult} from "./SearchResult/SerachResult";
import IslandNav from "../Nav";
import FilterPill from "../FilterPill";
import { motion } from "framer-motion/dist/framer-motion";
import {CollaborationContext} from "../../containers/CollaborationsNewPage/CollaborationsContext";

const headerHeight = 63;
const SidePanelPaddingY = 24;

export const SideBar =(props: IProps) => {
  const {initialData } = props
  const [data, setData] = useState<InitialData>(initialData)
  const [filteredData, setFilteredData]=useState<FilterData>([])
  const isMobile=window.innerWidth<=720
  const draggerRef = useRef(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const sidePanelHeightRef = useRef(null);
  const [galleryScrollBoxHeight, setGalleryScrollBoxHeight] = useState(window.innerHeight - (headerHeight + SidePanelPaddingY));
  const {collaborationsData,setCollaborationsData, countSearchItem, handleSearch, handleRegion, filterData, onSelectItem, handlePillClick, selectedRegions, searchText, isFiltered}=useContext(CollaborationContext)


  useEffect(() => {
    if (!sidePanelHeightRef.current || isMobile) return;
    const resizeObserver = new ResizeObserver(() => {
      setGalleryScrollBoxHeight(window.innerHeight - (headerHeight + SidePanelPaddingY + sidePanelHeightRef.current.clientHeight))
    });
    resizeObserver.observe(sidePanelHeightRef.current);
    return () => resizeObserver.disconnect(); 
  }, [])

  useEffect(() => {
    if(!!data) {
      setIsDataLoaded(true);
    }
  }, [data])

  const handleSelectGallery = (key,item) => {
    onSelectItem(key,item);
    if(props.isMobileSlider){
      props.setToggle(false);
    }
  }


  useEffect(() => {
    if(isDataLoaded && props.isMobileSlider) {
      props.getDraggerClientHeight();
    }
  }, [isDataLoaded])


  useEffect(() => {
    setData(initialData)
    setCollaborationsData(initialData)
  }, [initialData])

  useEffect(()=>{
    if(isFiltered && collaborationsData){
      let temp=[]
        Object.keys(collaborationsData).forEach(gallery=>{
          temp=[...temp, ...collaborationsData[gallery].filter(item=>!item.disabled)]
        })
      setFilteredData(temp)
    }
  },[isFiltered, collaborationsData])

  return (
    <div
      className={`sidebar-container `}>
      <div className='icon-div'>
        <img
          src={drawerIcon}
          alt='drawer-icon'
        />
      </div>
      <motion.div className='sidebar-scroller-box'
        ref={draggerRef}>
        <div className='top-list' ref={sidePanelHeightRef}>
          <div className='top-list-item'>
            {!isMobile && <IslandNav selectedRegions={selectedRegions} data={filterData} variant="search-and-filter-island"
                        handleSearch={handleSearch}
                        handleRegion={handleRegion}/>}
            {searchText && <SearchResult text={`Showing ${countSearchItem} results for "${searchText}"`}/>}
            {selectedRegions.length > 0 && (
              <div className='selected-pills'>
                {selectedRegions.map((region, index) => (
                  <React.Fragment key={`filter-${index}`}>
                    <FilterPill isSelected={true} label={region} id={index} handlePillClick={handlePillClick}/>
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        </div>
        <motion.div drag="y" dragConstraints={draggerRef} className="sidebar-scroller-box__content" style={!isMobile ? { maxHeight: `${galleryScrollBoxHeight}px`} : {}}>
          {
            isFiltered && <GalleryList data={filteredData} onSelectItem={(item) =>handleSelectGallery('', item)} />
          }

          {
            !isFiltered && Object.keys(collaborationsData).map((key, index) => {
              let list = collaborationsData[key]
              if(list.length <= 0) { return null}
              return (
                <React.Fragment key={`list-${index}`}>
                  <ListHeader count={+key < 10 ? `0${key}` : key} className={index === 0 ? 'first-of-type' : ''} />
                  <GalleryList data={list} onSelectItem={(item) => handleSelectGallery(key,item)}/>
                </React.Fragment>
              )
            })
          }
        </motion.div>
        </motion.div>
    </div>
  )
}

const ListHeader = ({title = "", count = "01", className = ""}: listHeaderProps) => (
  <div className={`sidebar-list-header ${className}`}>
    <div className='list-text title'> {title}</div>
    <div className='list-text count'>{count}</div>
  </div>
)